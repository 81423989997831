import React from "react";
import { Route, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import AssessmentIcon from '@material-ui/icons/Assessment';

import InsightToolbar from "../components/InsightToolbar"
import ProjectList from "../components/ProjectList"
import UserList from "../components/UserList";
import TeamList from "../components/TeamList";
import TopicList from "../components/TopicList";

const styles = theme => ({
    tabContainer: {
	paddingTop: theme.spacing(4),
	paddingBottom: theme.spacing(4)
    }
});

function TabPanel(props) {
    const { children, ...other } = props;

    return (
	<Typography component="div" role="tabpanel" {...other} >
	    {children}
	</Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node
};

class Administration extends React.Component {    
    handleChange = (event, newValue) => {
	this.props.history.push(newValue);
    }
    
    render() {    
	const { classes } = this.props;

	return (<>
		    <InsightToolbar app={this.props.app} title="Administration" subtitle="" />
		    
		    <Container maxWidth="lg" className={classes.container}>
		    	<Tabs
			    value={this.props.history.location.pathname}
			    onChange={this.handleChange}
			    variant="fullWidth"
			    indicatorColor="secondary"
			    textColor="secondary"
			    aria-label="icon label tabs example"
			>
			    <Tab value="/administration" icon={<AssessmentIcon />} label="Projects" />
			    <Tab value="/administration/users" icon={<AssessmentIcon />} label="Users" />
			    <Tab value="/administration/teams" icon={<AssessmentIcon />} label="Teams" />
			    <Tab value="/administration/topics" icon={<AssessmentIcon />} label="Topics" />
			</Tabs>

			<Container className={classes.tabContainer}>
			    <Route exact path="/administration">
				<TabPanel>
				    <ProjectList app={this.props.app} />
				</TabPanel>
			    </Route>
			    
			    <Route exact path="/administration/users">
				<TabPanel>
				    <UserList app={this.props.app}/>
				</TabPanel>
			    </Route>

			    <Route exact path="/administration/teams">
				<TabPanel>
				    <TeamList app={this.props.app}/>
				</TabPanel>
			    </Route>

			    <Route exact path="/administration/topics">
				<TabPanel>
				    <TopicList app={this.props.app}/>
				</TabPanel>
			    </Route>
			</Container>
		    </Container>
		</>		    
	       );
    }	
}

export default withRouter(withStyles(styles)(Administration));
