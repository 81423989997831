import React, { useState } from "react";

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
// import ReactTooltip from "react-tooltip";
import Moment from 'react-moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Hidden from '@material-ui/core/Hidden';

function TopUserList(props) {
    const { showFollowers, users, more, datasets } = props;

    const createUserLabel = (item) => {
	if (item.name != null) return item.name;
	if (item.uname != null) return '@' + item.uname;
	return 'n/a';
    }
    
    const getDatasetName = (id) => {
	const ds = datasets && datasets[id];
	if (ds) return ds.name;
	else return '#' + id;
    }

    const createToolTip = (item) => (
	<>
	    <Typography color="inherit">{item.name}{item.name != null && item.uname != null ? '\xa0' : null}{item.uname != null ? '@' + item.uname : null}</Typography>
	    { item.created_time && <div><b>Created:</b> <Moment format="YYYY-MM-DD hh:mm:ss">{ new Date(item.created_time*1000) }</Moment></div> }
	    <div><b>Platform:</b> { getDatasetName(item.dataset_id) }</div>
	    { item.last_comment && <div><b>Last comment:</b> { item.last_comment.text }</div> }
	</>
    );
    
    // className={classes.chart}
    return (
	<Table size="small">
	    <colgroup>
		<col/>
		<col style={{width:'80%'}}/>
		<Hidden mdDown><col style={{width:'20%'}}/></Hidden>
		<col/>
		<col/>
		{ showFollowers ? <col/> : null }
	    </colgroup>
	    <TableHead>
		<TableRow>
		    <TableCell>Rank</TableCell>
		    <TableCell>Name</TableCell>
		    <Hidden mdDown><TableCell align="right">Platform</TableCell></Hidden>
		    <TableCell align="right">Popularity</TableCell>
		    <TableCell align="right">Activity</TableCell>
		    { showFollowers ? <TableCell align="right">Followers</TableCell> : null }
		</TableRow>
	    </TableHead>
	    <TableBody>
		{users.slice(0, more ? users.length : 10).map(item => (
		    <TableRow key={item.key}>
			<TableCell align="right">{item.rank}</TableCell>
			<TableCell component="th" scope="item" style={{ cursor: 'default' }}>
			    <Tooltip title={createToolTip(item)}><span>{createUserLabel(item)}</span></Tooltip>
			</TableCell>
			<Hidden mdDown><TableCell align="right">{getDatasetName(item.dataset_id)}</TableCell></Hidden>
			<TableCell align="right">{item.popularity}</TableCell>
			<TableCell align="right">{item.activity}</TableCell>				
			{ showFollowers ? <TableCell align="right">{ item.followers != null ? <span>{item.followers}&nbsp;<span style={{ fontSize: 'smaller '}}>+{item.followers_diff}</span></span> : null }</TableCell> : null }
		    </TableRow>
		))}
	    </TableBody>
	</Table>
    );
}

export default TopUserList;
