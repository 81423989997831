import React from 'react';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

const WarningTypography = withStyles({
    root: {
	color: '#f00',
	'font-size': 'smaller'
    }
})(Typography);

class LoginForm extends React.Component {
    constructor(props) {
	super(props);

	this.state = {
	    userValue: "",
	    passValue: "",
	    badLogin: 0
	};
    }
    
    render() {
	const handleInputChange = (event) => {
	    const target = event.target;

	    this.setState({
		[target.name]: target.value
	    });
	}
	
	const handleLogin = (e) => {
	    const onError = () => {
		this.setState({passValue: "", badLogin: 1});
	    }
	    let f = this.props.app.login.bind(this.props.app);
	    f(this.state.userValue, this.state.passValue, onError);
	    e.preventDefault();
	}
	
	return (
	    <div>
		<Dialog open={true} aria-labelledby="login-dialog-title">
		    <DialogTitle id="login-dialog-title">Log in</DialogTitle>
		    <form onSubmit={handleLogin}>
			<DialogContent>
			    <WarningTypography style={{ visibility: this.state.badLogin ? '' : 'hidden' }}>The email and password you entered did not match our records. Please double-check and try again.</WarningTypography>
			    <TextField
				autoFocus
				value={this.state.userValue}
				onChange={handleInputChange}
				margin="dense"
				name="userValue"
				label="Username"
				type="username"
				fullWidth
			    />
			    <TextField
				value={this.state.passValue}
				onChange={handleInputChange}
				margin="dense"
				name="passValue"
				label="Password"
				type="password"
				fullWidth
			    />
			    
			</DialogContent>
			<DialogActions>
			    <Button color="primary" type="submit" >
				Login
			    </Button>
			</DialogActions>
		    </form>						
		</Dialog>
	    </div>
	);
    }
}

export default LoginForm;
