import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie';

import MyDrawer from "./MyDrawer";

import User from "../pages/User";
import Administration from "../pages/Administration";
import ExportList from "../pages/ExportList";
import ExportWizard from "../pages/ExportWizard";
import ExportView from "../pages/ExportView";
import AnalysisWizard from "../pages/AnalysisWizard";
import ExportGraph from "../pages/ExportGraph";
import ProjectCardView from "../pages/ProjectCardView";
import LoginForm from "../pages/LoginForm";
import EditProjectView from "../pages/EditProjectView";
import ClassifyView from "../pages/ClassifyView";
import ProjectView from "../pages/ProjectView";
import AnalysisView from "../pages/AnalysisView";
import SearchView from "../pages/SearchView";

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const theme = createMuiTheme({
    typography: {
	fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
	// fontSize: 14,
    },
    palette: {
	text: {
	    primary: '#2a3034',
	},
	primary: {
	    light: '#ff7961',
	    main: '#df292d',
	    dark: '#ba000d',
            contrastText: '#fff',
	},
	secondary: {
	    light: '#757ce8',
	    main: '#3f50b5',
	    dark: '#002884',
	    contrastText: '#fff',
	},
    },
});

const styles = theme => ({

});

const CreateRoutes = (props) => {
    const user = props.user;
    if (user != null) {
	return (
	    <Switch>
		<Route path="/user">
		    <User app={props.app} />
		</Route>
		{ user.is_admin ? (<Route path="/administration"><Administration app={props.app} /></Route>) : null }
		<Route path="/export/list">
		    <ExportList app={props.app} />
		</Route>
		<Route path="/editproject/:id">
		    <EditProjectView app={props.app} />
		</Route>
		<Route path="/classify/:id">
                    <ClassifyView app={props.app} />
                </Route>
		<Route path="/export/graph/:id" render={(props2) => <ExportGraph app={props.app} {...props2} />} />	    
		<Route path="/export/:id" render={(props2) => <ExportView app={props.app} {...props2} />} />
		<Route path="/project/:id" render={(props2) => <ProjectView app={props.app} {...props2} />} />
		<Route path="/export">
		    <ExportWizard app={props.app} />
		</Route>
		<Route path="/createAnalysis">
		    <AnalysisWizard app={props.app} />
		</Route>
		<Route path="/search">
		    <SearchView app={props.app} user={user}/>
		</Route>
		<Route path="/analysisList">
		    <AnalysisView app={props.app} user={user}/>
		</Route>
		<Route path="/">
		    <ProjectCardView app={props.app} />
		</Route>
	    </Switch>
	);
    } else {
	return (
	    <Switch>
		<Route path="/">
		    <LoginForm app={props.app} />
		</Route>			
	    </Switch>
	);
    }
};
		      
class InsightBaseApp extends React.Component {
    constructor(props) {	
	super(props);

	const { cookies } = props;

	const session = cookies.get('sess');
	const userdata = cookies.get('u');

	let user = null;
	if (userdata != null) {
	    var a = userdata.split('/');
	    user = { id: a[0], name: a[1], is_admin: a[2] == 1 };
	}
	
	this.state = {
	    user: user,
	    menuOpen: false,
	    snackbarOpen: false,
	    toasts: [ ],
	    projects: { },
	};

        this.getRealtimeProjects = this.getRealtimeProjects.bind(this);
        this.getStaticProjects = this.getStaticProjects.bind(this);
	this.getProjectName = this.getProjectName.bind(this);
    }

    addToast(text, severity) {	
	if (severity == null) severity = "success";
	this.setState(state => ({ snackbarOpen: true, toasts: state.toasts.concat([ { text: text, severity: severity } ]) }));
    }

    login(username, password, onError) {
	const { cookies } = this.props;

	const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
	    credentials: 'include',
            body: new URLSearchParams({
                'user': username,
		'password': password
            })
        };
	
        fetch("https://api.sometrik.com/user/login", requestOptions)
	    .then(res => res.json())
	    .then(
		(result) => {
		    if (result.data.status) {
			const session = result.data.cookie;
			const user = result.data.user;
			
			cookies.set('sess', session, { path: '/', domain: '.sometrik.com', sameSite: 'lax' });
			cookies.set('u', [ user.id, user.name, user.is_admin ? 1 : 0 ].join('/'), { path: '/' }); // intentionally a local cookie
			this.setState({ user: user, projects: result.data.projects });
		    } else {
			onError();		    
		    }
		},
		(error) => {
		    onError();		    
		}
	    )
    }

    toggleMenu() {
	this.setState(state => ({ menuOpen: !this.state.menuOpen }));
    }

    logout() {
	const { cookies } = this.props;
	
	cookies.remove('sess', { path: '/', domain: '.sometrik.com', sameSite: 'lax' });
	cookies.remove('u', { path: '/' });
	
	this.setState({ user: null });
    }

    getUser() {
	return this.state.user;
    }

    getRealtimeProjects() {
	return Object.values(this.state.projects).filter( p => !p.is_static );
    }

    getStaticProjects() {
	return Object.values(this.state.projects).filter( p => p.is_static != 0 );
    }

    getProjectName(id) {
	const p = this.state.projects[id];
	return p && p.name ? p.name : id;
    }

    componentDidMount() {
	fetch("https://api.sometrik.com/project/list", { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			projects: result.data,
		    });
		},
		(error) => {
		}		
	    )
    }

    render() {
	const { classes } = this.props;
	const { toasts } = this.state;

	const onclose = () => {
	    this.toggleMenu();
	}
	const snackbarClose = () => {
	    this.setState({ snackbarOpen: false, toasts: [ ] });
	}
	
	return (
	    <div className={classes.root}>
		<ThemeProvider theme={theme}>
		    <Router>
			<MyDrawer app={this} open={this.state.menuOpen } onclose={onclose} user={this.state.user} />
			<main className={classes.content}>
			    <CreateRoutes app={this} user={this.state.user} />
			    <Snackbar open={this.state.snackbarOpen} autoHideDuration={6000} onClose={snackbarClose}><div>{toasts.map( item => (<MuiAlert severity={item.severity}>{item.text}</MuiAlert>) )}</div></Snackbar>
			</main>
		    </Router>		
		</ThemeProvider>
	    </div>
	);
    }
}

export default withCookies(withStyles(styles)(InsightBaseApp));
