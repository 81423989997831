import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import InsightToolbar from "../components/InsightToolbar.js"
import Container from '@material-ui/core/Container';
import Chart from "react-apexcharts";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import TimeSelector from '../components/TimeSelector';

const styles = theme => ({
    container: {
	paddingTop: theme.spacing(4),
	paddingBottom: theme.spacing(4)
    }
});

class ProjectCardView extends React.Component {
    constructor(props) {
	super(props);
	
	this.state = {
	    projects: null,
	    isLoaded: false,
	    numDays: 30
	};
    }

    componentDidMount() {
	this.getData(this.state.numDays);
    }

    selectPage(id) {
	this.props.history.push('/editproject/'+id);
    }

    getData(n) {
	const p = {activity: '1', days: n};
        fetch("https://api.sometrik.com/project/list?" + new URLSearchParams(p), { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			isLoaded: true,
			projects: result.data
		    });
		},
		(error) => {
		    this.setState({
			isLoaded: true,
			error
		    });
		}
		
	    )
    }

    render() {
	const { classes, app } = this.props;
	const { projects } = this.state;

	const onTimeSelect = (n) => {
            this.setState({numDays: n});
	    this.getData(n);
	};
	
	const displayedProjects = projects != null ? projects : app.getRealtimeProjects();
	
	const fixed = (value) => value.toFixed(1);
	
	let options1 = {
	    xaxis: {
		type: 'datetime',
		tooltip: {
		    enabled: false
		}
	    },
	    yaxis: {
		labels: {
		    formatter: fixed,
		},
	    },
	    chart: {
		animations: {
		    enabled: false
		},
		toolbar: {
		    show: false,
		},
		selection: {
		    enabled: false,
		},
		zoom: {
		    enabled: false,
		},
		sparkline: {
		    enabled: true,
		}
	    },
	    stroke: {
                curve: 'straight',
                width: 2
            },
	    tooltip: {
		x: {
		    format: 'dd MMM yyyy'
		}
	    },
	};

	const createData = (project) => {
	    if (project && project.activity) {
		return [ {
		    name: 'Activity',
		    data: project.activity,
		} ];
	    } else {
		return [ ];
	    }
	};
	
	return (
	    <div>
		<InsightToolbar app={this.props.app} title="Projects" subtitle="" />
		
		<Container maxWidth="lg" className={classes.container}>
		    <Table>
			<TableBody>
			    { Object.values(displayedProjects).map((project) => (
				<TableRow key={project.id}>
				    <TableCell><Link component={RouterLink} to={ '/project/' + project.id }>{project.name}</Link></TableCell>
				    <TableCell>{ project.activity && project.activity.length > 0 ? <Chart options={options1} series={createData(project)} type="area" height={20} width={170} /> : null }</TableCell> 
				</TableRow>				    
			    )) }
			</TableBody>
		    </Table>
		</Container>
	    </div>
	);
    }
}

export default withRouter(withStyles(styles)(ProjectCardView));
