import React from "react";

import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Hidden from '@material-ui/core/Hidden';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import Message from "./Message";

const styles = theme => ({
    headingCell: {
	'text-align': 'center',
    },
    datasetName: {
	'vertical-align': 'top',
	'white-space': 'nowrap',
    },
    typeText: {
	'vertical-align': 'top',
    },
    content: {
	'vertical-align': 'top',
    },
    tools: {
	'vertical-align': 'top',
	'text-align': 'center',
    },
    emotion: {
	fontSize: 'larger',
    },
    score: {
	marginLeft: '0.25em',
    },
    quote: {
	marginLeft: '0.25em',
    },
    statusContainer: {
        height: '80vh'
    },
    statusInnerContainer: {
        textAlign: 'center',
        width: '100%'
    }
});

class ContentWindow extends React.Component {
    constructor(props) {
	super(props);
	this.state = {
	    error: null,
	    isLoaded: false,
	    show_more: false,
	};
    }

    componentDidMount() {
	const props = this.props;
	if (props.projectId) {
	    this.getData();
	}
    }
    
    componentDidUpdate(prevProps) {
	const props = this.props;
	if (props.projectId && (!prevProps.projectId || props.projectId !== prevProps.projectId)) {
	    this.getData();
	}
    }
    
    componentWillUnmount() {
	if (this.intervalId) clearTimeout(this.intervalId);
    }

    getData = () => {
	const { projectId, type } = this.props;
	const body = new URLSearchParams({ id: projectId, type: type });
	
	fetch("https://api.sometrik.com/project/content?" + body, { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			isLoaded: true,
			posts: result.data,
		    });
		    if (type <= 7) this.intervalId = setTimeout(this.getData.bind(this), 10000 * type);
		    this.props.onHideBackdrop();
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components.
		(error) => {		    
		    this.intervalId = setTimeout(this.getData.bind(this), 10000 * type);
		}
	    )
    }

    render() {
	console.log("rendering window");
	
	const { error, isLoaded, posts, show_more } = this.state;
	const { project, classes, primary, title } = this.props;

	const openMenu = (anchor, article) => {
            this.props.onContextMenu(anchor, article);
	}

	const toggleWindow = () => {	    
	    this.setState({ show_more: !this.state.show_more });
	}
	
	if (error) {
	    return <div>Error</div>;
	} else if (!isLoaded) {
	    if (primary) {
		return (
		    <TableRow key="heading">
			<TableCell>
			    <Box className={classes.statusContainer} display="flex" alignItems="center">
				<div className={classes.statusInnerContainer}>
				    <CircularProgress />
				</div>
			    </Box>
			</TableCell>
		    </TableRow>
		);
	    } else {
		return null;
	    }
	} else if (!posts.length) {
	    return null;
	} else {
	    return (
		<React.Fragment>
		    <TableRow key="heading">
			<Hidden mdDown><TableCell></TableCell></Hidden>
			<Hidden mdDown><TableCell></TableCell></Hidden>
			<TableCell colSpan={2} className={classes.headingCell}>
			    <Chip label={title} color="secondary"/>
			</TableCell>
		    </TableRow>
		    {posts.slice(0, show_more ? posts.length : 5).map(article => (
			<TableRow key={article.id}>
			    <Hidden mdDown><TableCell className={classes.datasetName}>{ article.dataset_name }</TableCell></Hidden>
			    <Hidden mdDown><TableCell className={classes.typeText}>{ article.type_text }</TableCell></Hidden>
			    <TableCell className={classes.content}>
				<Message positive_regex={project && project.positive_regex} negative_regex={project && project.negative_regex} article={article} stats={true} full={true} />
			    </TableCell>
			    <TableCell className={classes.tools}>
				<div><IconButton aria-label="settings" size="small" onClick={ (e) => { openMenu(e.currentTarget, article) }} ><MoreVertIcon /></IconButton></div>
				{ article.emoticon != null ? <div className={classes.emotion}>{article.emoticon}</div> : null }
			    </TableCell>
			</TableRow>
		    ))}					
		    { posts.length > 5 ? (
			<TableRow>
			    <Hidden mdDown><TableCell></TableCell></Hidden>
			    <Hidden mdDown><TableCell></TableCell></Hidden>
			    <TableCell colSpan={2}>
				<Button variant="contained" size="small" disableElevation onClick={ () => toggleWindow() }>{ show_more ? 'Show Less' : 'Show More' }</Button>
			    </TableCell>
			</TableRow>
		    ) : null }
		</React.Fragment>
	    );
	}
    }
};

export default withStyles(styles)(ContentWindow);
