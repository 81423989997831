import React from "react";
import { withStyles } from '@material-ui/core/styles';
import InsightToolbar from "../components/InsightToolbar.js"
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardTitle from '../components/CardTitle';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ClearIcon from '@material-ui/icons/Clear';

import TimeSelector from '../components/TimeSelector';
import BrushChart from "../components/BrushChart";
import Message from "../components/Message";
import Paper from '@material-ui/core/Paper';

import Chart from "react-apexcharts";

import moment from 'moment';

const styles = theme => ({
    queries: {
	paddingTop: theme.spacing(4),
	paddingBottom: theme.spacing(3)
    },
    chart: {
	boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
    },
    standardChart: {
	boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
    	minHeight: '22em',
    },
    paddedIconButton: {
	marginLeft:'0.25em',
	marginRight:'0.25em'
    }
});

class SearchView extends React.Component {
    constructor(props) {
	super(props);
	
	this.emotionLabels = [ 'Neutral', 'Anger', 'Anticipation', 'Disgust', 'Fear', 'Joy', 'Sadness', 'Surprise (neg)', 'Trust', 'Surprise (pos)' ];
	
	this.state = {
	    staticData: { },
	    filter: { },
	    searches: [ { queryText: '', query: '', results: null, visible: true } ],
	    numDays: null,
	    dataReceived: false
	};
    }

    componentDidMount() {
	this.selectFixedTime(7);
    	this.getStaticData();
    }

    getStaticData() {
	fetch("https://api.sometrik.com/dataset/list", { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({ staticData: { ...result, isLoaded: true } });
		    // console.log(JSON.stringify(result.data));
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components.
		(error) => {
		}
	    );
    }

    performSearch(search) {
	const p = { lang: 'fi', query0: search.query };
        fetch("https://api.sometrik.com/search/search6?" + new URLSearchParams(p), { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    search.results = result;
		    
		    this.setState({
			searches: this.state.searches,
			resultsVersion: this.state.resultsVersion + 1,
			dataReceived: true
		    });

		    this.loadMessages();
		},
		(error) => {
		    // ?
		}		
	    )
    }

    loadMessages() {
	const p = { lang: 'fi', mode: 'full' };
	if (this.state.filter.t) {
	    p.t0 = this.state.filter.t[0];
	    p.t1 = this.state.filter.t[1];
	}
	var query_found = false;
	for (var i = 0; i < this.state.searches.length; i++) {
	    const search = this.state.searches[i];
	    if (search.query != '' && search.visible) {
		query_found = true;
		p['query' + i] = search.query;
	    }
	}
	if (query_found) {
            fetch("https://api.sometrik.com/search/search6?" + new URLSearchParams(p), { credentials: 'include' } )
		.then(res => res.json())
		.then(
		    (result) => {
			this.setState({
			    selection: result,
			});
		    },
		    (error) => {
			this.setState({
			    selection: null,
			});
		    }		
		)
	} else {
	    this.setState( { selection: null } );
	}
    }

    updateFilter(key, value) {
	let filter = this.state.filter;
	filter.t = value;
	this.setState({ filter: filter });
	this.loadMessages();
    }
    
    selectFixedTime(n, from, to) {
    	if (n != null) {
	    var d1 = moment().valueOf() / 1000;
	    var d0 = moment().subtract(n, "days").valueOf() / 1000;
	    
	    this.setState({ numDays: n });
	    this.updateFilter('t', [ d0, d1 ], true);
	} else {
	    this.setState( { numDays: null } );

	    if (from != null || to != null) {
		const { filter } = this.state;
		let t = [ filter.t[0], filter.t[1] ];
		
		if (from != null) t[0] = from.getTime() / 1000;		
		if (to != null) t[1] = to.getTime() / 1000;

		this.updateFilter('t', t);
	    }
	}
    }

    render() {
	const { classes, user, app } = this.props;
	const { filter, selection, resultsVersion, staticData, searches } = this.state;

	const formatCount = (a) => {
	    if (a < 10000) return a;
	    if (a < 10000000) return Math.floor(a / 1000) + ' k';
	    return Math.floor(a / 1000000) + ' M';
	}
	
	const deleteSearch = (i) => {
	    const had_visible_data = searches[i].visible && searches[i].results.total > 0;
	    
	    searches.splice(i, 1);
	    if (searches.length == 5) searches.push({ queryText: '', query: '', visible: true});
	    this.setState({ searches: searches });

	    if (had_visible_data) this.loadMessages();
	}
	
	const handleSearch = (i, e) => {
	    if (i + 1 == searches.length && searches.length < 6) {
		searches.push({ queryText: '', query: '', visible: true });
	    }
	    searches[i].query = searches[i].queryText;
	    this.setState({ searches: searches });
	    this.performSearch(searches[i]);

	    e.preventDefault();
	}

	const handleInputChange = (i, event) => {
	    const target = event.target;
	    searches[i].queryText = target.value;	    
	    this.setState({ searches: searches });
	}

	const handleTimeSelection = (t) => {
	    this.updateFilter( 't', t );
	}

	const clearFixedSelection = () => {

	}

	const clearFixedTimeSelection = () => {

	}

	const onSelectFixedTime = (n, from, to) => {
	    this.selectFixedTime(n, from, to);	    
	}

	const toggleVisibility = (i) => {
	    searches[i].visible = !searches[i].visible;
	    this.setState({ searches: searches });
	    this.loadMessages();
	}
	
	const active_categories = null;
	const onContextMenu = null;
	
	let activityData = [ ];
	let brushData = [ ];
	let total = 0;
	let regex = [ ];
	
	for (var i = 0; i < searches.length; i++) {
	    const search = searches[i];
	    if (search.results) {
		const time = search.results.timeseries.t;
		const activity = search.results.timeseries.activity;

		activityData.push({
		    name: search.query,
		    data: search.visible ? time.map( (t, i) => [ t, activity[i] ] ) : [],
		});
		
		brushData.push({
	     	    name: search.query,
		    data: search.visible ? time.map( (t, i) => [ t, activity[i] ] ) : [],
		});

		total += search.results.total;
		for (let j = 0; j < search.results.positive_regex.length; j++) regex.push(search.results.positive_regex[j]);
	    }
	}

	let selectionTotal = selection ? selection.total : 0;
	
	const negative_regex = null;
	const positive_regex = regex.length ? new RegExp('(' + regex.join('|') + ')', 'gui') : null;

	const basicFormatter = value => (typeof value === 'number') ? value.toFixed(0) : value;

	const chartStates = {
	    states: {
		active: {
		    filter: {
			type: 'none'
		    }
		},
		hover: {
		    filter: {
			type: 'lighten'
		    }
		}
	    }
	};

	const renderResults = () => {
	    let sentimentSeries = [ { name: 'Neutral', data: [ ] }, { name: 'Positive', data: [ ] }, { name: 'Negative', data: [ ] } ];

	    let queries = [ ];
	    if (selection && selection.queries) {
		for (let q in selection.queries) {
		    let qd = selection.queries[q].sentiment;
		    sentimentSeries[0].data.push(qd[0]);
		    sentimentSeries[1].data.push(qd[1]);
		    sentimentSeries[2].data.push(qd[2]);
		    queries.push(q);
		}
	    }

	    let sentimentOptions = {
		...chartStates,

		chart: {
                    type: 'bar',
                    stacked: true,
                    stackType: '100%',
		    height: 100 * queries.length,
	    	    events: {
			dataPointSelection: (event, chart, config) => {
			    // this.updateFilter('sentiment', config.dataPointIndex);
			}
		    },
		    animations: {
			enabled: false
		    },
		    toolbar: { show: false },
		    // sparkline: { enabled: true },
		},
		plotOptions: {
                    bar: {
			horizontal: true,
                    },
		},
		stroke: {
                    width: 1,
                    colors: ['#fff']
		},
		xaxis: {
                    categories: queries,
		    labels: {
			show: false,
                    },
		    show: false,
		    axisBorder: { show: false },
		    axisTicks: { show: false },
		},
		yaxis: {
		    show: true,
		    labels: {
			show: true,
		    },
		    axisBorder: { show: false },
		    axisTicks: { show: false },
		},
		grid: {
		    show: false,
		    xaxis: {
			lines: {
			    show: false
			}
		    },   
		    yaxis: {
			lines: {
			    show: false
			}
		    },
		},
		legend: {
		    show: false
		},
		tooltip: {
		    enabled: true,
		    y: {
			formatter: basicFormatter,
		    }
		},	    
		// colors: orderedSentimentColors,
	    };

	    return (
		<Container maxWidth="lg">
		    <Grid container spacing={3}>
			<Grid item xs={12}>
			    <Box style={{ display: "flex", m: 1, p: 1, alignItems: 'center' }}>
				<Button color="primary" variant="contained" disabled={selectionTotal == 0}>Create Analysis</Button>
				
				<Typography component="h2" variant="h6" color="primary" style={{marginLeft:'1em'}}>
				    <div>{ formatCount(selectionTotal) + ' results' }</div>
				</Typography>
			    </Box>
			</Grid>
			
			<Grid item xs={12}>
			    <Card className={classes.chart}>
				<BrushChart key={resultsVersion} isRelative={false} activityData={activityData} brushData={brushData} onTimeSelection={handleTimeSelection} onClearFixed={clearFixedTimeSelection} fixedTimeSelection={filter.t}/>
				<TimeSelector n={this.state.numDays} fixedTimeSelection={filter.t} onTimeSelect={onSelectFixedTime} />
			    </Card>
			</Grid>

			{ queries.length &&
			  <Grid item xs={12}>
			      <Card className={classes.chart}>
				  <CardContent>
				      <CardTitle>Sentiment</CardTitle>
				      <Chart options={sentimentOptions} series={sentimentSeries} type="bar" height={100 * queries.length} />
				  </CardContent>
			      </Card>
			  </Grid>
			}

			{ selection && selection.topics &&
			  <Grid item xs={12} md={4} lg={4}>
			      <Card className={classes.chart}>
				  <CardContent>
				      <CardTitle>Topics</CardTitle>
				      <Table className={classes.statisticsTable} size="small">
					  <TableBody>
					      { selection.topics.map( topic => (
						  <TableRow key={topic[0]}>
						      <TableCell>{topic[1]}</TableCell>
						      <TableCell>{topic[2]}</TableCell>
						  </TableRow>
					      )) }
					  </TableBody>
				      </Table>
				  </CardContent>
			      </Card>
			  </Grid>
			 }

			{ selection && selection.documents && selection.documents.length ?
			  <Grid item xs={12} md={8} lg={8}>
			      <Card className={classes.chart}>
				  <CardContent>
				      <CardTitle>Example Content</CardTitle>
				      <Table className={classes.statisticsTable} size="small">
					  <TableBody>
					      { selection.documents.map( item => (
						  <TableRow key={item.key}>
						      <TableCell><Message user={user} positive_regex={positive_regex} negative_regex={negative_regex} article={item} topics={active_categories} emotionLabels={this.emotionLabels} onContextMenu={onContextMenu} datasets={staticData.datasets} /></TableCell>
						  </TableRow>
					      ))}
					  </TableBody>
				      </Table>
				  </CardContent>
			      </Card>
			  </Grid>
			  : null }
		    </Grid>
		</Container>
	    );
	}
	
	return (
	    <div>
		<InsightToolbar app={this.props.app} title="Quick Search" subtitle="" />

		<Container maxWidth="sm" className={classes.queries}>
		    { searches.map( (search, i) => (
			<form key={i} onSubmit={(e) => handleSearch(i, e)} style={{marginBottom:'0.25em'}}>
			    <Paper style={{display: "flex", m: 1, p: 1, alignItems: 'center' }}>
				<IconButton color="secondary" className={classes.paddedIconButton} m={1} p={1} aria-label="visibility" size="small" onClick={() => toggleVisibility(i)} disabled={search.query == ''}>
				    { search.visible ? <VisibilityIcon /> : <VisibilityOffIcon /> }
				</IconButton>
				<InputBase
				    autoFocus
				    style={{flexGrow: 1, m: 1, p: 1}}
				    placeholder="Query"
				    value={search.queryText}
				    onChange={(e) => handleInputChange(i, e)}
				/>
				<IconButton type="submit" m={1} p={1} className={classes.paddedIconButton} aria-label="search" size="small" disabled={search.queryText == ''}>
				    <SearchIcon />
				</IconButton>
				<Divider style={{ m: 0.5 }} orientation="vertical" flexItem />
				<IconButton sx={{ p: '10px', m: 1 }} className={classes.paddedIconButton} aria-label="delete" size="small" disabled={i + 1 == searches.length} onClick={() => deleteSearch(i)}>
				    <ClearIcon />
				</IconButton>
			    </Paper>
			</form>
		    )
				  ) }			
		</Container>
		
		{ this.state.dataReceived ? renderResults() : null }		
	    </div>
	);
    }
}

export default withStyles(styles)(SearchView);
