import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Link from "@material-ui/core/Link";
import Typography from '@material-ui/core/Typography';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

const useStyles = makeStyles({
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400,
  },
});

function RecursiveTreeView(data) {
    const classes = useStyles();
    
    const renderTree = (nodes) => (
	<TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
	    {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
	</TreeItem>
    );
        
    return (
	<TreeView
	    className={classes.root}
	    defaultCollapseIcon={<ExpandMoreIcon />}
	    defaultExpanded={['root']}
	    defaultExpandIcon={<ChevronRightIcon />}
	>
	    { data.root.map( n => <>
				      { renderTree(n) }
				  </>
			   )
	    }
	</TreeView>
    );
}

class TopicList extends React.Component {
    constructor(props) {
	super(props);
	this.state = {
	    root_nodes: [ ],
	};
    }

    componentDidMount() {
	this.getData();
    }
    
    getData() {
        fetch("https://api.sometrik.com/dataset/topictree", { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			isLoaded: true,
			root_nodes: result
		    });
		},
		(error) => {
		    this.setState({
			isLoaded: true,
			error
		    });
		}	
	    )
    }

    render() {
	const { root_nodes } = this.state;
	
	return (
	    <div>
		<RecursiveTreeView root={root_nodes} />
	    </div>
	);
    }
}
export default TopicList;
