import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Typography from '@material-ui/core/Typography';
import InsightToolbar from "../components/InsightToolbar.js"
import { StyledTableCell, StyledTableRow } from "../components/StyledTable.js";
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
    container: {
	paddingTop: theme.spacing(4),
	paddingBottom: theme.spacing(4),
    },
    table: {
	minWidth: 700,
	padding: "70px"
    }
});

class ExportList extends React.Component {
    constructor(props) {
	super(props);
	
	this.state = {
	    items: []
	};
    }

    componentDidMount() {
	this.getData();
    }

    selectPage(id, event) {
	this.props.history.push('/export/'+id);
	event.preventDefault();
    }

    getData() {
        fetch("https://api.sometrik.com/export/list", { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			isLoaded: true,
			items: result.data
		    });
		},
		(error) => {
		}	
	    )
    }

    render() {
	const { items } = this.state;
	const { classes } = this.props;
	
	return (
	    <div>
		<InsightToolbar app={this.props.app} title="Exports" />

		<Container maxWidth="lg" className={classes.container}>
		    <Table className={classes.table} aria-label="customized table">
			<TableHead>
			    <TableRow>
				<StyledTableCell>Project Name</StyledTableCell>
				<Hidden mdDown><StyledTableCell align="right">From</StyledTableCell></Hidden>
				<Hidden mdDown><StyledTableCell align="right">To</StyledTableCell></Hidden>
				<StyledTableCell align="right">Created</StyledTableCell>
				<StyledTableCell align="right">State</StyledTableCell>
			    </TableRow>
			</TableHead>
			<TableBody>
			    {items.map((item) => (
				<StyledTableRow key={item.id}>
				    <StyledTableCell component="th" scope="item">
					<Typography><Link component={RouterLink} to={ '/export/' + item.id }>{item.project_name}</Link></Typography>
				    </StyledTableCell>
				    <Hidden mdDown><StyledTableCell align="right">{ item.start_date }</StyledTableCell></Hidden>
				    <Hidden mdDown><StyledTableCell align="right">{ item.end_date }</StyledTableCell></Hidden>
				    <StyledTableCell align="right">{ item.created_time }</StyledTableCell>
				    <StyledTableCell align="right">{ item.state_text }</StyledTableCell>
				</StyledTableRow>
			    ))}
			</TableBody>
		    </Table>
		</Container>
	    </div>	    
	);
    }
}

export default withRouter(withStyles(styles)(ExportList));
