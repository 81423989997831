import React from "react";
import { withStyles } from '@material-ui/core/styles';

import Quote from "./Quote";
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Link from "@material-ui/core/Link";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';

const styles = theme => ({
    topContainer: {
	position: 'relative',
	'&:hover $button': {
	    visibility: 'inherit',
	}
    },
    score: {
	marginRight: '0.25em',
	fontSize: '75%',
    },
    topic: {
	marginRight: '0.25em',
	fontSize: '75%',
    },
    emotion: {
	marginRight: '0.25em',
	fontSize: '75%',
    },
    quote: {
	marginLeft: '0.25em',
    },
    button: {
	position: 'absolute',
	right: 0,
	top: 0,
	visibility: 'hidden',
	backgroundColor: '#fff',
    },
    full: {
    },
    limited: {
	'display': '-webkit-box',
	'-webkit-line-clamp': 3,
	'-webkit-box-orient': 'vertical',
	'overflow': 'hidden',
    },
    userInfo: {
	'text-overflow': 'ellipsis',
	'white-space': 'nowrap',
	'overflow': 'hidden',
    },
    dataset: {
	color: '#888',
	'margin-right': '0.25em',
    },
    name: {
	'color': '#888',
    },
    uname: {
	'color': '#333',
	'margin-right': '0.25em',
	'font-weight': 'bold',
    },
});

class Message extends React.Component {
    render() {
	const { user, positive_regex, negative_regex, article, full, fixedSize, classes, topics, emotionLabels, onContextMenu, variant, align, datasets, stats } = this.props;
	
	const is_admin = user && user.is_admin;
	
	let article_topics = [ ], article_emotions = [ ];
	if (article.topics && topics && is_admin) {
	    for (let i = 0; i < article.topics.length; i++) {
		const id = article.topics[i];
		article_topics.push([ id, topics[id] ]);
	    }
	}
	if (article.emotions && emotionLabels && is_admin) {
	    for (let i = 0; i < article.emotions.length; i++) {
		const id = article.emotions[i];
		article_emotions.push([ id, emotionLabels[id] ]);
	    }
	}

	const openMenu = (anchor, article) => {
	    if (onContextMenu) {
		onContextMenu(anchor, article);
	    }
	}

	const has_title = article.title != null;
	const is_small = variant == "small";

	let dataset_name = null;

	if (datasets) {
	    const dataset = datasets[article.dataset_id];
	    if (dataset) dataset_name = dataset.name;
	}
	
	return (
	    <div className={classes.topContainer}>
		<Typography variant="body2" className={full ? classes.full : classes.limited} align={align}>
		    { article_topics.map( t => <Chip size="small" className={classes.topic} key={t[0]} label={t[1]} color="primary" /> ) }
		    { article_emotions.map( t => <Chip size="small" className={classes.emotion} key={t[0]} label={t[1]} color="secondary" /> ) }	    
		    { !is_small && article.score && (is_admin || stats) ? <Chip size="small" className={classes.score} label={article.score} /> : null }
		    <Quote positive_regex={positive_regex} negative_regex={negative_regex} text={article.title} href={article.link} />
		    { !has_title || full ?
		      <Quote positive_regex={positive_regex} negative_regex={negative_regex} text={article.content} href={article.link} />
		      :
		      <Quote prefix=" | " matchesOnly={true} positive_regex={positive_regex} negative_regex={negative_regex} text={article.content} className={classes.quote} />
		    }
		    { article.image_url != null ? <Link href={article.image_url}>[pic]</Link> : null }
		    <Quote prefix=" " quote={true} href={article.sample_link_url} positive_regex={positive_regex} negative_regex={negative_regex} text={article.quote_title} className={classes.quote} />
		    <Quote prefix=" | " matchesOnly={true} quote={true} positive_regex={positive_regex} negative_regex={negative_regex} text={article.quote_content} className={classes.quote} />
		    { fixedSize ? <><br/><br/><br/></> : null }
		</Typography>
		<Typography variant="body2" className={classes.userInfo}>
		    { datasets ? <span className={classes.dataset}>{dataset_name}</span> : null }
		    { article.user_uname != null && article.dataset_id != 6 ? <span className={classes.uname}>@{ article.user_uname }</span> : null }		    
		    { article.user_name != null ? article.user_name : null }
		</Typography>
		{ onContextMenu && (
		    <div className={classes.button}>
			{ article.is_reclassified ? <IconButton size="small"><CheckIcon /></IconButton> : null }
			<IconButton size="small" onClick={ (e) => { openMenu(e.currentTarget, article) } } ><MoreVertIcon /></IconButton>
		    </div>
		)
		}
	    </div>
	);
    }
}

export default withStyles(styles) (Message);
