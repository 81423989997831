import React from "react";
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import InsightToolbar from "../components/InsightToolbar.js"

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    paper: {
	marginTop: theme.spacing(8),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center'
    },
    form: {
	width: '100%',
	marginTop: theme.spacing(1)
    },
    submit: {
	margin: theme.spacing(3, 0, 2)
    }
});


class User extends React.Component {
    constructor(props) {
        super(props);
	
	this.state = this.getInitialState();
    }

    getInitialState() {
	return { newpass: "", newpass2: "", error: true, hasWritten: false, hasWritten2: false, mismatchError: true };
    }

    handleSubmit = () => {
	const requestOptions = {
	    method: 'POST',
	    credentials: 'include',
	    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
	    body: new URLSearchParams({
                'password': this.state.newpass
	    }) 
        };
        fetch("https://api.sometrik.com/user/update", requestOptions)
	    .then(res => res.json())
	    .then(
		(result) => {
		    if (result.data.status == "1") {
			this.props.app.addToast.bind(this.props.app)("Password changed");	
		    } else {
			this.props.app.addToast.bind(this.props.app)("Failed to change password");	
		    }
		    this.setState(this.getInitialState());
		},
		(error) => {
		    this.props.app.addToast.bind(this.props.app)("Failed to change password");		    
		}
	    )
    }
    
    
    render() {
	const { classes } = this.props;

	const handleInputChange = (event) => {
            const target = event.target;

            if (target.name === "newpass") {
                this.setState({ error: target.value.length < 8, mismatchError: target.value !== this.state.newpass2 });
                if (target.value.length > 0) this.setState({ hasWritten: true });
            }

            if (target.name === "newpass2") {
                this.setState({ mismatchError: target.value !== this.state.newpass});
                if (target.value.length > 0) this.setState({ hasWritten2: true });
            }

            this.setState({
                [target.name]: target.value
            });
        }
	
	return (
	    <div className={classes.root}>
		<InsightToolbar app={this.props.app} title="User Settings" />

		<Container component="main" maxWidth="xs">
		    <div className={classes.paper}>
			<Typography component="h1" variant="h5" align="left">
			    Change password
			</Typography>
			<TextField
			    margin="normal"
			    required
			    fullWidth
			    name="newpass"
			    label="New password"
			    type="password"
			    value={this.state.newpass}
			    onChange={handleInputChange}
			    error={ this.state.error && this.state.hasWritten }
			    helperText={ this.state.error && this.state.hasWritten ? 'Password too short. Minimum length 8 letters.' : null }
			/>
			<TextField
                            margin="normal"
			    required
                            fullWidth
                            name="newpass2"
                            label="Verify new password"
                            type="password"
			    value={this.state.newpass2}
			    onChange={handleInputChange}
			    error={ this.state.mismatchError && this.state.hasWritten2 }
			    helperText={ this.state.mismatchError && this.state.hasWritten2 ? 'The passwords don\'t match.' : null }
			/>
			<Button			    
			    fullWidth
			    variant="contained"
			    color="primary"
			    className={classes.submit}
			    onClick={this.handleSubmit}
			    disabled={ this.state.error || this.state.mismatchError}
			>
			    Submit
			</Button>
		    </div>
		</Container>		
	    </div>
	);
    }
}

export default withStyles(styles) (User);
