import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Popover from '@material-ui/core/Popover';
import MuiAlert from '@material-ui/lab/Alert';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Hidden from '@material-ui/core/Hidden';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import FilterBox from "../components/FilterBox";

const styles = theme => ({
    root: {
	flexGrow: 1
    },
    flex: {
	flex: 1
    },
    menuButton: {
	marginLeft: -12,
	marginRight: 20
    },
    toolbarMargin: theme.mixins.toolbar,
    aboveDrawer: {
	zIndex: theme.zIndex.drawer + 1
    },
    popOver: {
	float: "left",
	height: "80%"
    },
    time: {
	color: '#848282'
    },
});

class InsightToolbar extends React.Component {
    constructor(props) {
	super(props);

	this.state = {
	    anchor1: null,
	    anchor2: null,
	    new_notification_count: 0,
	    notifications : { "new" : [ ], "old" : [ ] }
	};
    }

    componentDidMount() {
	this.getData();
	if (this.props.title) {
	    document.title = this.props.title + " - Sometrik Insight";
	} else {
	    document.title = "Sometrik Insight";
	}
    }

    componentWillUnmount() {
        clearTimeout(this.intervalId);
    }

    componentDidUpdate() {
	if (this.props.title) {
            document.title = this.props.title + " - Sometrik Insight";
        } else {
            document.title = "Sometrik Insight";
        }
    }

    clearAndClose = () => {
	const news = this.state.notifications.new;
	if (news.length > 0) {
            const requestOptions = {
		method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
		credentials: 'include',
                body: new URLSearchParams({
                    max_id: news[0].id
                })
            };

            fetch("https://api.sometrik.com/user/clear_notifications", requestOptions)
                .then(response => response.json())
                .then(data => {

                });
        }
	this.setState({ anchor1: null, new_notification_count: 0 });
    }
	
    getData = () => {
        fetch("https://api.sometrik.com/user/notifications", { credentials: 'include' } )
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        notifications: result.data,
			new_notification_count: result.data.new.length
                    });
                    this.intervalId = setTimeout(this.getData.bind(this), 10000);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.intervalId = setTimeout(this.getData.bind(this), 10000);
                }
            )
    }
    
    handleClick = (e) => {
	this.setState({anchor1: e.currentTarget});
    }

    handleClick2 = (e) => {
        this.setState({anchor2: e.currentTarget});
    }
    
    handleClose = () => {
	this.clearAndClose();
    }

    handleClose2 = () => {
	this.setState({anchor2: null});
    }
    
    render() {

	const renderMessages = (title, data) => {
	    return(
                <div>
		    <Box display="flex" justifyContent="left" m={0.5} p={0.5}>{title}</Box>
                    {data.map(item => (
                        <MuiAlert key={item.id} severity="info" action={ item.link != null ? <Button color="secondary" onClick={() => { this.props.history.push(item.link) }}>Open</Button> : null}>{item.text} <br/><Typography variant="caption" className={classes.time}>{timeAgo(item.t)}</Typography></MuiAlert>
                    ))}
                </div>
	    );
	}
	
	const { filter, filterNames, classes } = this.props;
	const { notifications } = this.state;
	let f = this.props.app.getUser.bind(this.props.app);
	const username = f();
	const username2 = username.name;
	
	const timeAgo = (time) => {
	    const seconds = Math.round((new Date()).getTime() / 1000) - time;

	    if (seconds >= 30 * 86400) {
		let months = Math.round(seconds / (30 * 86400));
		return months + " month" + (months > 1 ? "s" : "") + " ago";
	    } else if (seconds >= 86400) {
		let days = Math.round(seconds / 86400);
		return days + " day" + (days > 1 ? "s" : "") + " ago";
	    } else if (seconds >= 3600) {
		let hours = Math.round(seconds / 3600);
                return hours + " hour" + (hours > 1 ? "s" : "") + " ago";
            } else if (seconds >= 60) {
		let minutes = Math.round(seconds / 60);
                return minutes + " minute" + (minutes > 1 ? "s" : "") + " ago";
            } else {
		return "Now";
	    }
	}
	
	return (
	    <div>
		<AppBar className={classes.aboveDrawer}>
		    <Toolbar>
			<IconButton
			    className={classes.menuButton}
			    color="inherit"
			    aria-label="Menu"
			    onClick={this.props.app.toggleMenu.bind(this.props.app)}
			>
			    <MenuIcon />
			</IconButton>
			
			<Typography noWrap={true} variant="h6" color="inherit" className={classes.flex}>{this.props.title}</Typography>
			{ this.props.subtitle != null ? (<Hidden mdDown><Typography noWrap={true} color="inherit" className={classes.flex}>{this.props.subtitle}</Typography></Hidden>) : null }

			<FilterBox names={filterNames} filter={filter} onRemoveFilter={this.props.onRemoveFilter} />

			<Hidden mdDown>{ username2 }</Hidden>
			
			<IconButton onClick={this.handleClick} color="inherit">
			    <Badge badgeContent={this.state.new_notification_count} color="primary">
				<NotificationsIcon />
			    </Badge>
			</IconButton>
			
			<Popover
			    className={classes.popOver}
			    open={this.state.anchor1 != null}
			    anchorEl={this.state.anchor1}
			    onClose={this.handleClose}
			    anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left'
			    }}
			    transformOrigin={{
				vertical: 'top',
				horizontal: 'center'
			    }}
			>
			    {this.state.notifications.new.length > 0 ? renderMessages("New", notifications.new) : null}
			    {this.state.notifications.old.length > 0 ? renderMessages("Old", notifications.old) : null}
			    
			</Popover>
			
			<IconButton onClick={this.handleClick2} color="inherit">
                            <Badge color="primary">
                                <PersonIcon />
                            </Badge>
                        </IconButton>

			<Popper open={this.state.anchor2 != null} anchorEl={this.state.anchor2} transition disablePortal>
			    {({ TransitionProps, placement }) => (
				<Grow
				    {...TransitionProps}
				    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
				>
				    <Paper>
					<ClickAwayListener onClickAway={this.handleClose2}>
					    <MenuList autoFocusItem={this.state.anchor2 != null} id="menu-list-grow">
						<MenuItem onClick={ () => this.props.history.push('/user')}>
						    <ListItemIcon>
							<SettingsIcon />
						    </ListItemIcon>
						    User Settings
						</MenuItem>
						<MenuItem onClick={this.props.app.logout.bind(this.props.app)}>
						    <ListItemIcon>
							<ExitToAppIcon />
						    </ListItemIcon>
						    Log Out
						</MenuItem>
					    </MenuList>
					</ClickAwayListener>
				    </Paper>
				</Grow>
			    )}
			</Popper>    	
		    </Toolbar>
		</AppBar>

		<div className={classes.toolbarMargin} />
	    </div>
	)
    }
}

export default withRouter(withStyles(styles)(InsightToolbar));
