import React from "react";

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class CreateUserDialog extends React.Component {
    constructor(props) {
	super(props);
	this.state = this.getInitialState();
    }        

    getInitialState() {
	return {
	    username: '',
            password: '',
	    email: '',
	    team: null,
            is_admin: false,
            usernameError: true,
            usernameExistsError: false,
            passwordMissingError: true,
	    passwordTooShortError: false,
            hasWritten: false,
	    hasWrittenUsername: false,
	}
    }

    componentDidMount() {
	this.setState({username: this.props.selectedUser});
    }

    componentDidUpdate(prevProps) {
	if ((prevProps.selectedUser == null && this.props.selectedUser != null) ||
	    (prevProps.selectedUser != null && this.props.selectedUser != null && prevProps.selectedUser.id !== this.props.selectedUser.id)) {
	    this.setState({username: this.props.selectedUser.name, is_admin: this.props.selectedUser.is_admin, team: this.props.selectedUser.team_id, email: this.props.selectedUser.email});
	}

	if (prevProps.selectedUser != null && this.props.selectedUser == null) {
	    this.setState({username: '', team: null, is_admin: false});
	}
    }
    
    render() {
	const onCreateUser = () => {
	    if(this.props.selectedUser) {
		this.props.onUpdateUser(this.props.selectedUser.id, this.state.password, this.state.is_admin, this.state.team, this.state.email);
	    }
	    
	    if(!this.props.selectedUser) {
		this.props.onCreateUser(this.state.username, this.state.password, this.state.is_admin, this.state.team, this.state.email);
	    }

	    this.setState(this.getInitialState());
	}

	const onClose = () => {
	    this.setState(this.getInitialState());
	    this.props.onClose();
	}

	const isDisabled = () => {
	    if (this.props.selectedUser) {
		return this.state.passwordTooShortError;
	    } else {
		return this.state.passwordMissingError || this.state.passwordTooShortError || this.state.usernameError || this.state.usernameExistsError;
	    }
	}

	const checkIfUserExists = (name) => {
	    fetch("https://api.sometrik.com/user/show?" + (new URLSearchParams({ name: name })), { credentials: 'include' } )
		.then(res => res.json())
		.then(
		    (result) => {
			this.setState({ usernameExistsError: result.data != null });
		    },
		    (error) => {
		    }
		);
	}

	const handleInputChange = (event) => {
            const target = event.target;
	    const value = target.type === "checkbox" ? target.checked : target.value;

	    if (target.name === "password") {
		this.setState({ passwordTooShortError: target.value.length < 8 && target.value.length !== 0, passwordMissingError: target.value.length === 0 });
		if (target.value.length > 0) this.setState({ hasWritten: true });
	    }

	    if (target.name === "username") {
            	if (target.value !== this.state.username) {
		    this.setState({ usernameExistsError: false });
		    if (target.value.length > 0) checkIfUserExists(target.value);
		}
		    
		this.setState({ usernameError: target.value.length === 0 });
                if (target.value.length > 0) this.setState({ hasWrittenUsername: true });
	    }
	    
	    this.setState({
                [target.name]: value                
	    });
        }
	
	return (
	    <Dialog open={this.props.open} onClose={onClose} aria-labelledby="create-user-dialog-title">
		<DialogTitle id="create-user-dialog-title">{this.props.selectedUser ? "Update user" : "New user" }</DialogTitle>
		<DialogContent>
		    <TextField
			onChange={handleInputChange}
			name="username"
			autoFocus
			margin="dense"
			label="Username"
			fullWidth
			disabled={this.props.selectedUser? true : false}
			value={this.state.username}
			helperText={ this.state.usernameError && this.state.hasWrittenUsername ? 'Specify a username' : (this.state.usernameExistsError ? 'User already exists' : null) }
			error={ (this.state.usernameError && this.state.hasWrittenUsername) || this.state.usernameExistsError }
		    />

		    <TextField
                        onChange={handleInputChange}
                        type="password"
			name="password"
                        margin="dense"
                        label="Password"
                        fullWidth
			value={this.state.password}
			helperText={ (this.state.passwordTooShortError || this.state.passwordMissingError) && this.state.hasWritten ? 'Password too short. Minimum length 8 letters.' : null }
			error={ (this.state.passwordTooShortError || this.state.passwordMissingError) && this.state.hasWritten }
		    />

		    <TextField
                        onChange={handleInputChange}
                        type="email"
                        name="email"
                        margin="dense"
                        label="E-mail address"
                        fullWidth
                        value={this.state.email}
                    />

		    <FormControlLabel
			control={<Checkbox checked={this.state.is_admin} onChange={handleInputChange} name="is_admin" color="primary"/>}
			label="Admin privileges"
		    />

		    <FormControl>
			<InputLabel id="demo-simple-select-label">Team</InputLabel>
			<Select
			    labelId="demo-simple-select-label"
			    id="demo-simple-select"
			    value={this.state.team}
			    onChange={handleInputChange}
			    name="team"
			>
			    {this.props.teams.map((team) => (
				<MenuItem value={team.id}>{team.name}</MenuItem>
			    ))}
			</Select>
		    </FormControl>
		    
		</DialogContent>
		<DialogActions>
		    <Button onClick={onClose} color="primary">
			Cancel
		    </Button>
		    <Button onClick={onCreateUser} color="primary" disabled={isDisabled()}>
			{this.props.selectedUser ? 'Update' : 'Create' }
		    </Button>
		</DialogActions>
	    </Dialog>
	);
    }
}

export default CreateUserDialog;
