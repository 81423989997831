import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chart from "react-apexcharts";

const styles = theme => ({

});

class BrushChart extends React.Component {
    constructor(props) {
	super(props);
	
	this.pendingFilter = null;
	this.onMouseUp = this.onMouseUp.bind(this);
    }

    onMouseUp() {
	if (this.pendingFilter) {
	    this.props.onTimeSelection(this.pendingFilter);
	    this.pendingFilter = null;
	}
    }

    componentDidMount() {
	document.addEventListener('mouseup', this.onMouseUp);	
    }

    componentWillUnmount() {
	document.removeEventListener('mouseup', this.onMouseUp);
    }

    getMaxValue(series) {
	let maxValue = 0;
	for (let i = 0; i < series.length; i++) {
	    const data = series[i].data;
	    if (data) {
		for (let j = 0; j < data.length; j++) {
		    const v = data[j][1];
		    if (v > maxValue) maxValue = v;
		}
	    }
	}
	return Math.ceil(maxValue / 10) * 10;	
    }
    
    render() {
        const { activityData, brushData, isRelative, onClearFixed, fixedTimeSelection, colors, stroke } = this.props;

	const maxValue = this.getMaxValue(activityData);
	const brushMaxValue = this.getMaxValue(brushData);

	const handleBrushSelection = (chart, { xaxis, yaxis }) => {
	    this.pendingFilter = [ Math.round(xaxis.min / 1000), Math.round(xaxis.max / 1000) ];	    
	};
	
	const basicFormatterAbsolute = value => (typeof value === 'number') ? value.toFixed(0) : value;
	const basicFormatter = isRelative ?
	      value => (typeof value === 'number') ? value.toFixed(2) + ' %' : value
	      : basicFormatterAbsolute;

	let options1 = {
	    xaxis: {
		type: 'datetime',
		tooltip: {
		    enabled: false
		}
	    },
	    yaxis: {
		labels: {
		    formatter: basicFormatter,
		},
		tooltip: {
		    enabled: false
		},
		min: 0,
		max: maxValue,
		tickAmount: 5,
	    },
	    chart: {
		id: 'chart2',
		events: {
		    // scrolled: (e, p) => {
		    // 	console.log('chart2 selection');
		    // 	handleActivitySelection(e, p);
		    // },
	            beforeZoom: (e, {xaxis}) => {
			let current_time = Date.now();
			if (xaxis.max > current_time) xaxis.max = current_time;
			let limit = current_time - 3*365*86400*1000;
			if (xaxis.min < limit) xaxis.min = limit;
                        return {
                            xaxis: {
                                min: xaxis.min,
                                max: xaxis.max
                            }
                        }
                    }
                },
		// selection: {
		//     enabled: true,
		//     fill: {
		// 	color: "#444",
		// 	opacity: 0.4
		//     },		    
		// },
		// zoom: {
		//     enabled: true
		// },
		toolbar: {
		    autoSelected: "pan",
		    show: true,
		    tools: {
			download: true,
			selection: false,
			zoom: false,
			zoomin: false,
			zoomout:false,
			pan: false,
			reset: false
		    }
		},
		animations: {
		    enabled: false
		},
	    },
	    tooltip: {
		enabled: true,
		x: {
		    format: 'dd MMM yyyy'
		}
	    },
	    annotations: { },
	    legend: { show: false },
	};
	const options2 = {
	    yaxis: {
		labels: {
		    formatter: basicFormatter,
		},
		tooltip: {
		    enabled: false
		},
		min: 0,
		max: brushMaxValue,
		tickAmount: 1,
	    },
            chart: {
                id: 'chart1',
		events: {
		    brushScrolled: function(e, p) {
			onClearFixed();
			handleBrushSelection(e, p);
		    },
                },
                type: 'area',
                brush: {
                    target: 'chart2',
                    enabled: true
                },
                selection: {
                    enabled: true,
                    // xaxis: {
		    // 	min: new Date('19 Jun 2017').getTime(),
		    // 	max: new Date('14 Aug 2017').getTime()
                    // }
                },
		animations: {
		    enabled: false
		},
            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 0.91,
                    opacityTo: 0.1,
                }
            },
            xaxis: {
                type: 'datetime',
                tooltip: {
                    enabled: false
                }
            },
        };
	
	if (colors) {
	    options1.colors = options2.colors = colors;
	}
	if (stroke) {
	    options1.stroke = stroke;
	} else {
	    options1.stroke = {
                curve: 'smooth',
		width: 2,
		dashArray: 0
            };
	}
	
	if (fixedTimeSelection) {
	    options2.chart.selection.xaxis = { min: fixedTimeSelection[0] * 1000, max: fixedTimeSelection[1] * 1000 };
	}

        return (
	    <>
		<Chart options={options1} series={activityData} type="line" height={175} />
		<Chart options={options2} series={brushData} type="area" height={100} />
	    </>
	);
    }
}

export default withStyles(styles) (BrushChart);
