import React from "react";

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ReclassifyDialog from "../components/ReclassifyDialog";
import ContentWindow from "./ContentWindow";

const styles = theme => ({
    contentTable: {
	fontSize: '8pt',
    },
});

class ContentView extends React.Component {
    constructor(props) {
	super(props);
	this.windows = [
	    { id: 2, name: 'Now' },
            { id: 3, name: '5 Minutes' },
            { id: 4, name: '15 Minutes' },
            { id: 5, name: '1 Hour' },            
            { id: 6, name: '8 Hours' },
            { id: 7, name: '1 Day' },
            { id: 8, name: '1 Week' },
            { id: 9, name: '1 Month' },
            { id: 10, name: '6 Months' },
            { id: 11, name: '1 Year' },
            { id: 12, name: '2 Years' },
            { id: 13, name: '5 Years' },
            { id: 14, name: '10 Years' },
            { id: 15, name: '20 Years' },    
            { id: 16, name: '...' },
        ]
	this.state = {
	    anchor: null,
	    reclassify: false
	};
    }

    render() {
	const { classes } = this.props;
	
	console.log("rendering content");
	
	const handleClose = () => {
            this.setState({ anchor: null });
	}

	const openLink = () => {
	    window.open(this.state.selectedArticle.link);
	    handleClose();
	}

	const reclassify = () => {
	    this.setState({ reclassify: true });
	    handleClose();
	}

	const reclassifyArticle = (id, emotions) => {
	    let params = {
		'id': id,
		'emotions': emotions.join(','),
	    };
	    
	    const requestOptions = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
                body: new URLSearchParams(params)
            };
            fetch("https://api.sometrik.com/train/add", requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.data.status == "1") {
                            this.props.app.addToast.bind(this.props.app)("ok");
                        } else {
                            this.props.app.addToast.bind(this.props.app)("fail");
                        }
                    },
                    (error) => {
                        this.props.app.addToast.bind(this.props.app)("fail");
                    }
                )
	}

	const onContextMenu = (anchor, article) => {
	    this.setState({ anchor: anchor, selectedArticle: article });
	}

	return (
	    <>
		<Table size="small" className={classes.contentTable}>
		    <TableBody>
			{this.windows.map((w, i) => <ContentWindow onContextMenu={onContextMenu} key={w.id} type={w.id} title={w.name} projectId={this.props.projectId} project={this.props.project} primary={i === 0} onHideBackdrop={this.props.onHideBackdrop} />)}
		    </TableBody>
		</Table>
		
		<Menu
		    id="simple-menu"
		    anchorEl={this.state.anchor}
		    keepMounted
		    open={this.state.anchor != null}
		    onClose={handleClose}
		>
		    <MenuItem onClick={ () => openLink() } disabled={this.state.selectedArticle == null || this.state.selectedArticle.link == null}>View</MenuItem>
		    <MenuItem onClick={ () => reclassify() }>Reclassify</MenuItem>
		</Menu>
		
		<ReclassifyDialog open={this.state.reclassify} article={this.state.selectedArticle} onClose={ () => this.setState({ reclassify: false }) } onReclassify={reclassifyArticle} />
	    </>
	);
    }
}

export default withStyles(styles)(ContentView);
