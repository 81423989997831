import React from "react";
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Message from "./Message";

const styles = theme => ({
    messagebox: {
	marginBottom: '1em',
    },
});

class ReclassifyDialog extends React.Component {
    constructor(props) {
	super(props);
	this.state = this.getInitialState();
    }     

    getInitialState() {
	return {
	    anger: false,
	    anticipation: false,
	    disgust: false,
	    fear: false,
	    joy: false,
	    sadness: false,
	    surprise: false,
	    trust: false,
	    
	    positive: false,
	    negative: false,
	}
    }

    render() {
	const { article, classes } = this.props;

	const handleSubmit = () => {
	    let emotions = [ ], sentiment = [ ];
	    if (this.state.anger) emotions.push(1);
	    if (this.state.anticipation) emotions.push(2);
	    if (this.state.disgust) emotions.push(3);
	    if (this.state.fear) emotions.push(4);
	    if (this.state.joy) emotions.push(5);
	    if (this.state.sadness) emotions.push(6);
	    if (this.state.surprise) emotions.push(7);
	    if (this.state.trust) emotions.push(8);
	    if (this.state.positive) sentiment.push(1);
	    if (this.state.negative) sentiment.push(-1);
	    
	    console.log("article id:" + article.id + " emotions: " + emotions.join(', '));

	    this.props.onReclassify(article.id, emotions, sentiment);
	    onClose();
	}

	const onClose = () => {
	    this.setState(this.getInitialState());
	    this.props.onClose();
	}
	
	const handleInputChange = (event) => {
	    const target = event.target;
	    
            this.setState({
                [target.name]: target.checked
            });
	};
	
	return (
	    <Dialog open={this.props.open} onClose={onClose} aria-labelledby="reclassify-dialog-title">
		<DialogTitle id="reclassify-dialog-title">Reclassify</DialogTitle>
		
		<DialogContent>
		    <div className={classes.messagebox} >
			<Message article={article} full={true} />
		    </div>

		    { article && (article.rss_post_id == null || article.rss_post_id == 0) ?
		      <>
			  <FormControl>
			      <FormLabel>Emotion</FormLabel>
			      <FormGroup>
				  <FormControlLabel
				      control={<Checkbox checked={this.state.anger} onChange={handleInputChange} name="anger" />}
				      label="Anger"
				  />
				  <FormControlLabel
				      control={<Checkbox checked={this.state.anticipation} onChange={handleInputChange} name="anticipation" />}
				      label="Anticipation"
				  />
				  <FormControlLabel
				      control={<Checkbox checked={this.state.disgust} onChange={handleInputChange} name="disgust" />}
				      label="Disgust"
				  />
				  <FormControlLabel
				      control={<Checkbox checked={this.state.fear} onChange={handleInputChange} name="fear" />}
				      label="Fear"
				  />
				  <FormControlLabel
				      control={<Checkbox checked={this.state.joy} onChange={handleInputChange} name="joy" />}
				      label="Joy"
				  />
				  <FormControlLabel
				      control={<Checkbox checked={this.state.sadness} onChange={handleInputChange} name="sadness" />}
				      label="Sadness"
				  />
				  <FormControlLabel
				      control={<Checkbox checked={this.state.surprise} onChange={handleInputChange} name="surprise" />}
				      label="Surprise"
				  />
				  <FormControlLabel
				      control={<Checkbox checked={this.state.trust} onChange={handleInputChange} name="trust" />}
				      label="Trust"
				  />
			      </FormGroup>
			  </FormControl>

		      	  <FormControl>
			      <FormLabel>Sentiment</FormLabel>
			      <FormGroup>
				  <FormControlLabel
				      control={<Checkbox checked={this.state.positive} onChange={handleInputChange} name="positive" />}
				      label="Positive"
				  />
				  <FormControlLabel
				      control={<Checkbox checked={this.state.negative} onChange={handleInputChange} name="negative" />}
				      label="Negative"
				  />
			      </FormGroup>
			  </FormControl>		    
		      </>
		      : null }
		</DialogContent>
		
		<DialogActions>
		    <Button onClick={onClose} color="primary">
			Cancel
		    </Button>
		    <Button onClick={handleSubmit} color="primary">
			Submit
		    </Button>
		</DialogActions>
	    </Dialog>
	);
    }
}

export default withStyles(styles) (ReclassifyDialog);
