import React from "react";
import Button from '@material-ui/core/Button';
import InsightToolbar from "../components/InsightToolbar.js"
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import TwitterIcon from '@material-ui/icons/Twitter';

import CardTitle from '../components/CardTitle';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import PropTypes from 'prop-types';

import GroupDialog from '../components/GroupDialog.js';
import RecordDialog from '../components/RecordDialog.js';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent'
    }
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2)
    }
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0)
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1
  }
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
            <div className={classes.labelRoot}>
		{ LabelIcon ? <LabelIcon color="inherit" className={classes.labelIcon} /> : null }
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired
};

const styles = theme => ({
    buttons: {
	'& > *': {
	    margin: theme.spacing(1)
	}
    },
    container: {
	padding: 0,
	paddingTop: theme.spacing(4),
	paddingBottom: theme.spacing(4)
    },
    chart: {
	boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
    },
});

class EditProjectView extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
	this.state.project = { "records" : [] };
	this.icons = {
	    1: FolderOpenIcon,
	    2: ZoomInIcon,
	    // 3 = topic
	    4: TwitterIcon,
	    5: RssFeedIcon,
	    6: ZoomOutIcon,
	    // 7 = emotion
	    8: ZoomOutIcon,
	};
    }

    getInitialState() {
	return {
            open1: false,
            open2: false,
	};
    }

    showRecordDialog() {
        this.setState({ open2: true })
    }

    showGroupDialog() {
        this.setState({ open1: true })
    }

    closeRecordDialog() {
        this.setState({ open2: false })
    }

    closeGroupDialog() {
        this.setState({ open1: false })
    }
    
    getData = () => {
	const { id } = this.props.match.params;
        const body = new URLSearchParams({ id: id, records: 1 });
        fetch("https://api.sometrik.com/project/show?" + body, { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			isLoaded: true,
			project: result.data
		    });
		},
		(error) => {
		    this.setState({
			isLoaded: true,
			error
		    });
		}

	    )
    }

    componentDidMount() {
	this.getData();
    }

    handleSubmit(type, name) {
	const requestOptions = {
	    method: 'POST',
	    headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
	    credentials: 'include',
	    body: new URLSearchParams({
		'value': name,
		'type': type,
		'id': this.state.project.id
	    })
	};

	fetch("https://api.sometrik.com/project/add_record", requestOptions)
	    .then(response => response.json())
	    .then(
		(data) => {
		    this.props.app.addToast.bind(this.props.app)("Record added");	
		    this.getData();
		},
		(error) => {
		    this.props.app.addToast.bind(this.props.app)("Failed to add record", "error");
		}
	    );
	
	this.setState(this.getInitialState());
    }

    render() {
	const { classes } = this.props;

	const handleInputChange = (event) => {
            const target = event.target;
	    let project = this.state.project;
	    project[target.name] = target.value;
	    
	    this.setState({ project });	
        }
		
	const { project } = this.state;

	const renderTree = (items) => {
	    return(
		items.map((item) => (
                    <StyledTreeItem key={item.id} nodeId={item.id} labelText={item.label} labelInfo={item.activity} bgColor={item.type === 6 ? '#e11' : null} labelIcon={this.icons[item.type] } >
			{item.children != null ? renderTree(item.children) : null}
		    </StyledTreeItem>
                ))
	    );
	}

	const onUpdateSettings = () => {
	    const project = this.state.project;
	    const requestOptions = {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
		body: new URLSearchParams({
                    'id': project.id,
		    'name': project.name,
		    'lda_alpha': project.lda_alpha,
		    'lda_beta': project.lda_beta,
		    'num_ambassadors': project.num_ambassadors,
		    'num_topics': project.num_topics,
		    'lang': project.lang,
		})
            };
            fetch("https://api.sometrik.com/project/update", requestOptions)
		.then(res => res.json())
		.then(
		    (result) => {
			if (result.data.status == "1") {
			    this.props.app.addToast.bind(this.props.app)("Project update");	
			} else {
			    this.props.app.addToast.bind(this.props.app)("Failed to update project");	
			}
		    },
		    (error) => {
			this.props.app.addToast.bind(this.props.app)("Failed to update project");		    
		    }
		)
	}
	
	return (
	    <>
		<InsightToolbar app={this.props.app} title={project.name} />
		
		<Container maxWidth="lg" className={classes.container}>
		    <Grid container spacing={3}>
			<Grid item xs={12} md={6} lg={6}>
			    <Card className={classes.chart}>
				<CardContent>
				    <CardTitle>Settings</CardTitle>
				    <div>
					<TextField
					    name="name"
					    onChange={handleInputChange}
					    margin="dense"
					    label="Project name"
					    fullWidth
					    error={ false }
					    helperText={ null }
					    value={this.state.project.name || ''}
					/>
					<TextField
					    name="num_topics"
					    onChange={handleInputChange}
					    margin="dense"
					    label="Number of topics"
					    fullWidth
					    error={ false }
					    helperText={ null }
					    value={this.state.project.num_topics || ''}
					/>
					<TextField
					    name="lda_alpha"
					    onChange={handleInputChange}
					    margin="dense"
					    label="Alpha"
					    fullWidth
					    error={ false }
					    helperText={ null }
					    value={this.state.project.lda_alpha || ''}
					/>
					<TextField
					    name="lda_beta"
					    onChange={handleInputChange}
					    margin="dense"
					    label="Beta"
					    fullWidth
					    error={ false }
					    helperText={ null }
					    value={this.state.project.lda_beta || ''}
					/>
					<TextField
					    name="num_ambassadors"
					    onChange={handleInputChange}
					    margin="dense"
					    label="Number of Ambassadors"
					    fullWidth
					    error={ false }
					    helperText={ null }
					    value={this.state.project.num_ambassadors || ''}
					/>
					<Select
					    value={this.state.project.lang || ''}
					    name="lang"
					    onChange={handleInputChange}
					>
					    <MenuItem value='fi'>Finnish</MenuItem>
					    <MenuItem value='en'>English</MenuItem>
					    <MenuItem value='se'>Swedish</MenuItem>
					</Select>
				    </div>
				</CardContent>
				<CardActions>
				    <Button onClick={onUpdateSettings} variant="contained" color="primary">Update</Button>
				</CardActions>
			    </Card>
			</Grid>

			<Grid item xs={12} md={6} lg={6}>
			    <Card className={classes.chart}>
				<CardContent>
				    <CardTitle>Records</CardTitle>
				    <TreeView
					className={classes.root}
					defaultExpanded={['3']}
					defaultCollapseIcon={<ArrowDropDownIcon />}
					defaultExpandIcon={<ArrowRightIcon />}
					defaultEndIcon={<div style={{ width: 24 }} />}
				    >
					{renderTree(project.records)}
				    </TreeView>
				</CardContent>
				<CardActions>
				    <Button onClick={ () => this.setState({open1: true}) } variant="contained" color="primary">New Group</Button>
				    <Button onClick={ () => this.setState({open2: true}) } variant="contained" color="primary">New Record</Button>
				</CardActions>
			    </Card>
			</Grid>		   		    
		    </Grid>
		</Container>
		
		<GroupDialog open={this.state.open1} onSubmit={this.handleSubmit.bind(this)} onClose={this.closeGroupDialog.bind(this)}/>
		<RecordDialog open={this.state.open2} onSubmit={this.handleSubmit.bind(this)} onClose={this.closeRecordDialog.bind(this)}/>
		
	    </>
	);

    }
}

    
export default withRouter(withStyles(styles)(EditProjectView));
