import React from "react";
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from "@material-ui/core/Link";
import Typography from '@material-ui/core/Typography';
import CreateUserDialog from './CreateUserDialog';
import {StyledTableCell, StyledTableRow} from "../components/StyledTable.js";

const styles = theme => ({
    button: {
        marginTop: '10px',
    },
});

class UserList extends React.Component {
    constructor(props) {
	super(props);
	this.state = {
	    items: [],
	    teams: [],
	    dialogOpen: false,
	    selectedUser: null
	};
    }

    componentDidMount() {
	this.getData();
    }

    selectPage(id) {
	this.props.history.push('/edituser/'+id);
    }

    getData() {
        fetch("https://api.sometrik.com/user/list", { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			isLoaded: true,
			items: result.data,
			teams: result.teams
		    });
		},
		(error) => {
		    this.setState({
			isLoaded: true,
			error
		    });
		}	
	    )
    }

    render() {
	const { items } = this.state;
	const { classes } = this.props;
	
	const showCreateDialog = () => {
	    this.setState({ selectedUser: null, dialogOpen: true });
	}
	const hideDialog = () => {
	    this.setState({ dialogOpen: false, selectedUser: null});
	}
	const showUpdateDialog = (user) => {
	    this.setState({ selectedUser: user, dialogOpen: true });
	}
	
	const createUser = (name, pass, is_admin, team, email) => {
	    const requestOptions = {
		method: 'POST',
		headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
		credentials: 'include',
		body: new URLSearchParams({
		    'name': name,
		    'password': pass,
		    'is_admin': is_admin,
		    'team_id': team,
		    'email': email
		})
	    };

	    fetch("https://api.sometrik.com/user/create", requestOptions)
		.then(response => response.json())
		.then(
		    (data) => {
			this.props.app.addToast.bind(this.props.app)("User created successfully");	
			this.getData();
		    },
		    (error) => {
			this.props.app.addToast.bind(this.props.app)("Failed to create user", "error");
		    }
		);	    
	    hideDialog();
	}

	const updateUser = (id, pass, is_admin, team, email) => {
	    const requestOptions = {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
		body: new URLSearchParams({
		    'id': id,
                    'password': pass,
		    'is_admin': is_admin,
		    'team_id': team,
		    'email': email
		})
            };
            fetch("https://api.sometrik.com/user/update", requestOptions)
		.then(res => res.json())
		.then(
                    (result) => {
			if (result.data.status === "1") {
                            this.props.app.addToast.bind(this.props.app)("User updated");
			    this.getData();
			} else {
                            this.props.app.addToast.bind(this.props.app)("Failed to update user");
			}
                    },
                    (error) => {
			this.props.app.addToast.bind(this.props.app)("Failed to update user");
                    }
		)
	    hideDialog();
	}
	
	return (
	    <div>
		<TableContainer component={Paper}>
		    <Table aria-label="customized table">
			<TableHead>
			    <TableRow>
				<StyledTableCell>Username</StyledTableCell>
				<StyledTableCell align="right">Creation Time</StyledTableCell>
				<StyledTableCell align="right">Admin status</StyledTableCell>
				<StyledTableCell align="right">Team</StyledTableCell>
			    </TableRow>
			</TableHead>
			<TableBody>
			    {items.map((item) => (
				<StyledTableRow key={item.id}>
				    <StyledTableCell scope="item">
					<Typography><Link onClick={ () => showUpdateDialog(item)}>{item.name}</Link></Typography>
				    </StyledTableCell>
				    <StyledTableCell align="right">{item.created_time}</StyledTableCell>
				    <StyledTableCell align="right">{item.is_admin}</StyledTableCell>
				    <StyledTableCell align="right">{item.team_name}</StyledTableCell>
				</StyledTableRow>
			    ))}
			</TableBody>
		    </Table>
		</TableContainer>
		<Button variant="contained" color="primary" onClick={showCreateDialog} className={classes.button}>
		    Add a new user
		</Button>
		<CreateUserDialog open={this.state.dialogOpen} onClose={hideDialog} onCreateUser={createUser} onUpdateUser={updateUser} selectedUser={this.state.selectedUser} teams={this.state.teams}/>
	    </div>
	);
    }
}
export default withRouter(withStyles(styles)(UserList));
