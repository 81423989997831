import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardTitle from '../components/CardTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
        width: 275,
        boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)'
    },
    big: {
	marginTop: '3em',
	verticalAlign: 'middle'
    },
    number: {
	fontSize: 100,
    },
    count: {
	marginTop: '-1em',
	color: '#aaa',
    },
    chart: {
        boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
	minHeight: '22em',
    },
    pos: {
	color: '#4575b4',
    },
    neg: {
	color: '#d73027',
    },
});

class IndexValueCard extends React.Component {

    render() {
        const { classes, index, baseIndex, title, n, decimals } = this.props;
	const actualDecimals = decimals != null ? decimals : 0;
	const diff = index != null && baseIndex != null ? 100 * (index / baseIndex - 1) : null;
	
        return (
            <Grid item xs={12} md={4} lg={4}>
                <Card className={classes.chart}>
                    <CardContent>
			<CardTitle>Total</CardTitle>
			<Typography align="center" className={classes.big} title={title}>
                            <span className={classes.number}>{n != null ? n.toFixed(0) : null}</span>
			</Typography>
			{diff != null && diff !== 0 ? <Typography align="center"><span className={diff > 0 ? classes.pos : classes.neg}>{diff > 0 ? '\u25b2' : '\u25bc' } { (diff != null && diff >= 0 ? '+' : '' ) + diff.toFixed(1) + ' %' }</span></Typography> : null}
		    </CardContent>
                </Card>
            </Grid>
        );
    }
}

export default withStyles(styles) (IndexValueCard);
