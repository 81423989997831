import React from 'react';
import Hidden from '@material-ui/core/Hidden';

import TopicCard from "./TopicCard";
import CardTitle from './CardTitle';

import Grid from '@material-ui/core/Grid';
import Carousel from 'react-material-ui-carousel'
import Typography from '@material-ui/core/Typography';

class TopicDeck extends React.Component {
    constructor(props) {
	super(props);
    }

    render() {
        const { project, data, topics, sentimentLabels, sentimentColors, emotionLabels, onUpdateFilter, messages, t, datasets, onClick, onContextMenu } = this.props;
	
	if (data.length == 0) {
	    return null;	    
	} else {
	    let maxValue = 0;
	    for (let i = 0; i < data.length; i++) {
		let days = data[i].days;
		for (let j = 0; j < days.length; j++) {
		    if (days[j] > maxValue) maxValue = days[j];
		}
	    }
	    maxValue = Math.ceil(maxValue / 5) * 5;

	    const showCarousel = (n) => {
		const sa = Array.from(Array(n));
		return (
		    <Carousel autoPlay={false} animation="slide">
			{ Array.from(Array(Math.ceil(data.length / n))).slice(0, n == 1 ? 5 : 3).map( (sii, si) => 
			    <Grid container spacing={3}>
				{ sa.map( (ii, i) =>
				    <Grid item xs={12}>
					<TopicCard project={project} topic={data[n * si + i]} topics={topics} sentimentLabels={sentimentLabels} sentimentColors={sentimentColors} emotionLabels={emotionLabels} onUpdateFilter={onUpdateFilter} messages={messages} t={t} maxValue={maxValue} datasets={datasets} onClick={onClick} onContextMenu={onContextMenu} />
				    </Grid>
				) }		    
			    </Grid>
			) }
		    </Carousel>
		);
	    }
	
	    return <>
		       { showCarousel(1) }
		   </>;
	}
    }
}

export default TopicDeck;
