import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';

const styles = theme => ({
    chip: {
	marginRight: '0.25em',
    },
});

class FilterBox extends React.Component {
    render() {
	const { names, filter, classes } = this.props;

	let a = [ ];
	if (filter) {
	    for (let [key, value] of Object.entries(this.props.filter)) {
		if (key === 't') {
		    a.push( [
			key + '=1',
			() => this.props.onRemoveFilter(key, '1')
		    ] );
		} else if (Array.isArray(value)) {
		    for (let i = 0; i < value.length; i++) {
			a.push( [
			    key + '=' + value[i],
			    () => this.props.onRemoveFilter(key, value[i])
			] );
		    }
		}
	    }
	}
	return <div>
		   { a.map( f => <Chip className={classes.chip} key={f[0]} size="small" label={(names && names[f[0]]) || f[0]} onDelete={f[1]} color="secondary" /> ) }
	       </div>
    }
}

export default withStyles(styles)(FilterBox);
