import React from "react";

import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import InsightToolbar from "../components/InsightToolbar.js"

import TimeSelector from '../components/TimeSelector';

import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import moment from 'moment';

const styles = theme => ({
    root: {
	width: '100%',
    },  
    backButton: {
	marginRight: theme.spacing(1)
    },
    stepperContent: {
	marginTop: theme.spacing(1),
	marginBottom: theme.spacing(1)
    },
    buttons: {
         '& > *': {
             margin: theme.spacing(1),
	     'justify-content': 'center',
	     'align-items': 'center'
         }
    }
});

class ExportWizard extends React.Component {
    state = {
	start_date: new Date(),
        end_date: new Date(),
	project_id: null,
	activeStep: 0,
	export_type: "csv",
	numDays: 1,
	filter_twitter: 0,
	filter_linkedin: 0,
	filter_instagram: 0,
    };

    constructor(props) {
	super(props);
	this.steps = ['Select a Project', 'Select a time frame', 'Finish'];
    }

    setActiveStep(step) {
	this.setState({activeStep: step});
    }

    handleStartDateChange(event, value) {
	this.setState({ start_date: new Date(value), numDays: null });	
    }
    
    handleEndDateChange(event, value) {
	this.setState({ end_date: new Date(value), numDays: null });
    }
    
    selectFixedDays(n) {
	var d1 = moment();
	var d0 = moment().subtract(n, "days");
	this.setState({ start_date: new Date(d0.format("YYYY-MM-DD")), end_date: new Date(d1.format("YYYY-MM-DD"))  });
    }
    
    createExport() {
	var d0 = moment(this.state.start_date);
	var d1 = moment(this.state.end_date);
	
	const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
	    credentials: 'include',
            body: new URLSearchParams({
                'startdate': d0.format("YYYY-MM-DD"),
                'enddate': d1.format("YYYY-MM-DD"),
		'projectid': this.state.project_id,
		'type': this.state.export_type,
		'filter_twitter': this.state.filter_twitter,
		'filter_linkedin': this.state.filter_linkedin,
		'filter_instagram': this.state.filter_instagram,
            })
        };

        fetch("https://api.sometrik.com/project/export", requestOptions)
	    .then(res => res.json())
	    .then(
		(result) => {
		    let export_id = result.data.id;
		    this.props.history.push('/export/' + export_id);
		},
		(error) => {
		   
		}
	    )
    }

    handleNext() {
	if (this.state.activeStep === this.steps.length - 1) {
	    this.createExport();
	} else {
	    this.setActiveStep(this.state.activeStep + 1);
	}
    }

    selectProject(id) {
	this.setState({ project_id: id });
	this.handleNext();
    }

    selectProjectRadio = (e) => {
	this.setState({project_id: e.target.value});
	this.handleNext();
    }

    selectProjectList = (number) => {
	this.setState({project_id: number});
        this.handleNext();
    }

    selectExportType = (e) => {
	this.setState({export_type: e.target.value});
    }
    
    handleBack() {
	this.setActiveStep(this.state.activeStep - 1);
    }

    render() {
	
	const onTimeSelect = (n) => {
            this.setState({numDays: n});
	    if (n != null) {
                this.selectFixedDays(n - 1);
            }
        };
	
	//<Button onClick={this.selectProject.bind(this, item.id)}>{item.name}</Button>
	const activeStep = this.state.activeStep;
	const { classes, app } = this.props;

	const selectProject = () => {
	    return (<div>
			<Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">

			    <List component="nav" aria-label="project" value={this.state.project_id} subheader={
				      <ListSubheader component="div" id="nested-list-subheader">
					  Project
				      </ListSubheader>
				  }>
				{ app.getRealtimeProjects().map((item) => (
				    <React.Fragment key={item.id}>
					<ListItem
					    button
					    value={item.id}
					    selected={this.state.project_id === item.id}
					    onClick={this.selectProjectList.bind(this, item.id)}
					    
					>
					    <ListItemText primary={item.name} secondary={item.subtitle}/>
					</ListItem>
					<Divider />
				    </React.Fragment>
				))}
			    </List>
			</Box>
		    </div>
		   );
	}
	
	const selectTime = () => {
	    return (<div>
			<TimeSelector n={this.state.numDays} onTimeSelect={onTimeSelect}/>

			<Box display="flex" justifyContent="center" m={1} p={1}>
			    <MuiPickersUtilsProvider utils={MomentUtils}>
				<KeyboardDatePicker
				    disableToolbar
				    variant="inline"
				    format="YYYY-MM-DD"
				    margin="normal"
				    id="start-date-picker-inline"
				    disableFuture="true"
				    label="Start date"
				    value={this.state.start_date}
				    onChange={this.handleStartDateChange.bind(this)}
				    autoOk="true"
				    KeyboardButtonProps={{
					'aria-label': 'change date'
				    }}
				/>
			    </MuiPickersUtilsProvider>
			</Box>

			<Box display="flex" justifyContent="center" m={1} p={1}>			
			    <MuiPickersUtilsProvider utils={MomentUtils}>
				<KeyboardDatePicker
				    disableToolbar
				    variant="inline"
				    format="YYYY-MM-DD"
				    margin="normal"
				    id="end-date-picker-inline"
				    disableFuture="true"
				    label="End date"
				    value={this.state.end_date}
				    onChange={this.handleEndDateChange.bind(this)}
				    autoOk="true"
				    KeyboardButtonProps={{
					'aria-label': 'change date'
				    }}
				/>
			    </MuiPickersUtilsProvider>
			</Box>
		    </div>
		   );
	}

	const handleCheckboxInput = (e) => {
	    const target = e.target;
	    
            this.setState({
		[target.name]: target.checked ? 1 : 0,
            });
	}

	const selectType = () => {
	    return <div>
		       <Box display="flex" justifyContent="center" m={1} p={1}>
			   <FormControl component="fieldset">
			       <FormLabel component="legend">Type</FormLabel>
			       <RadioGroup aria-label="type" name="type" value={this.state.export_type} onChange={this.selectExportType.bind(this)}>
				   { false ? <FormControlLabel value="web" control={<Radio />} label="Web" key="web" /> : null }
				   <FormControlLabel value="csv" control={<Radio />} label="CSV" key="csv" />
				   <FormControlLabel value="csv2" control={<Radio />} label="CSV (w/o RSS & keywords)" key="csv2" />
				   <FormControlLabel value="graph" control={<Radio />} label="Graph" key="graph" />
				   <FormControlLabel value="audiencegraph" control={<Radio />} label="Audience Similarity Graph" key="audiencegraph" />
				   <FormControlLabel value="contentgraph" control={<Radio />} label="Content Similarity Graph" key="contentgraph" />
				   <FormControlLabel value="metadatagraph" control={<Radio />} label="Metadata Similarity Graph" key="metadatagraph" />
				   <FormControlLabel value="excel" control={<Radio />} label="Excel" key="excel" />
				   <FormControlLabel value="topics" control={<Radio />} label="LDA Topics" key="topics" />
			       </RadioGroup>
			   </FormControl>

			   <FormControl component="fieldset">
			       <FormLabel component="legend">Platform Filter</FormLabel>
			       <FormGroup>
				   <FormControlLabel
				       control={<Checkbox checked={this.state.filter_twitter} onChange={handleCheckboxInput} name="filter_twitter" />}
				       label="Twitter"
				   />
				   <FormControlLabel
				       control={<Checkbox checked={this.state.filter_linkedin} onChange={handleCheckboxInput} name="filter_linkedin" />}
				       label="LinkedIn"
				   />
				   <FormControlLabel
				       control={<Checkbox checked={this.state.filter_instagram} onChange={handleCheckboxInput} name="filter_instagram" />}
				       label="Instagram"
				   />
			       </FormGroup>
			   </FormControl>

		       </Box>
		       <Box display="flex" justifyContent="center" m={1} p={1}>
			   <Typography variant="h6">Create analysis</Typography>
		       </Box>
		   </div>;
	}

	const getStepContent = (stepIndex) =>{
	    switch (stepIndex) {
	    case 0: return selectProject();
	    case 1: return selectTime();
	    case 2: return selectType();
	    default: return 'Unknown stepIndex ' + stepIndex;
	    }
	}

	return (
	    <div>
		<InsightToolbar app={this.props.app} title="Create Export" />

		<Paper square >
		    <div className={classes.root}>
			<Stepper activeStep={activeStep} alternativeLabel>
			    {this.steps.map((label) => (
				<Step key={label}>
				    <StepLabel>{label}</StepLabel>
				</Step>
			    ))}
			</Stepper>
			<div>
			    <div>
				<div className={classes.stepperContent}>{getStepContent(activeStep)}</div>
				<Box display="flex" justifyContent="center" p={1}>
				    <Button
					disabled={activeStep === 0}
					onClick={this.handleBack.bind(this)}
					className={classes.backButton}
				    >
					Back
				    </Button>
				    <Button disabled={this.state.project_id == null} variant="contained" color="primary" onClick={this.handleNext.bind(this)}>
					{activeStep === this.steps.length - 1 ? 'Finish' : 'Next'}
				    </Button>
				</Box>
			    </div>
			</div>
		    </div>
		</Paper>
	    </div>
	);
	
    }
}

export default withRouter(withStyles(styles)(ExportWizard));
