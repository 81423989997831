import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from "@material-ui/core/Link";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    statisticsTable: {
        color: '#666',
    },
})

class ShowMoreDialog extends React.Component {    
    render () {
	const { classes, columns } = this.props;

	const renderRows = (data) => {
	    return (
		<>
		    { data.map( item =>
			<>
			    <TableRow key={item[3] != null ? item[3] : item[1]}>
				{ columns && columns > 1 ? <TableCell key="c0" component="th" scope="row">{item[0]}</TableCell> : null }
				<TableCell key="c1" component="th" scope="row">{ item[4] != null ? <Link onClick={item[4]}>{item[1]}</Link> : item[1] }</TableCell>
				<TableCell key="c2" align="right">{item[2]}</TableCell>
			    </TableRow>
			    { item.children ? renderRows(item.children) : null }
			</>
		    ) }		
		</>
	    );
	};
	
	return (
	    <Dialog open={true} onClose={this.props.onClose}>
		<DialogTitle>{this.props.dialogTitle}</DialogTitle>
		<Table size="small" className={classes.statisticsTable}>
                    <TableBody>
			{ this.props.data && renderRows(this.props.data) }
                    </TableBody>
		</Table>
            </Dialog>
	);
    }
}

export default withStyles(styles)(ShowMoreDialog);
