import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import { Link } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BarChartIcon from '@material-ui/icons/BarChart';
import SettingsIcon from '@material-ui/icons/Settings';
import LayersIcon from '@material-ui/icons/Layers';

import { fade } from '@material-ui/core/styles'

const drawerWidth = 240;

const styles = theme => ({
    root: {
	flexGrow: 1
    },
    flex: {
	flex: 1
    },
    drawerPaper: {
	position: "relative",
	width: drawerWidth
    },
    menuButton: {
	marginLeft: -12,
	marginRight: 20
    },
    toolbarMargin: theme.mixins.toolbar,
    aboveDrawer: {
	zIndex: theme.zIndex.drawer + 1
    },

    search: {
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: fade(theme.palette.common.white, 0.15),
	'&:hover': {
	    backgroundColor: fade(theme.palette.common.white, 0.25)
	},
	marginLeft: 0,
	width: '100%',
	[theme.breakpoints.up('sm')]: {
	    marginLeft: theme.spacing(1),
	    width: 'auto'
	}
    },
    searchIcon: {
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
    },
    inputRoot: {
	color: 'inherit'
    },
    inputInput: {
	padding: theme.spacing(1, 1, 1, 0),
	// vertical padding + font size from searchIcon
	paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
	transition: theme.transitions.create('width'),
	width: '100%',
	[theme.breakpoints.up('sm')]: {
	    width: '12ch',
	    '&:focus': {
		width: '20ch'
	    }
	}
    },
    toolbarIcon: {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: '0 8px',
	...theme.mixins.toolbar,
    },
});

class MyDrawer extends React.Component {
    render() {
	const { classes, user, app } = this.props;
		
	const onItemClick = () => {
	    this.props.onclose();
	    // setDrawer(variant === 'temporary' ? false : drawer);
	    // this.setState({ open: false });
	};

	return (
	    <Drawer variant="temporary" open={this.props.open} onClose={this.props.onclose} classes={{ paper: classes.drawerPaper }} >
		<div className={classes.toolbarIcon}>
		    <IconButton onClick={this.props.onclose}>
			<ChevronLeftIcon />
		    </IconButton>
		</div>
		<Divider/>
		<List>
		    { false &&
		      <ListItem button component={Link} to="/" onClick={onItemClick}>
			  <ListItemIcon>
			      <LayersIcon />
			  </ListItemIcon>
			  <ListItemText>Quick Search</ListItemText>
		      </ListItem>
		    }
		    { false &&
		    <ListItem button component={Link} to="/analysisList" onClick={onItemClick}>
			<ListItemIcon>
			    <LayersIcon />
			</ListItemIcon>
			<ListItemText>Manage Analyses</ListItemText>
		    </ListItem>
		    }
		    { user && user.is_admin ?
		      <>
			  <ListItem button component={Link} to="/projects" onClick={onItemClick}>
			      <ListItemIcon>
				  <LayersIcon />
			      </ListItemIcon>
			      <ListItemText>Projects</ListItemText>
			  </ListItem>
			  <ListItem button component={Link} to="/export" onClick={onItemClick}>
			      <ListItemIcon>
				  <LayersIcon />
			      </ListItemIcon>
			      <ListItemText>Create Export</ListItemText>
			  </ListItem>
			  <ListItem button component={Link} to="/export/list" onClick={onItemClick}>
			      <ListItemIcon>
				  <BarChartIcon />
			      </ListItemIcon>
			      <ListItemText>Exports</ListItemText>
			  </ListItem>
			  <ListItem button component={Link} to="/administration" onClick={onItemClick}>
			      <ListItemIcon>
				  <SettingsIcon />
			      </ListItemIcon>
			      <ListItemText>Administration</ListItemText>
			  </ListItem>
			</>
			: null }
		</List>
		<Divider/>
		{ false &&
		  <List>
		      <ListSubheader inset>List of Analyses</ListSubheader>
		      { app.getStaticProjects().map( project => (
			  <ListItem key={project.id} button component={Link} to={'/project/' + project.id} onClick={onItemClick}>
			      <ListItemIcon>
				  <AssignmentIcon />
			      </ListItemIcon>
			      <ListItemText primary={project.name} />
			  </ListItem>
		      ) ) }
		  </List>
		}
		{ user ?
		  <>
		      <Divider/>
		      <List>
			  <ListSubheader inset>Projects</ListSubheader>
			  { app.getRealtimeProjects().map( project => (
			      <ListItem key={project.id} button component={Link} to={'/project/' + project.id} onClick={onItemClick}>
				  <ListItemIcon>
				      <AssignmentIcon />
				  </ListItemIcon>
				  <ListItemText primary={project.name} />
			      </ListItem>
			  ) ) }
		      </List>
		  </>
		  : null }
	    </Drawer>	
	);
    }
}

export default withStyles(styles)(MyDrawer);
