import React from "react";

import Box from '@material-ui/core/Box';

import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import moment from 'moment';

const styles = theme => ({
    root: {
	width: '100%'
    },
    box: {
	'text-align': 'center',
	
         '& > *': {
             margin: theme.spacing(1),
	     'justify-content': 'center',
	     'align-items': 'center'
         }
    }
});

class TimeSelector extends React.Component {
    render() {
	const handleToggle = (e, value) => {
	    if (value != null) {
		this.props.onTimeSelect(value); //muista create exportissa value - 1
	    }
	}
	const handleStartDateChange = (event, value) => {
	    this.props.onTimeSelect(null, new Date(value));
	}
    
	const handleEndDateChange = (event, value) => {
	    this.props.onTimeSelect(null, null, new Date(value));
	}

	const { classes, n, fixedTimeSelection } = this.props;	

	let startDate = null, endDate = null;
	if (fixedTimeSelection) {
	    startDate = new Date(fixedTimeSelection[0] * 1000);
	    endDate = new Date(fixedTimeSelection[1] * 1000);
	}
	
	return (
	    <div className={classes.root}>
		<MuiPickersUtilsProvider utils={MomentUtils}>
		    <div className={classes.box}>
			<ToggleButtonGroup
                            value={n}
                            exclusive
                            onChange={handleToggle}
			    className={classes.el}
			>
                            <ToggleButton value={1} variant="outlined">1 Day</ToggleButton>
                            <ToggleButton value={7} variant="outlined">1 Week</ToggleButton>
			    <ToggleButton value={30} variant="outlined">30 Days</ToggleButton>
                            <ToggleButton value={90} variant="outlined">3 Months</ToggleButton>                        
			</ToggleButtonGroup>
			
			<KeyboardDatePicker
			    disableToolbar
			    variant="inline"
			    format="YYYY-MM-DD"
			    margin="normal"
			    id="start-date-picker-inline"
			    disableFuture="true"
			    label="Start date"
			    value={startDate}
			    onChange={handleStartDateChange}
			    autoOk="true"
			    KeyboardButtonProps={{
				'aria-label': 'change date'
			    }}
			/>
			
			<KeyboardDatePicker
			    disableToolbar
			    variant="inline"
			    format="YYYY-MM-DD"
			    margin="normal"
			    id="end-date-picker-inline"
			    disableFuture="true"
			    label="End date"
			    value={endDate}
			    onChange={handleEndDateChange}
			    autoOk="true"
			    KeyboardButtonProps={{
				'aria-label': 'change date'
			    }}
			/>
                    </div>
		</MuiPickersUtilsProvider>		
	    </div>
	);
	
    }
}

export default withRouter(withStyles(styles)(TimeSelector));
