import React from "react";

import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InsightToolbar from "../components/InsightToolbar.js"
import TextField from '@material-ui/core/TextField';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import TimeSelector from '../components/TimeSelector';

import AddIcon from '@material-ui/icons/Add';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import moment from 'moment';

const styles = theme => ({
    root: {
	width: '100%',
    },  
    backButton: {
	marginRight: theme.spacing(1)
    },
    stepperContent: {
	marginTop: theme.spacing(1),
	marginBottom: theme.spacing(1)
    },
    buttons: {
         '& > *': {
             margin: theme.spacing(1),
	     'justify-content': 'center',
	     'align-items': 'center'
         }
    },
    chart: {
	boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
    },
    button: {
	marginTop: theme.spacing(1),
    }
});

class AnalysisWizard extends React.Component {
    state = {
	start_date: new Date(),
        end_date: new Date(),
	project_id: null,
	activeStep: 0,
	export_type: "web",
	numDays: 1,
	analysis: {
	    'static': 1,
	    lang: 'fi',
	},
	numKeywords: 3
    };

    constructor(props) {
	super(props);
	this.steps = ['Details', 'Timeframe'];
    }

    setActiveStep(step) {
	this.setState({activeStep: step});
    }

    handleStartDateChange(event, value) {
	this.setState({ start_date: new Date(value), numDays: null });	
    }
    
    handleEndDateChange(event, value) {
	this.setState({ end_date: new Date(value), numDays: null });
    }
    
    selectFixedDays(n) {
	let d1 = moment();
	let d0 = moment().subtract(n, "days");
	
	this.setState({ start_date: new Date(d0.format("YYYY-MM-DD")), end_date: new Date(d1.format("YYYY-MM-DD"))  });
    }
    
    createAnalysis() {
	let d0 = moment(this.state.start_date);
	let d1 = moment(this.state.end_date);
	let analysis = this.state.analysis;
	
	const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
	    credentials: 'include',
            body: new URLSearchParams({
		...analysis,
                'startdate': d0.format("YYYY-MM-DD"),
                'enddate': d1.format("YYYY-MM-DD"),
            })
        };

        fetch("https://api.sometrik.com/project/create", requestOptions)
	    .then(res => res.json())
	    .then(
		(result) => {
		    let project_id = result.data.id;
		    this.props.history.push('/project/' + project_id);
		},
		(error) => {
		   
		}
	    )
    }

    handleNext() {
	if (this.state.activeStep === this.steps.length - 1) {
	    this.createAnalysis();
	} else {
	    this.setActiveStep(this.state.activeStep + 1);
	}
    }
    
    handleBack() {
	this.setActiveStep(this.state.activeStep - 1);
    }

    render() {
	
	const onTimeSelect = (n) => {
            this.setState({numDays: n});
	    if (n != null) {
                this.selectFixedDays(n - 1);
            }
        };
	
	const activeStep = this.state.activeStep;
	const { classes, app } = this.props;

	const handleInputChange = (event) => {
	    const target = event.target;
	    let analysis = this.state.analysis;
	    analysis[target.name] = target.value;
	    
	    this.setState({ analysis });
	}

	const addKeyword = () => {
	    this.setState({ numKeywords: this.state.numKeywords + 1 });
	}

	const isValid = () => {
	    if (!this.state.analysis.name) return;
	    let has_keyword = false;
	    for (let i = 0; i < this.state.numKeywords; i++) {		
		if (this.state.analysis['keyword' + i] != null) {
		    has_keyword = true;
		    break;
		}
	    }
	    return has_keyword;
	}
	
	const selectDetails = () => {
	    return (<Card className={classes.chart}>
			<CardContent>
			    <Box display="flex" m={1} p={1}>
				<TextField
				    name="name"
				    onChange={handleInputChange}
				    margin="dense"
				    label="Analysis name"
				    error={ false }
				    helperText={ null }
				    value={this.state.analysis.name || ''}
				/>
			    </Box>
			    <Box display="flex" m={1} p={1}>
				<Select
				    value={this.state.analysis.lang || ''}
				    name="lang"
				    onChange={handleInputChange}
				>
				    <MenuItem value='fi'>Finnish</MenuItem>
				</Select>	
			    </Box>
			    <Box display="flex" m={1} p={1}>
				<div>
				    <Typography>Keywords:</Typography>
				    { Array.from(Array(this.state.numKeywords)).map( (x, i) =>
					<div>
					    <TextField
						name={"keyword" + i}
						onChange={handleInputChange}
						margin="dense"
						label={"Keyword " + (i+1)}
						error={ false }
						helperText={ null }
						value={this.state.analysis['keyword' + i] || ''}
					    />
					</div>
				    ) }
				    <div>
					<Button
					    variant="contained"
					    size="small"
					    className={classes.button}
					    startIcon={<AddIcon />}
					    onClick={addKeyword}
					>
					    Add keyword
					</Button>					
				    </div>
				</div>
			    </Box>
			</CardContent>
		    </Card>
		   );
	}
	
	const selectTime = () => {
	    return (<Card className={classes.chart}>
			<CardContent>
			    <TimeSelector n={this.state.numDays} onTimeSelect={onTimeSelect}/>

			    <Box display="flex" justifyContent="center" m={1} p={1}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
				    <KeyboardDatePicker
					disableToolbar
					variant="inline"
					format="YYYY-MM-DD"
					margin="normal"
					id="start-date-picker-inline"
					disableFuture="true"
					label="Start date"
					value={this.state.start_date}
					onChange={this.handleStartDateChange.bind(this)}
					autoOk="true"
					KeyboardButtonProps={{
					    'aria-label': 'change date'
					}}
				    />
				</MuiPickersUtilsProvider>
			    </Box>

			    <Box display="flex" justifyContent="center" m={1} p={1}>			
				<MuiPickersUtilsProvider utils={MomentUtils}>
				    <KeyboardDatePicker
					disableToolbar
					variant="inline"
					format="YYYY-MM-DD"
					margin="normal"
					id="end-date-picker-inline"
					disableFuture="true"
					label="End date"
					value={this.state.end_date}
					onChange={this.handleEndDateChange.bind(this)}
					autoOk="true"
					KeyboardButtonProps={{
					    'aria-label': 'change date'
					}}
				    />
				</MuiPickersUtilsProvider>
			    </Box>
			</CardContent>
		    </Card>
		   );
	}

	const getStepContent = (stepIndex) =>{
	    switch (stepIndex) {
	    case 0: return selectDetails();
	    case 1: return selectTime();
	    default: return 'Unknown stepIndex ' + stepIndex;
	    }
	}

	return (
	    <>
		<InsightToolbar app={this.props.app} title="Create Analysis" />

		<Container maxWidth="lg" className={classes.container}>
		    <div className={classes.root}>
			<Stepper activeStep={activeStep} alternativeLabel>
			    {this.steps.map((label) => (
				<Step key={label}>
				    <StepLabel>{label}</StepLabel>
				</Step>
			    ))}
			</Stepper>
			<div>
			    <div>
				<div className={classes.stepperContent}>{getStepContent(activeStep)}</div>
				<Box display="flex" justifyContent="center" p={1}>
				    <Button
					disabled={activeStep === 0}
					onClick={this.handleBack.bind(this)}
					className={classes.backButton}
				    >
					Back
				    </Button>
				    <Button disabled={!isValid()} variant="contained" color="primary" onClick={this.handleNext.bind(this)}>
					{activeStep === this.steps.length - 1 ? 'Create Analysis' : 'Next'}
				    </Button>
				</Box>
			    </div>
			</div>
		    </div>
		</Container>
	    </>
	);
	
    }
}

export default withRouter(withStyles(styles)(AnalysisWizard));
