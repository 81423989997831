import React from "react";
import { Switch, Route, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PhoneIcon from '@material-ui/icons/Phone';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import InsightToolbar from "../components/InsightToolbar"
import CubeView from "../components/CubeView"
import ContentView from "../components/ContentView";
import TelebusView from "../components/TelebusView";

import moment from 'moment';

const styles = theme => ({
    tabContainer: {
	padding: 0,
	paddingTop: theme.spacing(4),
	paddingBottom: theme.spacing(4)
    },
    backdrop: {
	zIndex: theme.zIndex.drawer + 1,
	color: '#fff',
    },
    circularprogress: {
	'color': '#fff'
    },
    statusContainer: {
        height: '80vh'
    },
    statusInnerContainer: {
        textAlign: 'center',
        width: '100%'
    }    
});

function TabPanel(props) {
    const { children, ...other } = props;

    return (
	<Typography component="div" role="tabpanel" {...other} >
	    {children}
	</Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node
};

class ProjectView extends React.Component {
    constructor(props) {
	super(props);
	
	this.state = {
	    filter: { v: 0, tv: 0 },
	    numDays: null,
	    staticData: { isLoaded: false },
	    backdropOpen: true,
	    backdropWaiting: false,
	    filterNames: {
		't=1': 'Time selection',

		'is_tagged=0': 'Untagged',
		'is_tagged=1': 'Tagged',

		'own_user=0': 'Muut',
		'own_user=1': 'Omat',
		
		'gender=0': 'Tuntematon',
	    	'gender=1': 'Male',
		'gender=2': 'Female',
		'gender=3': 'Automaatti',
		'gender=4': 'Instituutio',

		'dataset_type=0': 'Unknown',
		'dataset_type=1': 'Media',
		'dataset_type=2': 'Organizational',
		'dataset_type=3': 'Personal Blog',
		'dataset_type=4': 'Forums',
		'dataset_type=5': 'Public Sector',
		'dataset_type=6': 'Social',
		'dataset_type=7': 'Comments',

		'device=0': 'Unknown',
		'device=1': 'Apple',
		'device=2': 'Android',
		'device=3': 'Windows Phone',

		'emotion=0': 'No emotion',
		'emotion=1': 'Anger',
		'emotion=2': 'Anticipation',
		'emotion=3': 'Disgust',
		'emotion=4': 'Fear',
		'emotion=5': 'Joy',
		'emotion=6': 'Sadness',
		'emotion=7': 'Surprise (neg)',
		'emotion=8': 'Trust',
		'emotion=9': 'Surprise (pos)',

		'sentiment=0': 'Neutral',
		'sentiment=1': 'Positive',
		'sentiment=2': 'Negative',
			
		'age_class=1': '< 18',
		'age_class=2': '18-24',
		'age_class=3': '25-34',
		'age_class=4': '35-44',
		'age_class=5': '45-54',
		'age_class=6': '55-64',
		'age_class=7': '>64',

		'education=1': 'peruskoulu',
		'education=2': 'amm./tekn./kauppakoulu',
 		'education=3': 'ylioppilas/lukio',
 		'education=4': 'opisto',
 		'education=5': 'amk',
 		'education=6': 'korkeakoulu',
		'education=7': 'ei tiedä koulutusta',

		'occupation=1': 'yrittäjä',
 		'occupation=2': 'johtava asema',
 		'occupation=3': 'asiantuntija, yl. toimihlö',
 		'occupation=4': 'toimihenkilö',
 		'occupation=5': 'työntekijä',
 		'occupation=6': 'maanviljelijä',
 		'occupation=7': 'opiskelija, koululainen',
 		'occupation=8': 'eläkeläinen',
 		'occupation=9': 'kotiäiti/-isä',
 		'occupation=10': 'työtön',
 		'occupation=11': 'ei tiedä ammattia',
		
		'economic_life_stage=1': 'yksinäistalous',
		'economic_life_stage=2': 'lapseton pari',
		'economic_life_stage=3': 'muu aikuistalous',
		'economic_life_stage=4': 'lapsitalous',

		'household_size=1': '1 henkilö',
		'household_size=2': '2 henkilöä',
		'household_size=3': '3 henkilöä',
		'household_size=4': '4 henkilöä',
		'household_size=5': '5 henkilöä',
		'household_size=6': '6+ henkilöä',
		
		'income_class=1': 'alle 10k€/v',
		'income_class=2': '10 - 20k€/v',
		'income_class=3': '20 - 30k€/v',
		'income_class=4': '30 - 40k€/v',
		'income_class=5': '40 - 50k€/v',
		'income_class=6': '50 - 60k€/v',
		'income_class=7': '60 - 70k€/v',
		'income_class=8': '70 - 80k€/v',
		'income_class=9': '80 - 90k€/v',
		'income_class=10': 'yli 90k€/v',
		'income_class=11': 'En halua vastata',
		
		'apartment_type=1': 'omakotitalo',
 		'apartment_type=2': 'rivi-/paritalo',
 		'apartment_type=3': 'kerrostalo',

		'major_region=246/1': 'Helsinki-Uusimaa',
		'major_region=246/2': 'Etelä-Suomi',
		'major_region=246/3': 'Länsi-Suomi',
		'major_region=246/4': 'Pohjois- ja Itä-Suomi',
		'major_region=246/5': 'Ahvenanmaa',

		'pos_topic=1': 'Vanhukset ja ikääntyvät +',
		'pos_topic=2': 'EU:hun liittyvät +',
		'pos_topic=3': 'Tasa-arvo +',
		'pos_topic=4': 'Piittaamattomuus ja itsekkyys +',
		'pos_topic=5': 'Järjestäytynyt rikollisuus ja muu rikollisuus +',
		'pos_topic=6': 'Maahanmuuttoon liittyvä +',
		'pos_topic=7': 'Yhteiskunnallinen vakaus +',
		'pos_topic=8': 'Arvopohja +',
		'pos_topic=9': 'Alkoholi ja alkoholinkäyttäjät +',
		'pos_topic=10': 'Järjestyshäiriöt +',
		'pos_topic=11': 'Huumeisiin liittyvät +',
		'pos_topic=12': 'Ympäristöön liittyvä +',
		'pos_topic=13': 'Koulu ja lasten turvallisuus +',
		'pos_topic=14': 'Talous ja työllisyys +',
		'pos_topic=15': 'Terveys +',
		'pos_topic=16': 'Syrjäytyminen ja yksinäisyys +',
		'pos_topic=17': 'Kansainvälinen vakaus +',
		'pos_topic=18': 'Psyykkinen terveys +',
		'pos_topic=19': 'Media ja sananvapaus +',
		'pos_topic=20': 'Julkiset palvelut +',
		'pos_topic=21': 'Viranomaisten toimet +',
		'pos_topic=22': 'Poliitikkojen toimet +',
		'pos_topic=23': 'Luonnonkatastrofit +',
		'pos_topic=24': 'Omaisuusrikokset +',
		'pos_topic=25': 'Väkivalta kodin ulkopuolella +',
		'pos_topic=26': 'Lähisuhdeväkivalta +',
		'pos_topic=27': 'Seksuaalirikokset +',
		'pos_topic=28': 'Liikenne +',
		'pos_topic=29': 'Tulipalot +',
		'pos_topic=30': 'Koti- ja vapaa-ajan tapaturmat +',
		'pos_topic=31': 'Terrorismi +',
		'pos_topic=32': 'Vihapuhe, viharikokset +',
		'pos_topic=33': 'Ääriliikkeiden hillintä +',
		'pos_topic=34': 'Tietoverkkoihin liittyvät +',
		'pos_topic=35': 'Epidemiat ja pandemiat +',
		'pos_topic=36': 'Arki ja harrastukset +',
		'pos_topic=37': 'Asuinpaikka +',
		'pos_topic=38': 'Koulutus +',
		'pos_topic=39': 'Läheiset ja koti +',
		'pos_topic=40': 'Oma varautuminen +',
		'pos_topic=41': 'Tieto +',
		'pos_topic=42': 'Valvonta ja vartiointi +',

		'neg_topic=1': 'Vanhukset ja ikääntyvät -',
		'neg_topic=2': 'EU:hun liittyvät -',
		'neg_topic=3': 'Tasa-arvo -',
		'neg_topic=4': 'Piittaamattomuus ja itsekkyys -',
		'neg_topic=5': 'Järjestäytynyt rikollisuus ja muu rikollisuus -',
		'neg_topic=6': 'Maahanmuuttoon liittyvä -',
		'neg_topic=7': 'Yhteiskunnallinen vakaus -',
		'neg_topic=8': 'Arvopohja -',
		'neg_topic=9': 'Alkoholi ja alkoholinkäyttäjät -',
		'neg_topic=10': 'Järjestyshäiriöt -',
		'neg_topic=11': 'Huumeisiin liittyvät -',
		'neg_topic=12': 'Ympäristöön liittyvä -',
		'neg_topic=13': 'Koulu ja lasten turvallisuus -',
		'neg_topic=14': 'Talous ja työllisyys -',
		'neg_topic=15': 'Terveys -',
		'neg_topic=16': 'Syrjäytyminen ja yksinäisyys -',
		'neg_topic=17': 'Kansainvälinen vakaus -',
		'neg_topic=18': 'Psyykkinen terveys -',
		'neg_topic=19': 'Media ja sananvapaus -',
		'neg_topic=20': 'Julkiset palvelut -',
		'neg_topic=21': 'Viranomaisten toimet -',
		'neg_topic=22': 'Poliitikkojen toimet -',
		'neg_topic=23': 'Luonnonkatastrofit -',
		'neg_topic=24': 'Omaisuusrikokset -',
		'neg_topic=25': 'Väkivalta kodin ulkopuolella -',
		'neg_topic=26': 'Lähisuhdeväkivalta -',
		'neg_topic=27': 'Seksuaalirikokset -',
		'neg_topic=28': 'Liikenne -',
		'neg_topic=29': 'Tulipalot -',
		'neg_topic=30': 'Koti- ja vapaa-ajan tapaturmat -',
		'neg_topic=31': 'Terrorismi -',
		'neg_topic=32': 'Vihapuhe, viharikokset -',
		'neg_topic=33': 'Ääriliikkeiden hillintä -',
		'neg_topic=34': 'Tietoverkkoihin liittyvät -',
		'neg_topic=35': 'Epidemiat ja pandemiat -',
		'neg_topic=36': 'Arki ja harrastukset -',
		'neg_topic=37': 'Asuinpaikka -',
		'neg_topic=38': 'Koulutus -',
		'neg_topic=39': 'Läheiset ja koti -',
		'neg_topic=40': 'Oma varautuminen -',
		'neg_topic=41': 'Tieto -',
		'neg_topic=42': 'Valvonta ja vartiointi -',

		'month=24248': '2020-09',
		'month=24249': '2020-10',
	    },
	};
    }

    handleChange = (event, newValue) => {
	this.props.history.push(newValue);
    }

    componentDidMount() {
	this.getProjectData();
	this.getDatasetData();
    }
    
    componentDidUpdate(prevProps) {
	if (prevProps.match.params.id !== this.props.match.params.id) {
	    this.setState({ project: null });
	    this.getProjectData();
	    this.getDatasetData();
	}
    }

    selectFixedTime(n, from, to) {
    	if (n != null) {
	    var d1 = moment().valueOf() / 1000;
	    var d0 = moment().subtract(n, "days").valueOf() / 1000;
	    
	    this.setState({ numDays: n });
	    this.updateFilter('t', [ d0, d1 ], true);
	} else {
	    this.setState( { numDays: null } );

	    if (from != null || to != null) {
		const { filter } = this.state;
		let t = [ filter.t[0], filter.t[1] ];
		
		if (from != null) t[0] = from.getTime() / 1000;		
		if (to != null) t[1] = to.getTime() / 1000;

		this.updateFilter('t', t);
	    }
	}
    }
    
    getProjectData() {
	const { id } = this.props.match.params;
	
	fetch("https://api.sometrik.com/project/show?" + new URLSearchParams({ id: id, records: 1 }), { credentials: 'include' } )
	    .then(res => res.json())
	    .then(		
		(result) => {
		    let project = result.data;
		    if (project) {
			if (project.positive_regex) project.positive_regex = new RegExp(project.positive_regex, 'gui');
			if (project.negative_regex) project.negative_regex = new RegExp(project.negative_regex, 'ui');
			if (project.static_start_date && project.static_end_date) {
			    this.updateFilter('t', [ project.static_start_date, project.static_end_date ])
			} else {
			    this.selectFixedTime(7);
			}
		    }
		    this.setState({ project: project });

		    if (!project.is_ready) {
			this.intervalId = setTimeout(() => {
			    this.getProjectData();
			}, 5000);
		    }
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components.
		(error) => {
		}
	    );
    }

    getDatasetData() {
	fetch("https://api.sometrik.com/dataset/list", { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    let filterNames = this.state.filterNames;
		    for (let [key, value] of Object.entries(result.regions)) {
			filterNames["region=" + key] = value.name;
		    }
		    for (let [key, value] of Object.entries(result.provinces)) {
			filterNames["province=" + key] = value.name;
		    }
		    for (let [id, value] of Object.entries(result.datasets)) {
			filterNames["dataset=" + id] = value.name;
		    }
		    for (let [id, value] of Object.entries(result.countries)) {
			filterNames["country=" + id] = value.name;
		    }
		    for (let [id, value] of Object.entries(result.topics)) {
			filterNames["topic=" + id] = value;
		    }
		    this.setState({ staticData: { ...result, isLoaded: true }, filterNames: filterNames });
		    // console.log(JSON.stringify(result.data));
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components.
		(error) => {
		}
	    );
    }

    updateFilter(key, value, reset_time) {
	let changed = true;
	let f = { ...this.state.filter };
	if (Array.isArray(value)) {
	    if (value.length === 0) {
		delete f[key];
	    } else {
		f[key] = value;
	    }
	} else if (f[key]) {
	    let v = f[key];
	    for (let i = 0; i < v.length; i++) {
		if (v[i] === value) {
		    changed = false;
		    break;
		}
	    }
	    if (changed) {
		f[key].push(value);
	    }
	} else {		
	    f[key] = [ value ];
	}
	if (changed) {
	    f.v++;
	    if (reset_time) f.tv++;
	    this.setState( { filter: f, backdropWaiting: true } );
	    if (this.state.numDays && !f.t) {
		this.setState({ numDays: null });
	    }
	    
	    this.intervalId = setTimeout(() => {
		if (this.state.backdropWaiting) {
		    this.setState( { backdropWaiting: false, backdropOpen: true });
		}
	    }, 1000);
	}	
    }
    
    render() {
	const { project, filter, staticData, numDays } = this.state;
	const { classes } = this.props;

	const onRemoveFilter = (removed_key, removed_value) => {
	    let new_filter = { };
	    for (let [key, value] of Object.entries(this.state.filter)) {
		if (key !== removed_key) {
		    new_filter[key] = value;
		} else if (key !== 't') {
		    const new_values = value.filter( v => v !== removed_value );
		    if (new_values.length) new_filter[key] = new_values;
		}
	    }
	    new_filter.v++;
	    if (removed_key === "t") {
		new_filter.tv++;
		if (this.state.numDays) this.setState({ numDays: null });
	    }
	    this.setState( { filter: new_filter } );
	};
	const onUpdateFilter = (key, value) => {
	    this.updateFilter(key, value);
	};
	const onHideBackdrop = () => {
	    this.setState( { backdropWaiting: false, backdropOpen: false });
	}

	const onSelectFixedTime = (n, from, to) => {
	    this.selectFixedTime(n, from, to);
	}

	const project_id = this.props.match.params.id != null ? this.props.match.params.id - 0 : null;
	
	const base_url = '/project/' + project_id;

	let activePath = this.props.history.location.pathname;
	if (activePath === base_url + "/tags" || activePath === base_url + "/platforms" ||
	    activePath === base_url + "/countries" || activePath === base_url + "/provinces" ||
	    activePath === base_url + "/topics" || activePath === base_url + "/significant" ||
	    activePath === base_url + "/messages"
	   ) {
	    activePath = base_url;
	}

	const updateTopic = (projectId, id, name, del) => {
	    let params = {
		'project_id': projectId,
	     	'id': id,
		'delete': del ? 1 : 0,
	    };

	    if (name != null) params.name = name;
	    
	    const requestOptions = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
                body: new URLSearchParams(params)
            };
            fetch("https://api.sometrik.com/user/edit_topic", requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.data.status == "1") {
			    if (del) {
				let f = { ...this.state.filter };
				f.v++;
				f.tv++;
				this.setState( { filter: f, backdropWaiting: true } );
			    } else {
				let filterNames = this.state.filterNames;
				let staticData = this.state.staticData;
				
				filterNames["topic=" + id] = name;
				staticData.topics[id] = name;
				
				this.setState({ staticData: staticData, filterNames: filterNames });				
			    }
			    this.props.app.addToast.bind(this.props.app)("Ok");
                        } else {
                            this.props.app.addToast.bind(this.props.app)('Failed to update', 'error');
                        }
                    },
                    (error) => {
                        this.props.app.addToast.bind(this.props.app)('Failed to contact server', 'error');
                    }
                )
	}

	const hasTabs = project_id === 31 || (project && !project.is_static);
	      
	return (<>
		    <InsightToolbar app={this.props.app} title={(project && project.name) || this.props.app.getProjectName(project_id)} subtitle="" filter={filter} filterNames={this.state.filterNames} onRemoveFilter={onRemoveFilter} />
		    
		    { !(project && project.is_ready) ?
		      <Box className={classes.statusContainer} display="flex" alignItems="center">                                               
                          <div className={classes.statusInnerContainer}>                                                                         
                	      <CircularProgress />
			      <div>Loading data...</div>
                          </div>                                                                                                                 
                      </Box>			  		     
		      :
		      <>
			  <Backdrop className={classes.backdrop} open={this.state.backdropOpen}>
			      <CircularProgress className={classes.circularprogress} />
			  </Backdrop>

			  <Container maxWidth="lg" className={classes.container}>
			      { hasTabs ?
				<Tabs
				    value={activePath}
				    onChange={this.handleChange}
				    variant="fullWidth"
				    indicatorColor="secondary"
				    textColor="secondary"
				>
				    <Tab value={base_url} icon={<AssessmentIcon />} label="Stats (Social)" />
				    { project_id === 31 ? <Tab value={base_url + '/telebus'} icon={<PhoneIcon />} label="Stats (Telebus)" /> : null }
				    { project_id !== 31 ? <Tab value={base_url + '/content'} icon={<ChatBubbleIcon />} label="Content" /> : null }
				</Tabs>
				: null }
			      
			      <Container className={classes.tabContainer}>
				  <Switch>
				      <Route path={base_url + '/content'}>
					  <TabPanel>
					      <ContentView app={this.props.app} projectId={project_id} project={project} onHideBackdrop={onHideBackdrop} />
					  </TabPanel>
				      </Route>
				      <Route path={base_url + '/telebus'}>
					  <TabPanel>
					      <TelebusView app={this.props.app} projectId={project_id} staticData={staticData} filter={filter} onRemoveFilter={onRemoveFilter} onUpdateFilter={onUpdateFilter} onHideBackdrop={onHideBackdrop} />
					  </TabPanel>
				      </Route>
				      <Route path={base_url}>
					  <TabPanel>
					      { project ? <CubeView app={this.props.app} projectId={project_id} project={project} staticData={staticData} filterNames={this.state.filterNames} numDays={numDays} filter={filter} onRemoveFilter={onRemoveFilter} onUpdateFilter={onUpdateFilter} onHideBackdrop={onHideBackdrop} onSelectFixedTime={onSelectFixedTime} onUpdateTopic={updateTopic} /> : null }
					  </TabPanel>
				      </Route>
				  </Switch>
			      </Container>
			  </Container>
		      </>
		    }
		</>		    
	       );
    }	
}

export default withRouter(withStyles(styles)(ProjectView));
