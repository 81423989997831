import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {Sigma, SigmaEnableWebGL, RandomizeNodePositions, RelativeSize, NOverlap} from 'react-sigma';
import ForceLink from 'react-sigma/lib/ForceLink'
import ForceAtlas2 from 'react-sigma/lib/ForceAtlas2'
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    sigma: {
	height: "22em",
    },
    loading: {
	fontSize: 'smaller',
	margin: '1em',
    }
});

class ProjectGraph extends React.Component {
    constructor(props) {
	super(props);
    }

    render() {	
	const { classes, graph } = this.props;

	console.log("rending ProjectGraph (nodes = " + (graph ? graph.nodes.length : 'n/a') + ")\n");

	if (graph) {
	    return <Sigma graph={graph} settings={{drawEdges: true, clone: false}}>
		       <RelativeSize initialSize={15}/>
		       <RandomizeNodePositions/>
		       <ForceAtlas2 worker barnesHutOptimize barnesHutTheta={0.6} iterationsPerRender={10} linLogMode={false} />
		   </Sigma>
	} else {
	    return <Typography align="center" className={classes.loading}>Loading graph...</Typography>;
	}	
    }
}

export default withStyles(styles) (ProjectGraph);
