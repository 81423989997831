import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {Sigma, SigmaEnableWebGL, RandomizeNodePositions, RelativeSize} from 'react-sigma';
// import ForceLink from 'react-sigma/lib/ForceLink'
import ForceAtlas2 from 'react-sigma/lib/ForceAtlas2'
import InsightToolbar from "../components/InsightToolbar.js"

const styles = theme => ({
    sigma: {
	height: "80vh",
	margin: '2em'
    }
});

class ExportGraph extends React.Component {
    constructor(props) {
	super(props);
	this.state = {
            graph: null
	};
    }

    componentDidMount () {
	this.getData();
    }

    getData() {
	const { id } = this.props.match.params;
	const body = new URLSearchParams({ id: id });

	fetch("https://api.sometrik.com/export/graph?" + body, { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			graph: result.data
		    });
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components.
		(error) => {
		    
		}
	    )
    }

    render() {	
	const { classes } = this.props;
	const { graph } = this.state;
	
	// let myGraph = { nodes: props.task.nodes, edges: props.task.edges };

	if (graph) {
	    const h = window.innerHeight - 50;
	    
	    // <ForceLink easing="cubicInOut"/>
	    //  
            return (
		<>
		    <InsightToolbar app={this.props.app} title="Graph" />
		       
		    <Sigma graph={graph} settings={{drawEdges: true, clone: false}} style={{height: h+'px'}}>
			<RelativeSize initialSize={15}/>
			<RandomizeNodePositions/>
			<ForceAtlas2 worker barnesHutOptimize barnesHutTheta={0.6} iterationsPerRender={10} linLogMode={false} />
		    </Sigma>
		</>
	    )
	} else {
	    return <>
		       <InsightToolbar app={this.props.app} title="Graph" />
		       <div>Loading...</div>
		   </>
	}
    }
}

export default withStyles(styles) (ExportGraph);
