import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import Typography from '@material-ui/core/Typography';
import { geoCentroid } from "d3-geo";
import { scaleLinear } from "d3-scale";
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
    Sphere,
    Marker,
    Graticule
} from "react-simple-maps";

const neutralColor = '#eee';
const colorScale = scaleLinear()
      .domain([-1, -0.66, -0.33, 0, 0.33, 0.66, 1])
      .range(["#4575b4", "#91bfdb", "#e0f3f8", neutralColor, "#fee090", "#fc8d59", "#d73027"]);

const MapChart = (props) => {
    const [ content, setTooltipContent ] = useState();
    const [ tooltip_n, setTooltipN ] = useState(0);
    const [ zoom, setZoom ] = useState(1.0);
    const [ coordinates, setCoordinates ] = useState([25.0, 65.0]);
    const [ visibleLayers, setVisibleLayers ] = useState();
    const [ countryData, setCountryData ] = useState();
    const [ countryRawData, setCountryRawData ] = useState();
    const [ countryHashtags, setCountryHashtags ] = useState();
    const [ countryId, setCountryId ] = useState();
    const [ currentVersion, setCurrentVersion ] = useState(-1);
    const [ maxValue, setMaxValue ] = useState();
    
    const { title, layers, proj, isRelative } = props;
    
    // console.log("maxValue = " + maxValue + ", helsinki = " + countryData["Helsinki"] + ", stockholm = " + countryData["Stockholm"]);
    // const maxActivity = props.data.map(x => x.activity).reduce( (a, b) => Math.max(a, b), 0);

    const selectLayers = (zoom) => {
	let target = [ ];
	let new_maxValue = 0;
	let new_id = { };
	let new_hashtags = { };
	let new_rawdata = { };
	let new_data = { };
	if (layers) {
	    let has_solo = false;

	    for (let i = 0; i < layers.length; i++) {
		if (layers[i].solo) {
		    has_solo = true;
		    break;
		}
	    }
	    
	    layers.forEach( l => {
		if (has_solo ? l.solo : (l.minZoom == null || l.minZoom <= zoom) && (l.maxZoom == null || l.maxZoom > zoom)) {
		    console.log("using: " + l.src);
		    	    
		    target.push(l);

		    if (l.data && props.dataAvailable) {
			console.log("layer has data");
			for (const [id, country] of Object.entries(l.countries)) {
			    let activity = l.data && l.data[id];
			    if (activity == null) activity = [0, 0];
			    new_rawdata[country.name] = activity;
			    new_id[country.name] = id;
			    let value = 0;
			    if (country.population && !isRelative) value = activity[1] / country.population;
			    else value = activity[0];
			    new_data[country.name] = value;
			    if (Math.abs(value) > new_maxValue) {
				new_maxValue = Math.abs(value);
			    }
			    if (l.hashtags) new_hashtags[country.name] = l.hashtags[id];
			}
		    }

		}
	    } );
	}
	setVisibleLayers(target);
	if (props.dataAvailable) {
	    console.log("maxValue = " + new_maxValue);
	    setMaxValue(new_maxValue);
	    setCountryId(new_id);
	    setCountryHashtags(new_hashtags);
	    setCountryRawData(new_rawdata);
	    setCountryData(new_data);
	    setCurrentVersion(props.v);
	}
    }
    
    const moveEnd = (position) => {
	console.log("creating visibleLayers (zoom = " + position.zoom + ", da = " + props.dataAvailable + ")");
	setZoom( position.zoom );
	setCoordinates( position.coordinates );
	selectLayers(position.zoom);
    };

    function handleZoomIn() {
	// if (zoom >= 4) return;
	setZoom(zoom * 2);	
	selectLayers(zoom);
    }
    
    function handleZoomOut() {
	// if (zoom <= 1) return;
	setZoom(zoom / 2);
	selectLayers(zoom);
    }
    
    if (visibleLayers == null || (!countryData && props.dataAvailable) || (props.dataAvailable && currentVersion !== props.v)) {
	console.log("creating visibleLayers (zoom = " + zoom + ", v = " + props.v + ", initial, da = " + props.dataAvailable + ")");
	selectLayers(zoom);
    }

    if (visibleLayers != null) {
	let onClick = null;
	
	visibleLayers.forEach( l => {
	    if (l.data) {
		onClick = l.onClick;
	    }
	} );
	
	return (
	    <>
		<ReactTooltip>{ content != null ? <><div>{content}</div><div>N: {tooltip_n}</div></> : null }</ReactTooltip>
		<ComposableMap
		    data-tip=""
		    projection="geoAzimuthalEqualArea"
		    projectionConfig={proj}
		    style={{margin:0,padding:0,width:'100%',height:'100%',overflow:'hidden'}}
		>
		    <ZoomableGroup zoom={zoom} center={coordinates} onMoveEnd={moveEnd} maxZoom={32} minZoom={0.2}>
			<Sphere stroke="#E4E5E6" strokeWidth={0.5} />
			<Graticule stroke="#E4E5E6" strokeWidth={0.5} />
			{ visibleLayers.map( layer => (
			    <Geographies key={layer.src} geography={layer.src}>
				{({ geographies }) => (
				    <>
					{ geographies.map(geo => {
					    const name = geo.properties.VARNAME_2 || geo.properties.Name || geo.properties.NAME || geo.properties.NAME_2 || geo.properties.na || geo.properties.nimi;
					    let raw = countryRawData ? countryRawData[name] : null;
					    let id = countryId ? countryId[name] : null;
					    let value = null;
					    if (countryData) {
						const d = countryData[name];
						if (d != null && maxValue) {
						    value = (d != null ? d : 0) / maxValue;
						}
					    }
					    return (
						<Geography
						    key={geo.rsmKey}
						    geography={geo}
						    fill={ value == null ? neutralColor : colorScale(value) }
						    stroke='#fff'
						    strokeWidth={1}
						    onMouseEnter={() => {
							setTooltipContent(name + ": " + (raw != null ? (isRelative ? raw[0].toFixed(2) + ' %' : raw[0].toFixed(0)) : 'n/a'));
							setTooltipN(raw != null ? raw[1].toFixed(0) : 0);
						    }}
						    onMouseLeave={() => {
							setTooltipContent(null);
							setTooltipN(0);
						    }}
						    onClick={() => {
							if (onClick && id != null) onClick(id);
						    }}
						    style={{
							default: {
							    outline: "none",
							    'vectorEffect': 'non-scaling-stroke',
							},
							hover: {
							    outline: "none",
							    'vectorEffect': 'non-scaling-stroke',
							},
							pressed: {
							    outline: "none",
							    'vectorEffect': 'non-scaling-stroke',
							}
						    }}
						/>
					    );
					})}
					
					{geographies.map(geo => {
					    const name = geo.properties.VARNAME_2 || geo.properties.Name || geo.properties.NAME || geo.properties.NAME_2 || geo.properties.na || geo.properties.nimi;
					    if (countryHashtags) {
						const h = countryHashtags[name];
						if (h) {
						    const centroid = geoCentroid(geo);
						    return (
							<g key={geo.rsmKey + "-name"}>
							    <Marker coordinates={centroid}>
								<text fontSize={10 / zoom} textAnchor="middle">{h}</text>
							    </Marker>
							</g>
						    );
						}
					    }
					    return null;
					})}
				    </>
				)
				}
			    </Geographies>
			) ) }
		    </ZoomableGroup>
		</ComposableMap>
		<div>
		    <Typography component="h2" variant="h6" color="primary" gutterBottom>
			&nbsp;&nbsp;{title}&nbsp;

			<button onClick={handleZoomIn}>
			    <svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				stroke="currentColor"
				strokeWidth="3"
			    >
				<line x1="12" y1="5" x2="12" y2="19" />
				<line x1="5" y1="12" x2="19" y2="12" />
			    </svg>
			</button>&nbsp;
			<button onClick={handleZoomOut}>
			    <svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				stroke="currentColor"
				strokeWidth="3"
			    >
				<line x1="5" y1="12" x2="19" y2="12" />
			    </svg>
			</button>
		    </Typography>
		</div>
	    </>
	);
    } else {
	return null;
    }
};

export default MapChart;
