import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Message from "./Message";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    statisticsTable: {
        color: '#666',
    },
})

class MessageDialog extends React.Component {
    constructor(props) {
	super(props);
	
	this.state = {
	    messages: [ ]
	}
    }
    
    componentDidMount() {
	const props = this.props;
	
	const { filter, projectId } = this.props;
	const p = { ...filter, id: projectId, mode: 'messages', n: 100 };

	fetch("https://api.sometrik.com/project/cube?" + new URLSearchParams(p), { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			messages: result.messages,
		    });
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components.
		(error) => {
		}
	    )	    
    }

    render () {
	const { user, classes, project, topics, emotionLabels, datasets } = this.props;

	const onContextMenu = (anchor, article) => {
	    if (this.props.selectArticle) this.props.selectArticle(article);
	    this.setState({ anchor: anchor, selectedArticle: article });
	}

	const handleClose = () => {
            this.setState({ anchor: null });
	}

	const openLink = () => {
	    window.open(this.state.selectedArticle.link);
	    handleClose();
	}

	const reclassify = () => {
	    if (this.props.reclassify) this.props.reclassify();
	    handleClose();
	}

	const toggleTagged = () => {
	    if (this.props.toggleTagged) this.props.toggleTagged();
	    handleClose();
	}

	const renderRows = (data) => {
	    return (
		<>
		    { data.map( item =>
			<TableRow key={item.key}>
			    <TableCell><Message user={user} positive_regex={project && project.positive_regex} negative_regex={project && project.negative_regex} article={item} topics={topics} emotionLabels={emotionLabels} onContextMenu={onContextMenu} datasets={datasets} /></TableCell>
			</TableRow>			    
		    ) }		
		</>
	    );
	};
	
	return (
	    <Dialog open={true} onClose={this.props.onClose}>
		<DialogTitle>Esimerkkisisältö</DialogTitle>
		<Table size="small" className={classes.statisticsTable}>
                    <TableBody>
			{ this.state.messages.data ? renderRows(this.state.messages.data) : null }
                    </TableBody>
		</Table>

		<Menu
		    anchorEl={this.state.anchor}
		    keepMounted
		    open={this.state.anchor != null}
		    onClose={handleClose}			
		>
		    <MenuItem key="view" onClick={ () => openLink() } disabled={this.state.selectedArticle == null || this.state.selectedArticle.link == null}>View</MenuItem>
		    { user.is_admin ? <MenuItem key="reclassify" onClick={ () => reclassify() } disabled={this.state.selectedArticle == null || this.state.selectedArticle.rss_post_id != 0}>Reclassify</MenuItem> : null }
		    <MenuItem key="tag" onClick={ () => toggleTagged() } disabled={this.state.selectedArticle == null}>{ !this.state.selectedArticle || !this.state.selectedArticle.is_tagged ? 'Add Tag' : 'Remove Tag' }</MenuItem>
		</Menu>
            </Dialog>
	);
    }
}

export default withStyles(styles)(MessageDialog);
