import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

export default function CardTitle(props) {
    return (
	<Typography component="h2" variant="h6" color="primary" gutterBottom>
	    {props.children}
	</Typography>
    );
}

CardTitle.propTypes = {
    children: PropTypes.node,
};
