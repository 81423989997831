import React, { Component } from 'react';
import { CookiesProvider } from 'react-cookie';
import CssBaseline from '@material-ui/core/CssBaseline';

import InsightBaseApp from './components/InsightBaseApp.js';
import './App.css';

class App extends Component {
  render() {
    return (
      <React.Fragment>
          <CssBaseline />
	  <CookiesProvider>
	      <InsightBaseApp />
	  </CookiesProvider>
      </React.Fragment>
    );
  }
}

export default App;
