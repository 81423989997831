import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StyledTableCell, StyledTableRow } from "../components/StyledTable.js";
import Container from '@material-ui/core/Container';

const styles = theme => ({
    container: {
	paddingTop: theme.spacing(4),
	paddingBottom: theme.spacing(4)
    },
});

class ExportTopics extends React.Component {
    render() {
	const { task, classes } = this.props;
	const topics = task.topics;
	
	return (
	    <Container maxWidth="lg" className={classes.container}>
		<h1>Topics</h1>
		<Table>
		    <TableHead>
			<TableRow>
			    {topics.map(t => (
				<StyledTableCell key={t.id}>Topic #{t.id} ({ Math.round(t.f * 100) } %)</StyledTableCell>
			    ))}
			</TableRow>
		    </TableHead>
		    <TableBody>
			{Array.from(Array(20).keys()).map((row) => (
			    <StyledTableRow key={row}>
				{topics.map(t => (
				    <StyledTableCell key={t.id}>{t.words[row][0]}</StyledTableCell>
				))}
			    </StyledTableRow>
			))}
		    </TableBody>
		</Table>
	    </Container>
	);
    }
}

export default withRouter(withStyles(styles)(ExportTopics));
