import React from "react";
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Chart from "react-apexcharts";
import Button from '@material-ui/core/Button';
import AnnotationDialog from '../components/AnnotationDialog';
import CardTitle from '../components/CardTitle';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'react-moment';
import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import TopicCard from "./TopicCard.js";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
	backgroundColor: '#f5f5f9',
	color: 'rgba(0, 0, 0, 0.87)',
	fontSize: theme.typography.pxToRem(12),
	border: '1px solid #dadde9'
    }
}))(Tooltip);

function createToolTip(item) {
    return (
	<React.Fragment>
	    <Typography color="inherit">{item.name}{item.uname != null ? '\xa0@' + item.uname : null}</Typography>
            { item.flags.map((f) => (<div key={f}>{f}</div>)) }
	    { item.created_time && <div><b>Created:</b> <Moment format="YYYY-MM-DD hh:mm:ss">{ new Date(item.created_time*1000) }</Moment></div> }
            <div><b>Source:</b> { item.primary_source }</div>
	    { item.last_comment && <div><b>Last comment:</b> { item.last_comment.text }</div> }
	</React.Fragment>
    );
}

const styles = theme => ({
    statisticsTable: {
	color: '#666',
    },
    tableHeader: {
        fontWeight: 'bold',
    },
    total: {
	backgroundColor: '#f0f0f0',
    },
    container: {
	paddingTop: theme.spacing(4),
	paddingBottom: theme.spacing(4)
    },
    chart: {
	boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
    },
});

function createData(input) {
    var series = [];
    for (var i = 0; i < input.length; i++) {
        series.push({
            x: i,
            y: input[i]
        });
    }
    return series;
}

class ExportCharts extends React.Component {
    constructor(props) {
	super(props);
	
	this.state = {
	    open: false,
	    more_popular_users: false,
	    more_active_users: false
	};
    }

    showAnnotationDialog() {
	this.setState({ open: true })
    }
    
    render() {
	const { task } = this.props;
	const { classes, onCreateAnnotation } = this.props;

	const closeAnnotationDialog = () => {
	    this.setState({ open: false })
	}

	let options1 = {
	    xaxis: {
		type: 'datetime',
		tooltip: {
		    enabled: false
		}
	    },
	    yaxis: {
		labels: {
		    formatter: function (value) {
			return Math.round(value);
		    }
		},
	    },
	    chart: {
		animations: {
		    enabled: false
		},
		events: {
		    click: function(event, chartContext, config) {
			// The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
			alert('dpi: ' + config.dataPointIndex);
		    }
		}
	    },
	    stroke: {
                curve: 'smooth',
                width: 2
            },
	    tooltip: {
		x: {
		    format: 'dd MMM yyyy'
		}
	    },
	    annotations: { },
	};
	let options_emotion = {
	    ...options1,
	    colors: [
		'rgb(88,68,70)', // fear
		'rgb(146,193,233)', // hope
		'rgb(200,16,46)', // anger
		'rgb(255,205,0)' // joy
		]
	}
	let options2 = {

	};
	const options3 = {
	    chart: {
                height: 350,
                type: 'heatmap'
            },
            dataLabels: {
                enabled: false
            },
            colors: [ "#008FFB" ],
        };          

	let data1 = [ ];
	let data2 = [ ];
	let data3 = [ ];
	let data4 = [ ];
	let data5 = [ ];
	
	if (task && task.state === 2) {
	    data1.push({
		name: 'News and blogs',
		data: task.activity.news
	    });
	    data1.push({
		name: 'Comments',
		data: task.activity.comment
	    });
	    data1.push({
		name: 'Tweets',
		data: task.activity.tweet
	    });
	    data1.push({
		name: 'VK.com',
		data: task.activity.vk
	    });
	    data1.push({
		name: 'Reddit',
		data: task.activity.reddit
	    });
	    data1.push({
		name: 'Youtube',
		data: task.activity.youtube
	    });
	    data1.push({
		name: 'Vimeo',
		data: task.activity.vimeo
	    });
	    data1.push({
		name: 'Tumblr',
		data: task.activity.tumblr
	    });
	    data1.push({
		name: 'TikTok',
		data: task.activity.tiktok
	    });
	    data1.push({
		name: 'New Users',
		data: task.activity.new_users
	    });

	    data3.push({
		name: 'Fear',
		data: task.emotion.fear
	    });
	    data3.push({
		name: 'Hope',
		data: task.emotion.hope
	    });
	    data3.push({
		name: 'Anger',
		data: task.emotion.anger
	    });
	    data3.push({
		name: 'Joy',
		data: task.emotion.joy
	    });

	    data4.push({
		name: 'Negative',
		data: task.sentiment.neg
	    });
	    data4.push({
		name: 'Positive',
		data: task.sentiment.pos
	    });

	    options1.annotations.xaxis = task.annotations;
	    options2.labels = task.activity_by_platform.labels;	    
	    data2 = task.activity_by_platform.series;

	    const topics = task.topics;

	    data5 = topics.map( t => (
		{
		    name: 'Topic #' + t.id,
		    data: createData(t.heatmap)
		}
	    ) );

	    data5.reverse();
	}

	const topics = task.topics;
	
	 const optionsTopicBars = {
            xaxis: {
                categories: topics.map( t => t.id )
            },
            yaxis: {
                labels: {
                    formatter: value => value.toFixed(1)
                }
            },
            dataLabels: {
                // enabled: false,
                formatter: value => value.toFixed(1)
            }
        };
        const seriesTopicBars = [
            {
                name: "series-1",
                data: topics.map( t => t.theta_sum )
            }
        ];

	const toggleMorePopularUsers = () => {
	    this.setState( (state) => ({ more_popular_users: !state.more_popular_users }) );
	}

	const toggleMoreActiveUsers = () => {
	    this.setState( (state) => ({ more_active_users: !state.more_active_users }) );
	}

	const { more_popular_users, more_active_users } = this.state;
	
	return (
	    <Container maxWidth="lg" className={classes.container}>
		<Grid container spacing={3}>
		    <Grid item xs={12} md={6} lg={6}>
		    	<Card className={classes.chart}>
			    <CardContent>
				<CardTitle>Platforms</CardTitle>
				<Chart options={options2} series={data2} type="donut" width="600" height="300" />
			    </CardContent>
			</Card>
		    </Grid>

		    <Grid item xs={12} md={6} lg={6}>			
			<Card className={classes.chart}>
			    <CardContent>
				<CardTitle>Statistics</CardTitle>
				<Table className={classes.statisticsTable} size="small">
				    <TableHead>
					<TableRow>
					    <TableCell></TableCell>
					    <TableCell align="right">Posts</TableCell>
					    <TableCell align="right">Comments</TableCell>
					</TableRow>
				    </TableHead>
				    <TableBody>
					<TableRow key="tweetsrow">
					    <TableCell component="th" scope="row" className={classes.tableHeader}>Tweets:</TableCell>
					    <TableCell align="right">{task.num_tweets}</TableCell>
					    <TableCell align="right">0</TableCell>
					</TableRow>				    
					<TableRow key="newsrow">
					    <TableCell component="th" scope="row" className={classes.tableHeader}>News & Blogs:</TableCell>
					    <TableCell align="right">{task.num_news}</TableCell>
					    <TableCell align="right">{task.num_comments}</TableCell>
					</TableRow>
					<TableRow key="vkcomrow">
					    <TableCell component="th" scope="row" className={classes.tableHeader}>Vk.com:</TableCell>
					    <TableCell align="right">{task.num_vkcom}</TableCell>
					    <TableCell align="right">{task.num_vkcom_comments}</TableCell>
					</TableRow>
					<TableRow key="redditrow">
					    <TableCell component="th" scope="row" className={classes.tableHeader}>Reddit:</TableCell>
					    <TableCell align="right">{task.num_reddit}</TableCell>
					    <TableCell align="right">{task.num_reddit_comments}</TableCell>
					</TableRow>
					<TableRow key="youtuberow">
					    <TableCell component="th" scope="row" className={classes.tableHeader}>Youtube:</TableCell>
					    <TableCell align="right">{task.num_youtube}</TableCell>
					    <TableCell align="right">{task.num_youtube_comments}</TableCell>
					</TableRow>
					<TableRow key="vimeorow">
					    <TableCell component="th" scope="row" className={classes.tableHeader}>Vimeo:</TableCell>
					    <TableCell align="right">{task.num_vimeo}</TableCell>
					    <TableCell align="right">{task.num_vimeo_comments}</TableCell>
					</TableRow>
					<TableRow key="tumblrrow">
					    <TableCell component="th" scope="row" className={classes.tableHeader}>Tumblr:</TableCell>
					    <TableCell align="right">{task.num_tumblr}</TableCell>
					    <TableCell align="right">0</TableCell>
					</TableRow>
					<TableRow key="slidesharerow">
					    <TableCell component="th" scope="row" className={classes.tableHeader}>SlideShare:</TableCell>
					    <TableCell align="right">{task.num_slideshare}</TableCell>
					    <TableCell align="right">0</TableCell>
					</TableRow>
					<TableRow key="tiktokrow">
					    <TableCell component="th" scope="row" className={classes.tableHeader}>TikTok:</TableCell>
					    <TableCell align="right">{task.num_tiktok}</TableCell>
					    <TableCell align="right">{task.num_tiktok_comments}</TableCell>
					</TableRow>
					<TableRow key="totalsrow" className={classes.total}>
					    <TableCell component="th" scope="row" className={classes.tableHeader}>Total:</TableCell>
					    <TableCell align="right" className={classes.tableHeader}>{ task.num_tweets + task.num_news + task.num_vkcom + task.num_reddit + task.num_youtube + task.num_vimeo + task.num_tumblr + task.num_tiktok + task.num_slideshare }</TableCell>
					    <TableCell align="right" className={classes.tableHeader}>{ task.num_comments + task.num_vkcom_comments + task.num_reddit_comments + task.num_youtube_comments + task.num_vimeo_comments + task.num_tiktok_comments }</TableCell>			
					</TableRow>
				    </TableBody>
				</Table>
			    </CardContent>
			</Card>
		    </Grid>

		    <Grid item xs={12}>
			<Card className={classes.chart}>
			    <CardContent>
				<CardTitle>Activity</CardTitle>

				<Chart options={options1} series={data1} type="line" height="300" />

				<Box display="flex" p={1}>
				    <Button variant="contained" color="primary" onClick={this.showAnnotationDialog.bind(this)}>Add annotation</Button>
				</Box>
			    </CardContent>
			</Card>
		    </Grid>

		    <Grid item xs={12}>
			<Card className={classes.chart}>
			    <CardContent>
				<CardTitle>Emotions</CardTitle>
				<Chart options={options_emotion} series={data3} type="line" height="300" />
			    </CardContent>
			</Card>
		    </Grid>

		    <Grid item xs={12}>
			<Card className={classes.chart}>
			    <CardContent>
				<CardTitle>Sentiment</CardTitle>
				<Chart options={options1} series={data4} type="line" height="300" />
			    </CardContent>
			</Card>
		    </Grid>

		    <Grid item xs={12}>
			<Card className={classes.chart}>
		    	    <CardContent>
				<CardTitle>Popular users</CardTitle>
				<Table size="small">
				    <TableHead>
					<TableRow>
					    <TableCell>Name</TableCell>
					    <TableCell align="right">Popularity</TableCell>
					    <TableCell align="right">Activity</TableCell>
					    <TableCell align="right">Source</TableCell>
					</TableRow>
				    </TableHead>
				    <TableBody>
					{task.popular_users.slice(0, more_popular_users ? task.popular_users.length : 10).map((item, i) => (
					    <TableRow key={item.name}>
						<TableCell component="th" scope="item" style={{ cursor: 'default' }}>
						    <HtmlTooltip title={createToolTip(item)}><span>{item.name}</span></HtmlTooltip>
						</TableCell>
						<TableCell align="right">{item.received_activity}</TableCell>
						<TableCell align="right">{item.activity}</TableCell>
						<TableCell align="right">{item.primary_source}</TableCell>
					    </TableRow>
					))}
				    </TableBody>
				</Table>
			    </CardContent>
			    <CardActions>
				<Button size="small" onClick={toggleMorePopularUsers}>{ this.state.more_popular_users ? 'Show Less' : 'Show More' }</Button>
			    </CardActions>
			</Card>
		    </Grid>

		    <Grid item xs={12}>
			<Card className={classes.chart}>
			    <CardContent>
				<CardTitle>Active users</CardTitle>
				<Table size="small">
				    <TableHead>
					<TableRow>
					    <TableCell>Name</TableCell>
					    <TableCell align="right">Popularity</TableCell>
					    <TableCell align="right">Activity</TableCell>
					    <TableCell align="right">Source</TableCell>
					</TableRow>
				    </TableHead>
				    <TableBody>
					{task.active_users.slice(0, more_active_users ? task.active_users.length : 10).map((item, i) => (
					    <TableRow key={item.name}>
						<TableCell component="th" scope="item" style={{ cursor: 'default' }}>
						    <HtmlTooltip title={createToolTip(item)}><span>{item.name}</span></HtmlTooltip>
						</TableCell>
						<TableCell align="right">{item.received_activity}</TableCell>
						<TableCell align="right">{item.activity}</TableCell>
						<TableCell align="right">{item.primary_source}</TableCell>
					    </TableRow>
					))}
				    </TableBody>
				</Table>
			    </CardContent>
			    <CardActions>
				<Button size="small" onClick={toggleMoreActiveUsers}>{ this.state.more_active_users ? 'Show Less' : 'Show More' }</Button>
			    </CardActions>
			</Card>
		    </Grid>
		
		    <Grid container item direction="row" justify="space-between" alignItems="stretch" spacing={3} xs={12}>
			{topics && topics.map(t => (
                            <Grid item xs={3} key={t.id}>
				<TopicCard topic={t} />
                            </Grid>
			))}
                    </Grid>

		    <Grid item xs={12} md={6} lg={6}>
                        <Card className={classes.chart}>
			    <CardContent>
                                <CardTitle>Topic Distribution</CardTitle>
                                <Chart
				    options={optionsTopicBars}
				    series={seriesTopicBars}
				    type="bar"
				    width="500"
                                />
			    </CardContent>
                        </Card>
		    </Grid>

		    <Grid item xs={12}>
                        <Card className={classes.chart}>
                            <CardContent>
                                <CardTitle>Daily Topic Distribution</CardTitle>
                                <Chart options={options3} series={data5} type="heatmap" height={350} />
                            </CardContent>
                        </Card>
                    </Grid>

		</Grid>
		
		<AnnotationDialog open={this.state.open} onCreate={onCreateAnnotation} onClose={closeAnnotationDialog} />

	    </Container>
	);
    }
}

export default withRouter(withStyles(styles)(ExportCharts));
