import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";

const styles = theme => ({
    quote: {
	fontStyle: 'italic',
	color: '#777',
    },
    ins: {
	color: '#966',
    },
    res: {
	background: '#fe1',
    }
});

function createMatches(regex, text, matches_only) {
    const matches = text.matchAll(regex);

    const divider = ' […] ';
    
    let parts = [ ];
    let currentPos = 0;
    for (const match of matches) {
	if (currentPos < match.index) {
	    let before = text.substr(currentPos, match.index - currentPos);
	    if (matches_only) {
		let i = before.search(/\S+\s+\S*$/);
		if (i != null) {
		    if (parts.length === 0 || parts[parts.length - 1][1] !== divider) parts.push( [ 'ins', divider ] );
		    parts.push( [ null, before.substr(i) ]);
		}
	    } else {
		parts.push( [ null, before ] );
	    }		
	}
	parts.push( [ 'res', match[0] ] );
	currentPos = match.index + match[0].length;
	if (matches_only) {
	    let after = text.substr(currentPos);
	    let r = after.match(/^(\S*\s+\S+)/);
	    if (r) {
		parts.push([ null, r[1] ]);
		parts.push([ 'ins', divider ]);
	    }
	}
    }
    if (!matches_only && currentPos < text.length) {
	parts.push( [ null, text.substr(currentPos) ] );
    }

    return parts;
}

class Quote extends React.Component {
    render() {
	const { quote, prefix, classes, href, text, matchesOnly, positive_regex, negative_regex } = this.props;

	if (text != null && (positive_regex || !matchesOnly)) {
	    let parts = [ ];
	    if (positive_regex) {
		parts = createMatches(positive_regex, text, matchesOnly);
	    } else {
		parts = [ [ null, text ] ];
	    }

	    if (parts.length) {
		let tags = parts.map( (p, i) => <span key={i} className={classes[p[0]]}>{p[1]}</span> );
		
		const className = quote ? classes.quote : classes.root;
		
		const prefix_span = <span className={className} key={0}>{prefix}</span>;
		if (href == null) {
		    return <span>{ prefix_span } <span className={className} key={1}>{tags}</span></span>;
		} else {
		    return <span>{ prefix_span } <Link href={href} className={className} key={1} target="_blank">{tags}</Link></span>
		}
	    }
	}
	
	return null;
    }
}

export default withStyles(styles) (Quote);
