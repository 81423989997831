import React from "react";

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class CreateProjectDialog extends React.Component {
    constructor(props) {
	super(props);
	
	this.state = {
	    projectName: '',
	    lang: 'fi',
	    nameError: true,
	    nameExistsError: false,
	    hasWritten: false
	};
    }

    onClose = () => {
	this.setState({ projectName: '', lang: 'fi', nameError: true, nameExistsError: false, hasWritten: false });
	if (this.props.onClose != null ) {
	    this.props.onClose();
	}
    }

    render() {
	const onCreateProject = () => {
	    if (this.props.onCreateProject != null) {
		this.props.onCreateProject(this.state.projectName, this.state.lang);
	    }
	}

	const checkIfProjectExists = (name) => {
	    fetch("https://api.sometrik.com/project/show?" + (new URLSearchParams({ name: name })), { credentials: 'include' } )
		.then(res => res.json())
		.then(
		    (result) => {
			this.setState({ nameExistsError: result.data != null });
		    },
		    (error) => {
		    }
		);
	}

	const handleInputChange = (event) => {
            const target = event.target;

	    if (target.name === "projectName") {
		if (target.value !== this.state.projectName) {
		    this.setState({ nameExistsError: false });
		    if (target.value.length > 0) checkIfProjectExists(target.value);
		}

		this.setState({ nameError: target.value.length === 0 });
		if (target.value.length > 0) this.setState({ hasWritten: true });
	    }
	    
            this.setState({
                [target.name]: target.value
            });
        }
	
	return (
	    <Dialog open={this.props.open} onClose={this.onClose} aria-labelledby="new-project-dialog-title">
		<DialogTitle id="new-project-dialog-title">New project</DialogTitle>
		<DialogContent>
		    <TextField
			name="projectName"
			onChange={handleInputChange}
			ref="name"
			autoFocus
			margin="dense"
			label="Project name"
			fullWidth
			error={ (this.state.nameError && this.state.hasWritten) || this.state.nameExistsError }
			helperText={ this.state.nameError && this.state.hasWritten ? 'Specify a project name' : (this.state.nameExistsError ? 'Project already exists' : null) }
			value={this.state.name}
		    />
		    <Select
			value={this.state.lang}
			name="lang"
			onChange={handleInputChange}
		    >
			<MenuItem value='fi'>Finnish</MenuItem>
			<MenuItem value='en'>English</MenuItem>
			<MenuItem value='se'>Swedish</MenuItem>
		    </Select>
		</DialogContent>
		<DialogActions>
		    <Button onClick={this.onClose} color="primary">
			Cancel
		    </Button>
		    <Button onClick={onCreateProject} color="primary" disabled={ this.state.nameError || this.state.nameExistsError }>
			Create
		    </Button>
		</DialogActions>
	    </Dialog>
	);
    }
}

export default CreateProjectDialog;
