import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Typography from '@material-ui/core/Typography';
import CreateProjectDialog from './CreateProjectDialog';
import { StyledTableCell, StyledTableRow } from "../components/StyledTable.js";

const styles = theme => ({
    button: {
        marginTop: '10px',
    },
});

class ProjectList extends React.Component {
    constructor(props) {
	super(props);
	
	this.state = {
	    items: [],
	    dialogOpen: false
	};
    }

    componentDidMount() {
	this.getData();
    }

    selectPage(id) {
	this.props.history.push('/editproject/'+id);
    }

    getData() {
        fetch("https://api.sometrik.com/project/list", { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			isLoaded: true,
			items: result.data
		    });
		},
		(error) => {
		    this.setState({
			isLoaded: true,
			error
		    });
		}
		
	    )
    }

    render() {
	const { items } = this.state;
	const { classes } = this.props;
	
	const showDialog = () => {
	    this.setState({ dialogOpen: true });
	}
	const hideDialog = () => {
	    this.setState({ dialogOpen: false });
	}
	const createProject = (name, lang) => {
	    const requestOptions = {
		method: 'POST',
		headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
		credentials: 'include',
		body: new URLSearchParams({
		    'name': name,
		    'lang': lang
		})
	    };

	    fetch("https://api.sometrik.com/project/create", requestOptions)
		.then(response => response.json())
		.then(
		    (data) => {
			this.props.app.addToast.bind(this.props.app)("Project created successfully");	
			this.getData();
		    },
		    (error) => {
			this.props.app.addToast.bind(this.props.app)("Failed to create project", "error");				
		    }
		);	    
	    hideDialog();
	}
	
	return (
	    <div>
		<TableContainer component={Paper}>
		    <Table aria-label="customized table">
			<TableHead>
			    <TableRow>
				<StyledTableCell>Project Name</StyledTableCell>
				<StyledTableCell align="right">Creation Time</StyledTableCell>
				<StyledTableCell align="right">Last Updated</StyledTableCell>
			    </TableRow>
			</TableHead>
			<TableBody>
			    {Object.values(items).map((item) => (
				item.is_static ? null : 
				    <StyledTableRow key={item.name}>
					<StyledTableCell component="th" scope="item">
					    <Typography><Link component={RouterLink} to={ '/editproject/' + item.id }>{item.name}</Link></Typography>
					</StyledTableCell>
					<StyledTableCell align="right">{item.created_time}</StyledTableCell>
					<StyledTableCell align="right">{item.updated_time}</StyledTableCell>
				    </StyledTableRow>
			    ))}
			</TableBody>
		    </Table>
		</TableContainer>
		
		<Button variant="contained" color="primary" onClick={showDialog} className={classes.button}>
			Add new project
		    </Button>

		<CreateProjectDialog open={this.state.dialogOpen} onClose={hideDialog} onCreateProject={createProject} />
	    </div>
	);
    }
}

export default withRouter(withStyles(styles)(ProjectList));
