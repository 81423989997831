import React from "react";
import { withStyles } from '@material-ui/core/styles';
import InsightToolbar from "../components/InsightToolbar.js"

const styles = theme => ({
    
});

class ClassifyView extends React.Component {
    constructor(props) {
	super(props);
	this.state = {
	    
	};	
    }

    componentDidMount() {
	this.getData();
    }

    getData = () => {

    }

    render() {
	return (<React.Fragment>
		    <InsightToolbar app={this.props.app} title="Classify"/>
		    
		</React.Fragment>
	       );
    }
}

export default withStyles(styles)(ClassifyView);
