import React from "react";
import { withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from "@material-ui/core/Link";
import Typography from '@material-ui/core/Typography';
import {StyledTableCell, StyledTableRow} from "../components/StyledTable.js";

class TeamList extends React.Component {
    constructor(props) {
	super(props);
	this.state = {
	    items: [],
	    dialogOpen: false,
	    selectedTeam: null
	};
    }

    componentDidMount() {
	this.getData();
    }

    getData() {
        fetch("https://api.sometrik.com/team/list", { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			isLoaded: true,
			items: result.data
		    });
		},
		(error) => {
		    this.setState({
			isLoaded: true,
			error
		    });
		}	
	    )
    }

    render() {
	const { items } = this.state;
	
	return (
	    <div>
		<TableContainer component={Paper}>
		    <Table aria-label="customized table">
			<TableHead>
			    <TableRow>
				<StyledTableCell>Team name</StyledTableCell>
				<StyledTableCell align="right">Creation Time</StyledTableCell>
			    </TableRow>
			</TableHead>
			<TableBody>
			    {items.map((item) => (
				<StyledTableRow key={item.id}>
				    <StyledTableCell scope="item">
					<Typography><Link onClick={() => { } }>{item.name}</Link></Typography>
				    </StyledTableCell>
				    <StyledTableCell align="right">{item.created_time}</StyledTableCell>				    
				</StyledTableRow>
			    ))}
			</TableBody>
		    </Table>
		</TableContainer>
	    </div>
	);
    }
}
export default withRouter(TeamList);
