import React from "react";
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Message from "./Message";

const styles = theme => ({
    messagebox: {
	marginBottom: '1em',
    },
});

class EditTopicDialog extends React.Component {
    constructor(props) {
	super(props);
	this.state = this.createState(props.topic);
    }     

    createState(topic) {	
	let topic_name = null;
	if (topic) {
	    const { topics } = this.props;
	    const topic_key = topic.id;
	    if (topics) topic_name = topics[topic_key];
	    if (topic_name == null) topic_name = '#' + topic.id;
	}
	
	return {
	    id: topic && topic.id,
	    name: topic_name,
	}
    }

    componentDidUpdate(prevProps) {
	if (this.props.topic && (!prevProps.topic || this.props.topic.id != prevProps.topic.id)) {
	    this.setState(this.createState(this.props.topic));
	}
    }
    
    componentDidMount() {
	this.setState(this.createState(this.props.topic));
    }
    
    render() {
	const { topic } = this.props;

	const handleSubmit = () => {
	    this.props.onUpdateTopic(0, topic.id, this.state.name);
	    onClose();
	}

	const onClose = () => {
	    this.setState(this.createState());
	    this.props.onClose();
	}
	
	const handleInputChange = (event) => {
	    const target = event.target;

	    this.setState({
		[target.name]: target.value
	    });
	};
	
	return (	    
	    <Dialog open={this.props.open} onClose={onClose} aria-labelledby="reclassify-dialog-title">
		<DialogTitle id="reclassify-dialog-title">Edit Topic</DialogTitle>
		
		<DialogContent>
		    <TextField
			value={this.state.name}
			name="name"
			autoFocus
			onChange={handleInputChange}
			margin="dense"
			label="Name"
			fullWidth
		    />
		</DialogContent>
		
		<DialogActions>
		    <Button onClick={onClose} color="primary">
			Cancel
		    </Button>
		    <Button onClick={handleSubmit} color="primary">
			Submit
		    </Button>
		</DialogActions>
	    </Dialog>
	);
    }
}

export default withStyles(styles) (EditTopicDialog);
