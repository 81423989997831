import React from "react";
import { Route, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Chart from "react-apexcharts";
import Button from '@material-ui/core/Button';
import CardTitle from '../components/CardTitle';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Link from "@material-ui/core/Link";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import MapChart from "./MapChart";

import ShowMoreDialog from "./ShowMoreDialog.js";
import IndexValueCard from "./IndexValueCard.js";

const styles = theme => ({
    statisticsTable: {
	color: '#666',
	margin: 0,
    },
    tableHeader: {
        fontWeight: 'bold',
    },
    total: {
	backgroundColor: '#f0f0f0',
    },
    container: {
	paddingTop: theme.spacing(4),
	paddingBottom: theme.spacing(4)
    },
    chart: {
	boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
    },
    standardChart: {
	boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
	minHeight: '22em',
    },
    limitedCell: {
	'display': '-webkit-box',
	'-webkit-line-clamp': 3,
	'-webkit-box-orient': 'vertical',
	'overflow': 'hidden',
    }
});

class TelebusView extends React.Component {
    constructor(props) {
	super(props);
	
	this.state = {
	    data: null,
	    filteredData: null,
	};
	this.aspects = [
	    'Omaisuusrikokset',	    
	    'Väkivalta kodin ulkopuolella',
	    'Lähisuhdeväkivalta',	    
	    'Seksuaalirikokset',
	    'Liikenneonnettomuudet',	   
	    'Tulipalot',
	    'Koti- ja vapaa-ajan tapaturmat',
	    'Terrorismi',
	    'Vihapuhe, viharikokset',
	    'Kyberrikollisuus',
	    'Epidemiat, pandemiat',
	    'Poliisin voimankäyttö',
	];
	this.fear_topics = {
	    1: 'Vanhukset ja ikääntyvät',
            2: 'EU:hun liittyvät',
            3: 'Tasa-arvo',
            4: 'Piittaamattomuus ja itsekkyys',
            5: 'Järjestäytynyt rikollisuus ja muu rikollisuus',
            6: 'Maahanmuuttoon liittyvä',
            7: 'Yhteiskunnallinen vakaus',
            8: 'Arvopohja',
            9: 'Alkoholi ja alkoholinkäyttäjät',
            10: 'Järjestyshäiriöt',
            11: 'Huumeisiin liittyvät',
            12: 'Ympäristöön liittyvä',
            13: 'Koulu ja lasten turvallisuus',
            14: 'Talous ja työllisyys',
            15: 'Terveys',
            16: 'Syrjäytyminen ja yksinäisyys',
            17: 'Kansainvälinen vakaus',
            18: 'Psyykkinen terveys',
            19: 'Media ja sananvapaus',
            20: 'Julkiset palvelut',
            21: 'Viranomaisten toimet',
            22: 'Poliitikkojen toimet',
            23: 'Luonnonkatastrofit',
            24: 'Omaisuusrikokset',
            25: 'Väkivalta kodin ulkopuolella',
            26: 'Lähisuhdeväkivalta',
            27: 'Seksuaalirikokset',
            28: 'Liikenne',
            29: 'Tulipalot',
            30: 'Koti- ja vapaa-ajan tapaturmat',
            31: 'Terrorismi',
            32: 'Vihapuhe, viharikokset',
            33: 'Ääriliikkeiden hillintä',
            34: 'Tietoverkkoihin liittyvät',
            35: 'Epidemiat ja pandemiat',
            36: 'Arki ja harrastukset',
            37: 'Asuinpaikka',
            38: 'Koulutus',
            39: 'Läheiset ja koti',
            40: 'Oma varautuminen',
            41: 'Tieto',
	    42: 'Valvonta ja vartiointi',
	};
	this.major_regions = {
	    '246/1': { name: 'Helsinki-Uusimaa' },
	    '246/2': { name: 'Etelä-Suomi' },
	    '246/3': { name: 'Länsi-Suomi' },
	    '246/4': { name: 'Pohjois- ja Itä-Suomi' },
	    '246/5': { name: 'Ahvenanmaa' },
	};
    }

    componentDidMount() {
	const props = this.props;
	const is_filtered = this.isFiltered(props.filter);

	this.getData();
	if (is_filtered) this.getData(props.filter);
    }
    componentWillUnmount() {
	// clearTimeout(this.intervalId);
    }
    componentDidUpdate(prevProps) {
	const props = this.props;
	let reload_all = false, reload = false, clear = false;
	const is_filtered = this.isFiltered(props.filter);
	if (props.projectId && (!prevProps.projectId || props.projectId !== prevProps.projectId)) {
	    reload_all = reload = true;
	} else if (props.filter.v !== prevProps.filter.v) {
	    if (!is_filtered) {
	     	clear = true;
	    } else {
	     	reload = true;
	    }
	}

	if (reload_all) {
	    this.setState({ isLoaded: false });
	    this.getData();
	    if (is_filtered) this.getData(props.filter);
	} else if (reload) {
	    this.getData(props.filter);
	} else if (clear) {
	    this.setState({ actualVersion: props.filter.v });
	    props.onHideBackdrop();
	}
    }

    isFiltered(filter) {
	for (let [key, value] of Object.entries(filter)) {
	    if (key !== "v" && key !== "tv" && key !== "t") return true;
	}
	return false;
    }

    getData(filter) {
	if (filter == null) filter = { };

	const { projectId } = this.props;
	const is_filtered = this.isFiltered(filter);
	let p = { id: projectId };

	for (let [key, value] of Object.entries(filter)) {
	    p[key] = Array.isArray(value) ? value.join() : value;
	}

	console.log("loading data, v = " + filter.v);

	fetch("https://api.sometrik.com/project/telebus?" + new URLSearchParams(p), { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    if (is_filtered) {
			console.log("received filtered data");
			this.setState({
			    filteredData: result,
			    isLoaded: true,
			    actualVersion: filter.v
			});
		    } else {
			console.log("received data");
			this.setState({
			    data: result,
			    isLoaded: true,
			    actualVersion: filter.v
			});
		    }
		    this.props.onHideBackdrop();
		    this.getWordData(filter);
		    this.getMessageData(filter);
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components.
		(error) => {
		}
	    )
    }

    getWordData(filter) {
        const { projectId } = this.props;
        const is_filtered = this.isFiltered(filter);
        let p = { id: projectId, mode: "words" };

	for (let [key, value] of Object.entries(filter)) {
	    p[key] = Array.isArray(value) ? value.join() : value;
	}

        fetch("https://api.sometrik.com/project/telebus?" + new URLSearchParams(p), { credentials: 'include' } )
            .then(res => res.json())
            .then(
                (result) => {
		    if (is_filtered) {
			this.setState({
			    filteredWordData: result.words,
			});
		    } else {
			this.setState({
			    wordData: result.words,
			});
		    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                }
            )
    }

    getMessageData(filter) {
        const { projectId } = this.props;
        const is_filtered = this.isFiltered(filter);
        let p = { id: projectId, mode: "messages" };

	for (let [key, value] of Object.entries(filter)) {
	    p[key] = Array.isArray(value) ? value.join() : value;
	}

        fetch("https://api.sometrik.com/project/telebus?" + new URLSearchParams(p), { credentials: 'include' } )
            .then(res => res.json())
            .then(
                (result) => {
		    if (is_filtered) {
			this.setState({
			    filteredMessageData: result,
			});
		    } else {
			this.setState({
			    messageData: result,
			});
		    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                }
            )
    }

    render() {
	console.log("rendering cube");

	const bias = 2;

	const { filter, staticData, projectId, classes } = this.props;
	const { isLoaded, error, data, filteredData, messageData, filteredMessageData, wordData, filteredWordData } = this.state;

	let active_categories = this.fear_topics;
        const is_filtered = this.isFiltered(filter);

	const unfilteredData = data != null ? data : { };

	const base_url = "/project/" + projectId + "/telebus";
	let activeData = data, activeMessageData = messageData, activeWordData = wordData;
	if (is_filtered) {
	    if (filteredData) activeData = filteredData;
	    if (filteredMessageData) activeMessageData = filteredMessageData;
	    if (filteredWordData) activeWordData = filteredWordData;
	}

	console.log(base_url);

	const getProvinceName = (id) => {
	    const c = staticData.provinces && staticData.provinces[id];
	    if (c) return c.name;
	    else return '#' + id;
	}

	const getMajorRegionName = (id) => {
	    const r = this.major_regions[id];
	    if (r) return r.name;
	    else return '#' + id;
	}

	const basicFormatter = value => (typeof value === 'number') ? value.toFixed(0) : value;
	const basicFormatterBias = value => (typeof value === 'number') ? (value - bias).toFixed(0) : value;
	const basicFormatter2 = value => (typeof value === 'number') ? value.toFixed(1) : value;
	const basicFormatterRelBias = value => (typeof value === 'number') ? (value - bias).toFixed(0) + '%': value;

	let monthData = [ ];
	let genderData = [ ];
	let ageData = [ ];
	let occupationData = [ ];
	let educationData = [ ];
	let householdSizeData = [ ];
	let countryData = [ ];
	let majorRegionData = [ ];
	let provinceData = [ ];
	let topMajorRegionData = [ ];
	let topProvinceData = [ ];
	let regionData = [ ];
	let aspectData = [ ];
	let apartmentData = [ ];
	let economicLifeStageData = [ ];
	let incomeClassData = [ ];
	let avgFear = 77;
	let fearIndex = null;
	let fearIndexN = null;

	let countryHashtagData = { };
	let regionHashtagData = { };
	let provinceHashtagData = { };
	let majorRegionHashtagData = { };

	if (data && (!is_filtered || filteredData)) {
	    console.log("has activedata");

	    monthData = [ { name: 'Indeksi', data: activeData.months.activity.map( v => v + bias ) } ];
	    educationData = [ { name: 'Indeksi', data: activeData.education_dist.map( v => v + bias ) } ];
	    ageData = [ { name: 'Indeksi', data: activeData.age_dist.map( v => v + bias ) } ];
	    occupationData = [ { name: 'Indeksi', data: activeData.occupation_dist.map( v => v + bias ) } ];
	    householdSizeData = [ { name: 'Indeksi', data: activeData.household_size_dist.map( v => v + bias ) } ];
	    apartmentData = [ { name: 'Indeksi', data: activeData.apartment_type_dist.map( v => v + bias ) } ];
	    economicLifeStageData = [ { name: 'Indeksi', data: activeData.economic_life_stage_dist.map( v => v + bias ) } ];
	    incomeClassData = [ { name: 'Indeksi', data: activeData.income_class_dist.map( v => v + bias ) } ];
	    genderData = [ { name: 'Indeksi', data: activeData.gender_dist.map( v => v + bias ) } ];

	    countryData = activeData.countries.data;
	    majorRegionData = activeData.major_regions.data;
	    provinceData = activeData.provinces.data;
	    topProvinceData = activeData.provinces.most_active;
	    topMajorRegionData = activeData.major_regions.most_active;
	    regionData = activeData.regions.data;
	    aspectData = activeData.aspects;
	    if (activeData.index_value != null) fearIndex = avgFear = activeData.index_value;
	    fearIndexN = activeData.total_activity;

	    countryHashtagData = activeData.countries.aspects;
	    majorRegionHashtagData = activeData.major_regions.aspects;
	    provinceHashtagData = activeData.provinces.aspects;
	    regionHashtagData = activeData.regions.aspects;
	}

	let fearLevelData = [ ];
	if (activeData && 0) {
	    let a = activeData.aspects;
	    let values = [ ];
	    for (let j = 0; j < 12; j++) {
		let f = a[1][j] + a[2][j] + a[3][j];
		let t = a[0][j] + f;
		values.push( 100 * f / t );
	    }
	    fearLevelData = [ { name: 'Osuus', data: values } ];
	}

	let aspectsData = [
	    {
		name: 'Ei huolestuta lainkaan',
		data: aspectData[0],
	    },
	    {
		name: 'Huolestuttaa vain vähän',
		data: aspectData[1],
	    },
	    {
		name: 'Huolestuttaa melko paljon',
		data: aspectData[2],
	    },
	    {
		name: 'Huolestuttaa erittäin paljon',
		data: aspectData[3],
	    }
	];

	const layers = [
	    {
		countries: staticData.countries,
		hashtags: countryHashtagData,
		data: countryData,
		src: "/world-110m.json",
		maxZoom: 0.5,
		onClick: (id) => {
		    this.props.onUpdateFilter('country', id);
		}
	    },
	    {
		hashtags: majorRegionHashtagData,
		countries: this.major_regions,
		data: majorRegionData,
		src: '/nuts2.json',
		minZoom: 0.5,
		maxZoom: 0.85,
		onClick: (key) => {
		    if (key != null) {
			this.props.onUpdateFilter('major_region', key);
		    }
		}
	    },
	    {
		hashtags: provinceHashtagData,
		countries: staticData.provinces,
		data: provinceData,
		src: '/nuts3.json',
		minZoom: 0.85,
		maxZoom: 2.6,
		onClick: (key) => {
		    this.props.onUpdateFilter('province', key);
		}
	    },
	    {
		hashtags: regionHashtagData,
		countries: staticData.regions,
		data: regionData,
		src: "/Kunnat2020_topojson.json",
		minZoom: 2.6,
		onClick: (id) => {
		    this.props.onUpdateFilter('region', id);
		}
	    },
	];

	const ShowMessages = (props) => {
	    return(
		<ShowMoreDialog dialogTitle="More messages"
                                data={ (activeMessageData && activeMessageData.messages[props.match.params.id] ? activeMessageData.messages[props.match.params.id].data : []).map( item => (
                                            [
                                                item.content
                                            ]
                                        ))}
                                open={true} onClose={() => this.props.history.push(base_url)} />
	    );
	}

	const plotColorRange = [
	    {
		from: avgFear - 100,
		to: avgFear - 12.5,
		color: '#4575b4'
	    }, {
		from: avgFear - 12.5,
		to: avgFear - 7.5,
		color: '#91bfdb'
	    }, {
		from: avgFear - 7.5,
		to: avgFear - 2.5,
		color: '#e0f3f8'
	    }, {
		from: avgFear - 2.5,
		to: avgFear + 2.5,
		color: '#c0c0c0'
	    }, {
		from: avgFear + 2.5,
		to: avgFear + 7.5,
		color: '#fee090'
	    }, {
		from: avgFear + 7.5,
		to: avgFear + 12.5,
		color: '#fc8d59'
	    }, {
		from: avgFear + 12.5,
		to: avgFear + 100,
		color: '#d73027'
	    }
	];

	const chartStates = {
	    states: {
		active: {
		    // allowMultipleDataPointsSelection: true,
		    filter: {
			type: 'none'
		    }
		},
		hover: {
		    filter: {
			type: 'lighten'
		    }
		}
	    }
	};

	const barOptions = {
	    grid: {
		show: false,
	    },
	    xaxis: {
		min: 0,
		max: 100,
	    },
            yaxis: {
                labels: {
		    show: false,
                    formatter: basicFormatterBias,
                },
            },
            dataLabels: {
                // enabled: false,
                formatter: basicFormatterBias,
		style: {
		    colors: [ '#333' ],
		}
            },
	    chart: {
		animations: {
                    enabled: false
		},
            },
	    plotOptions: {
                bar: {
                    colors: {
			ranges: plotColorRange,
                    },
                    columnWidth: '80%',
                }
            },
	    ...chartStates
        };

	const horizontalBarOptions = {
	    ...barOptions,
            yaxis: {
                labels: {
		    show: true,
		    formatter: basicFormatterBias,
		},
            },
	    plotOptions: {
		bar: {
		    colors: {
			ranges: plotColorRange,
                    },
                    horizontal: true,
		}
            },
	};

	const topFearOptions = {
	    grid: {
		show: false,
	    },
            yaxis: {
                labels: {
		    show: false,
                    formatter: basicFormatter2,
                },
            },
            dataLabels: {
                // enabled: false,
                formatter: basicFormatter,
		style: {
		    colors: [ '#333' ],
		}
            },
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			// this.props.onUpdateFilter('education', config.selectedDataPoints[0].map( v => v + 1 ));
			// this.props.onUpdateFilter('education', config.dataPointIndex + 1);
		    },
		},
		animations: {
                    enabled: false
		},
	    },
            xaxis: {
		min: 0,
		max: 100,
		labels: {
		    show: true,
                },
            },
	    legend: {
		position: 'top',
	    },
	    stroke: {
		show: true,
		width: 1,
		colors: ['#fff']
            },
	    colors: ['#d73027', '#c0c0c0'],
	    ...chartStates
        };

	let topFearData = [ ];
	if (data && filteredData && is_filtered) {
	    let a1 = filteredData.aspects;
	    let a2 = data.aspects;

	    if (a1 && a2 && a1.length && a2.length) {
		const fears = [ ];
		for (let j = 0; j < 12; j++) {
		    let f1 = a1[1][j] + a1[2][j] + a1[3][j];
		    let t1 = a1[0][j] + f1;
		    let v1 = 100 * f1 / t1;
		    
		    let f2 = a2[1][j] + a2[2][j] + a2[3][j];
		    let t2 = a2[0][j] + f2;
		    let v2 = 100 * f2 / t2;
		    
		    let diff = v1 - v2;
		    if (diff > 0) fears.push( { id: j, selected: v1, base: v2, diff: diff } );
		}
		fears.sort( (a, b) => b.diff - a.diff );
		let values1 = [ ], values2 = [ ], categories = [ ];
		for (let j = 0; j < fears.length && j < 5; j++) {
		    const v = fears[j];
		    values1.push(v.selected);
		    values2.push(v.base);
		    categories.push(this.aspects[v.id]);
		}
		topFearData = [ { name: 'Valittu aineisto', data: values1 }, { name: 'Koko aineisto', data: values2 } ];
		topFearOptions.xaxis.categories = categories;
	    }
	}

	const wordOptions = {
            chart: {
		type: 'bar',
		height: 350,
		animations: {
                    enabled: false
		},
            },
            plotOptions: {
		bar: {
		    horizontal: true,
		}
            },
            dataLabels: {
		enabled: false,
		style: {
		    colors: [ '#333' ],
		}
            },
	    ...chartStates
	};

	const word1Options = {
	    ...wordOptions,
            xaxis: {
		categories: activeWordData && activeWordData[1] ? activeWordData[1].most_used_words : []
            }
        };

	const word2Options = {
	    ...wordOptions,
            xaxis: {
		categories: activeWordData && activeWordData[2] ? activeWordData[2].most_used_words : []
            }
        };

	const word3Options = {
	    ...wordOptions,
            xaxis: {
		categories: activeWordData && activeWordData[3] ? activeWordData[3].most_used_words : []
            }
        };

	const educationOptions = {
	    ...horizontalBarOptions,
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			// this.props.onUpdateFilter('education', config.selectedDataPoints[0].map( v => v + 1 ));
			this.props.onUpdateFilter('education', config.dataPointIndex + 1);
		    },
		},
		animations: {
                    enabled: false
		},
	    },
            xaxis: {
		labels: {
		    show: false,
                },
                categories: [ 'perus-/kansakoulu', 'ammatti-, tekninen-/kauppakoulu', 'ylioppilas/lukio', 'opisto', 'ammattikorkeakoulu', 'yliopisto/korkeakoulu' ],
            },
        };
	
	const fearLevelOptions = {
	    ...horizontalBarOptions,
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			// this.props.onUpdateFilter('education', config.selectedDataPoints[0].map( v => v + 1 ));
			this.props.onUpdateFilter('education', config.dataPointIndex + 1);
		    },
		},
		animations: {
                    enabled: false
		},
	    },
            xaxis: {
		labels: {
		    show: false,
                },
                categories: this.aspects,
            },
        };

	const householdSizeOptions = {
	    ...barOptions,
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			// this.props.onUpdateFilter('household_size', config.selectedDataPoints[0].map( v => v + 1 ));
			this.props.onUpdateFilter('household_size', config.dataPointIndex + 1);
		    },
		},
		animations: {
		    enabled: false
		},
	    },
            xaxis: {
                categories: [ 1, 2, 3, 4, 5, '6+' ],
            },
        };

	const incomeClassOptions = {
	    ...horizontalBarOptions,
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			// this.props.onUpdateFilter('income_class', config.selectedDataPoints[0].map( v => v + 1 ));
			this.props.onUpdateFilter('income_class', config.dataPointIndex + 1);
		    },
		},
		animations: {
                    enabled: false
		},
	    },
            xaxis: {
		labels: {
		    show: false,
                },
                categories: [ 'alle 10k€/v', '10 - 20k€/v', '20 - 30k€/v', '30 - 40k€/v', '40 - 50k€/v', '50 - 60k€/v', '60 - 70k€/v', '70 - 80k€/v', '80 - 90k€/v', 'yli 90k€/v', 'En halua vastata' ],
            },
        };

	const ageOptions = {
	    ...barOptions,
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			// this.props.onUpdateFilter('age_class', config.selectedDataPoints[0].map( v => v + 1 ));
			 this.props.onUpdateFilter('age_class', config.dataPointIndex + 1);
		    },
		},
		animations: {
                    enabled: false
		},
	    },
            xaxis: {
                categories: [ '< 18', '18 - 24', '25 - 34', '35 - 44', '45 - 54', '55 - 64', '>64' ],
            },
        };

	const occupationOptions = {
	    ...horizontalBarOptions,
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			// this.props.onUpdateFilter('occupation', config.selectedDataPoints[0].map( v => v + 1 ));
			this.props.onUpdateFilter('occupation', config.dataPointIndex + 1);
		    },
		},
		animations: {
                    enabled: false
		},
	    },
            xaxis: {
		labels: {
		    show: false,
                },
                categories: [ 'yrittäjä', 'johtava asema', 'muu ylempi toimihenkilö, asiantuntija', 'toimihenkilö', 'työntekijä', 'maanviljelijä', 'opiskelija, koululainen', 'eläkeläinen', 'kotiäiti/-isä', 'työtön' ],
            },
        };

	let economicLifeStageOptions = {
	    ...horizontalBarOptions,
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			// this.props.onUpdateFilter('economic_life_stage', config.selectedDataPoints[0].map( v => v + 1 ));
			this.props.onUpdateFilter('economic_life_stage', config.dataPointIndex + 1);
		    },
		},
		animations: {
                    enabled: false
		},
	    },
	    xaxis: {
		labels: {
		    show: false,
                },
		categories: [ 'yksinäistalous', 'lapseton pari', 'muu aikuistalous', 'talous, jossa lapsia' ], // 'En tiedä' ],
	    },
	};

	let genderOptions = {
	    ...barOptions,
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			// this.props.onUpdateFilter('gender', config.selectedDataPoints[0].map( v => v + 1 ));
			this.props.onUpdateFilter('gender', config.dataPointIndex + 1);
		    },
		},
		animations: {
                    enabled: false
		},
	    },
	    xaxis: {
		categories: [ 'Mies', 'Nainen' ],
	    },
	};

	let monthOptions = {
	    ...barOptions,
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			// this.props.onUpdateFilter('month', config.selectedDataPoints[0].map( i => activeData.months.months[i] ));
			this.props.onUpdateFilter('month', activeData.months.months[config.dataPointIndex] );
		    },
		},
		animations: {
		    enabled: false
		},
	    },
	    xaxis: {
		categories: activeData ? activeData.months.months : [ ],
		labels: {
                    formatter: mv => Math.floor(mv / 12) + '-' + (mv % 12 + 1),
                }
	    },
	};

	let apartmentOptions = {
	    ...barOptions,
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			// this.props.onUpdateFilter('apartment_type', config.selectedDataPoints[0].map( v => v + 1 ));
			this.props.onUpdateFilter('apartment_type', config.dataPointIndex + 1);
		    },
		},
		animations: {
		    enabled: false
		},
	    },
	    xaxis: {
		categories: [
		    'omakotitalo',
		    'rivi-/paritalo',
		    'kerrostalo',
		],
	    },
	};

	const optionsTopicBars = {
	    grid: {
		show: false,
	    },
            yaxis: {
                labels: {
		    show: false,
                    formatter: basicFormatterBias,
                },
            },
	    dataLabels: {
		enabled: true,
		style: {
		    colors: ['#333']
		},
		formatter: basicFormatterRelBias,
	    },
	    ...chartStates
        };

	const positiveTopics = activeMessageData && activeMessageData.positive_topics ? activeMessageData.positive_topics : [];
	const optionsPositiveTopicBars = {
	    ...optionsTopicBars,
            xaxis: {
                categories: positiveTopics.map( t => active_categories[t[0]] || '#' + t[0] ),
            },
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			this.props.onUpdateFilter('pos_topic', positiveTopics[config.dataPointIndex][0] );
		    }
		},
		animations: {
		    enabled: false
		},
	    },
	};

	const negativeTopics = activeMessageData && activeMessageData.negative_topics ? activeMessageData.negative_topics : [];
	const optionsNegativeTopicBars = {
	    ...optionsTopicBars,
            xaxis: {
                categories: negativeTopics.map( t => active_categories[t[0]] || '#' + t[0] ),
            },
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			this.props.onUpdateFilter('neg_topic', negativeTopics[config.dataPointIndex][0] );			
		    }
		},
		animations: {
		    enabled: false
		},
	    },
	    colors: [ '#fc8d59' ],
	};

	const aspectOptions = {
	    chart: {
		type: 'bar',
		height: 350,
		stacked: true,
		stackType: '100%',
		animations: {
                    enabled: false
		},
            },
            plotOptions: {
		bar: {
		    horizontal: true,
		},
            },
            stroke: {
		width: 1,
		colors: ['#fff']
            },
	    tooltip: {
		y: {
		    formatter: function (val) {
			return val;
		    }
		}
            },
            fill: {
		opacity: 1

            },
            legend: {
		position: 'top',
		horizontalAlign: 'center',
		offsetX: 40
            },
	    xaxis: {
		categories: this.aspects,
	    },
	    colors: [
		'#cccccc',
		'#fdcc8a',
		'#fc8d59',
		'#d7301f',
	    ],
	    ...chartStates
	}

	if (error) {
	    return <div>Error</div>;
	} else {
	    return (
		<>
		    <Route exact path={base_url + "/messages/:id"} component={ShowMessages}/>

		    <Route exact path={base_url + "/provinces"}>
                        <ShowMoreDialog dialogTitle="More provinces" data={ topProvinceData.map( ds => (
                                            [
                                                getProvinceName(ds.id),
                                                basicFormatter(ds.activity),
                                                ds.id,
                                                () => this.updateFilter('province', ds.id)
                                            ]
                                        ))}
					open={true} onClose={() => this.props.history.push(base_url)} />
                    </Route>

		    <Grid container spacing={3}>
			<IndexValueCard index={fearIndex} baseIndex={unfilteredData && unfilteredData.index_value} n={fearIndexN} title="Yksittäisen henkilön pelkotaso voi olla 0, 33 %, 66 % tai 100 %, laskettuna henkilön suurimman pelon mukaan. Näiden kaikkien keskiarvosta tulee turvattomuusindeksi."  decimals={1} />

			<Grid item xs={12} md={8} lg={8}>
		    	    <Card className={classes.standardChart}>
				<CardContent>
				    <CardTitle>Turvattomuus kuukausittain</CardTitle>
				    <Chart options={monthOptions} series={monthData} type="bar" height={240} />
				</CardContent>
			    </Card>
			</Grid>

			<Grid item xs={12}>
                            <Card className={classes.chart}>
			        <MapChart v={this.state.actualVersion} dataAvailable={isLoaded && staticData.isLoaded} isRelative={true} style={{margin:0,padding:0}} layers={layers} proj={{
					      rotate: [-25.0, -65.0, 0],
					      scale: 3000
                                          }}
                                />
				<CardTitle>&nbsp;Turvattomuus alueittain</CardTitle>
			    </Card>
                        </Grid>

			<Grid item xs={12} md={4} lg={4}>
			    <Card className={classes.standardChart}>
                                <CardContent>
                                    <CardTitle>Kerro asiat, jotka huolestuttavat tai pelottavat</CardTitle>
                                    <Chart
                                        options={word1Options}
                                        series={ activeWordData && activeWordData[1] ? [ { name: 'Words', data: activeWordData[1].most_used_frequencies } ] : [ ] }
                                        type="bar"
                                        height={240}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

			<Grid item xs={12} md={4} lg={4}>
                            <Card className={classes.standardChart}>
                                <CardContent>
                                    <CardTitle>Onko jotain turvallisuuden tunnetta lisääviä asioita?</CardTitle>
                                    <Chart
                                        options={word2Options}
                                        series={ activeWordData && activeWordData[2] ? [ { name: 'Words', data: activeWordData[2].most_used_frequencies } ] : [ ] }
                                        type="bar"
                                        height={240}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

			<Grid item xs={12} md={4} lg={4}>
                            <Card className={classes.standardChart}>
                                <CardContent>
                                    <CardTitle>Mitkä asiat ovat vaikuttaneet siihen, että olet huolestunut tämän suhteen?</CardTitle>
                                    <Chart
                                        options={word3Options}
					series={ activeWordData && activeWordData[3] ? [ { name: 'Words', data: activeWordData[3].most_used_frequencies } ] : [ ] }
                                        type="bar"
                                        height={240}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

			<Grid item xs={12}>
                            <Card className={classes.chart}>
                                <CardContent>
                                    <CardTitle>Aiheet</CardTitle>
                                    <Chart
                                        options={aspectOptions}
                                        series={aspectsData}
                                        type="bar"
                                        height={340}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

			{ false ?
			  <Grid item xs={12}>
                              <Card className={classes.chart}>
                                  <CardContent>
                                      <CardTitle>Pelkotasot</CardTitle>
                                      <Chart
                                          options={fearLevelOptions}
                                          series={fearLevelData}
                                          type="bar"
                                          height={340}
                                      />
                                  </CardContent>
                              </Card>
                          </Grid> : null }
			  
			<Grid item xs={12}>
			    <Card className={classes.standardChart}>
				<CardContent>
				    <CardTitle>Avovastausten aiheet</CardTitle>
				    <Chart
					options={optionsNegativeTopicBars}
					series={ [ {
					    name: "Osuus",
					    data: (activeMessageData && activeMessageData.negative_topics ? activeMessageData.negative_topics : []).map( t => t[1] + bias ),
					} ] }
					type="bar"
					height={240}
                                    />
				</CardContent>
			      </Card>
			</Grid>

			{ true ?
			<Grid item xs={12}>
			    <Card className={classes.standardChart}>
				<CardContent>
				    <CardTitle>Turvallisuuden tunnetta lisäävät aiheet</CardTitle>
				    <Chart
					options={optionsPositiveTopicBars}
					series={ [ {
					    name: "Osuus",
					    data: (activeMessageData && activeMessageData.positive_topics ? activeMessageData.positive_topics : []).map( t => t[1] + bias ),
					} ] }
					type="bar"
					height={240}
                                    />
				</CardContent>
			      </Card>
			</Grid> : null }

			<Grid item xs={12} md={4} lg={4}>
                            <Card className={classes.standardChart}>
                                <CardContent>
                                    <CardTitle>Turvattomuus ikäluokittain</CardTitle>
                                    <Chart
                                        options={ageOptions}
                                        series={ageData}
                                        type="bar"
                                        height={240}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

			<Grid item xs={12} md={4} lg={4}>
		    	    <Card className={classes.standardChart}>
				<CardContent>
				    <CardTitle>Turvattomuus sukupuolittain</CardTitle>
				    <Chart options={genderOptions} series={genderData} type="bar" />
				</CardContent>
			    </Card>
			</Grid>

			<Grid item xs={12} md={4} lg={4}>
                            <Card className={classes.standardChart}>
                                <CardContent>
                                    <CardTitle>Turvattomuus talouden elinvaiheittain</CardTitle>
                                    <Chart options={economicLifeStageOptions} series={economicLifeStageData} type="bar" />
                                </CardContent>
                            </Card>
                        </Grid>

			<Grid item xs={12} md={4} lg={4}>
                            <Card className={classes.standardChart}>
                                <CardContent>
                                    <CardTitle>Turvattomuus koulutusluokittain</CardTitle>
                                    <Chart
                                        options={educationOptions}
                                        series={educationData}
                                        type="bar"
                                        height={240}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

			<Grid item xs={12} md={4} lg={4}>
                            <Card className={classes.standardChart}>
                                <CardContent>
                                    <CardTitle>Turvattomuus talouden kokoluokittain</CardTitle>
                                    <Chart
                                        options={householdSizeOptions}
                                        series={householdSizeData}
                                        type="bar"
                                        height={240}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

			<Grid item xs={12} md={4} lg={4}>
                            <Card className={classes.standardChart}>
                                <CardContent>
                                    <CardTitle>Turvattomuus ammateittain</CardTitle>
				    <Chart
                                        options={occupationOptions}
                                        series={occupationData}
                                        type="bar"
                                        height={240}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

			<Grid item xs={12} md={4} lg={4}>
                            <Card className={classes.standardChart}>
                                <CardContent>
                                    <CardTitle>Turvattomuus asumismuodoittain</CardTitle>
                                    <Chart options={apartmentOptions} series={apartmentData} type="bar" />
                                </CardContent>
                            </Card>
                        </Grid>

			<Grid item xs={12} md={4} lg={4}>
                            <Card className={classes.standardChart}>
                                <CardContent>
                                    <CardTitle>Turvattomuus tuloluokittain</CardTitle>
                                    <Chart
                                        options={incomeClassOptions}
                                        series={incomeClassData}
                                        type="bar"
                                        height={240}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

			<Grid item xs={12} md={4} lg={4}>
			    <Card className={classes.standardChart}>
                                <CardContent>
                                    <CardTitle>Turvattomuus suuralueittain</CardTitle>
                                    <Table className={classes.statisticsTable} size="small">
                                        <TableBody>
                                            { topMajorRegionData.slice(0, 10).map( c => (
                                                <TableRow key={c.id}>
                                                    <TableCell component="th" scope="row" className={classes.tableHeader}><Link onClick={() => this.props.onUpdateFilter('major_region', c.id)}>{getMajorRegionName(c.id)}</Link></TableCell>
                                                    <TableCell align="right">{basicFormatter(c.activity)}</TableCell>
                                                </TableRow>
                                            ) ) }
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>

			<Grid item xs={12} md={4} lg={4}>
                            <Card className={classes.standardChart}>
                                <CardContent>
                                    <CardTitle>Turvattomuus maakunnittain</CardTitle>
                                    <Table className={classes.statisticsTable} size="small">
                                        <TableBody>
                                            { topProvinceData.slice(0, 6).map( c => (
                                                <TableRow key={c.id}>
                                                    <TableCell component="th" scope="row" className={classes.tableHeader}><Link onClick={() => this.props.onUpdateFilter('province', c.id)}>{getProvinceName(c.id)}</Link></TableCell>
                                                    <TableCell align="right">{basicFormatter(c.activity)}</TableCell>
                                                </TableRow>
                                            ) ) }
                                        </TableBody>
                                    </Table>
                                </CardContent>
				{ topProvinceData.length > 7 ? (
				    <CardActions>
					<Button onClick={() => this.props.history.push(base_url + "/provinces")}>Näytä {topProvinceData.length - 7} lisää</Button>
				    </CardActions>
				) : null }
                            </Card>
                        </Grid>

			<Grid item xs={12} md={8} lg={8}>
			    <Card className={classes.standardChart}>
				<CardContent>
				    <CardTitle>Merkittävimmät pelot</CardTitle>
				    { topFearData.length > 0 ?
				      <Chart
                                          options={topFearOptions}
                                          series={topFearData}
                                          type="bar"
                                          height={240}
                                      />				      				      
				      : <div>Rajaamalla aineistoa näet rajatun aineiston tärkeimmät pelonaiheet</div>
				    }
				</CardContent>
			    </Card>
			</Grid>

			<Grid item xs={12}>
                            <Card className={classes.chart}>
                                <CardContent>
                                    <CardTitle>Kerro ensin asiat, jotka huolestuttavat tai pelottavat</CardTitle>
				    <Table className={classes.statisticsTable} size="small">
					<TableBody>
					    { (activeMessageData && activeMessageData.messages && activeMessageData.messages[1] ? activeMessageData.messages[1].data : []).slice(0, 5).map( item => (
						<TableRow key={item.id}>
						    <TableCell><div className={classes.limitedCell}>{item.content}</div></TableCell>
						</TableRow>
					    ))}
					</TableBody>
				    </Table>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={() => this.props.history.push(base_url + "/messages/1")}>Näytä lisää</Button>
                                </CardActions>
			    </Card>
                        </Grid>

			<Grid item xs={12}>
                            <Card className={classes.chart}>
                                <CardContent>
                                    <CardTitle>Entä onko jotain turvallisuuden tunnetta lisääviä asioita?</CardTitle>
				    <Table className={classes.statisticsTable} size="small">
					<TableBody>
					    { (activeMessageData && activeMessageData.messages && activeMessageData.messages[2] ? activeMessageData.messages[2].data : []).slice(0, 5).map( item => (
						<TableRow key={item.id}>
						    <TableCell><div className={classes.limitedCell}>{item.content}</div></TableCell>
						</TableRow>
					    ))}
					</TableBody>
				    </Table>
                                </CardContent>
				<CardActions>
                                        <Button onClick={() => this.props.history.push(base_url + "/messages/2")}>Näytä lisää</Button>
                                    </CardActions>
                            </Card>
                        </Grid>

			<Grid item xs={12}>
                            <Card className={classes.chart}>
                                <CardContent>
                                    <CardTitle>Mikä tai mitkä asiat ovat vaikuttaneet siihen, että olet huolestunut tämän asian suhteen?</CardTitle>
				    <Table className={classes.statisticsTable} size="small">
					<TableBody>
					    { (activeMessageData && activeMessageData.messages && activeMessageData.messages[3] ? activeMessageData.messages[3].data : []).slice(0,5).map( item => (
						<TableRow key={item.id}>
						    <TableCell><div className={classes.limitedCell}>{item.content}</div></TableCell>
						</TableRow>
					    ))}
					</TableBody>
				    </Table>
                                </CardContent>
				<CardActions>
                                    <Button onClick={() => this.props.history.push(base_url + "/messages/3")}>Näytä lisää</Button>
                                </CardActions>
                            </Card>
                        </Grid>

		    </Grid>
		</>
	    );
	}
    }
}

export default withRouter(withStyles(styles)(TelebusView));
