import React from "react";
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

export default class RecordDialogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        return { open: false, input: null, error: true, hasWritten: false,}
    }

    render() {
        const handleInputChange = (event) => {
            const target = event.target;

	    this.setState({ error: target.value.length < 1 });
	    if (target.value.length > 0) this.setState({hasWritten: true});

            this.setState({
                [target.name]: target.value
            });
        }

        const handleSubmit = () => {
	    this.setState(this.getInitialState());
            this.props.onSubmit(1, this.state.input);
        }

        const handleClose = () => {
            this.setState(this.getInitialState());
            this.props.onClose();
        }

        return (
	    <Dialog open={this.props.open} onClose={handleClose} aria-labelledby="new-group-dialog-title">
                    <DialogTitle id="new-group-dialog-title">New Group</DialogTitle>
                    <DialogContent>
                        <TextField
                            name="input"
                            onChange={handleInputChange}
                            value={this.state.input}
                            ref="name"
                            autoFocus
                            margin="dense"
                            label="Name"
                            fullWidth
			    error={ this.state.error && this.state.hasWritten }
                            helperText={ this.state.error && this.state.hasWritten ? 'Required field.' : null }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color="primary" disabled={ this.state.error }>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
	);
    }
}
