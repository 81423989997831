import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CardTitle from './CardTitle';
import Typography from '@material-ui/core/Typography';
import Chart from "react-apexcharts";
import Message from "./Message";
import Link from "@material-ui/core/Link";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    root: {
	// boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)'
	padding: '1em',
    },
    inner1: {
	position: 'relative',
	textTransform: 'uppercase',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	paddingRight: '5em',
    },
    inner2: {
	position: 'relative',
	marginTop: '0.25em',
    },
    box2: {
	marginTop: '0.25em',
    },
    f: {
	position: 'absolute',
	top: 0,
	right: 0
    },
    lemmas: {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
    },
    messageContainer: {
	marginTop: '0.25em',
    }
});

class TopicCard extends React.Component {
    updateFilter(key, value) {
	this.props.onUpdateFilter(key, value);	
    }

    render() {
	const { project, classes, topic, topics, sentimentLabels, sentimentColors, emotionLabels, messages, t, maxValue, datasets, onClick, onContextMenu } = this.props;

	if (!topic) return null;
	
	const topic_days = topic.days;
	
	let activityData = [ {
	    name: 'Activity',
	    data: t.map( (t, i) => [ t, topic_days[i] ] ),
	} ];

	const basicFormatter = value => (typeof value === 'number') ? value.toFixed(0) : value;

	const chartStates = {
	    states: {
		active: {
		    filter: {
			type: 'none'
		    }
		},
		hover: {
		    filter: {
			type: 'lighten'
		    }
		}
	    }
	};

	let timeseriesOptions = {
	    xaxis: {
		type: 'datetime',
		tooltip: {
		    enabled: false
		},
		labels: {
		    show: false,
		},
	    },
	    yaxis: {
		labels: {
		    formatter: basicFormatter,
		    // show: false,
		},
		tooltip: {
		    enabled: false
		},
		min: 0,
		max: maxValue,
		tickAmount: 4,
	    },
	    chart: {
		toolbar: {
		    show: false
		},
		animations: {
		    enabled: false
		},
		selection: {
		    enabled: false,
		},
		zoom: {
		    enabled: false,
		},
		sparkline: {
		    // enabled: true,
		}
	    },
	    stroke: {
		curve: 'straight',
		width: 2,
	    },
	    tooltip: {
		enabled: true,
		x: {
		    format: 'dd MMM yyyy'
		}
	    },
	    grid: {
		show: false,
		padding: {
		    right: 20,
		    top: 0,
		    bottom: 0,
		    left: 0
		}
	    },
	    colors: [ '#798897' ],
	};

	const sentimentSeries = [
	    {
		name: sentimentLabels[0],
		data: [ topic.sentiment[0] ],
            },            {
		name: sentimentLabels[1],
		data: [ topic.sentiment[1] ],
            }, {
		name: sentimentLabels[2],
		data: [ topic.sentiment[2] ],
            }
	];

	const orderedSentimentColors = [ sentimentColors[0], sentimentColors[1], sentimentColors[2] ];
	
	let sentimentOptions = {
	    ...chartStates,

	    chart: {
                type: 'bar',
                stacked: true,
                stackType: '100%',
		height: 50,
	    	events: {
		    dataPointSelection: (event, chart, config) => {
			this.updateFilter('sentiment', config.dataPointIndex);
		    }
		},
		animations: {
		    enabled: false
		},
		toolbar: { show: false },
		sparkline: { enabled: true },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            xaxis: {
                categories: [ 'Sentiment' ],
		labels: {
		    show: false,
                },
		show: false,
		axisBorder: { show: false },
		axisTicks: { show: false },
            },
	    yaxis: {
		show: false,
		labels: {
		    show: false,
		},
		axisBorder: { show: false },
		axisTicks: { show: false },
	    },
	    grid: {
		show: false,
		xaxis: {
		    lines: {
			show: false
		    }
		},   
		yaxis: {
		    lines: {
			show: false
		    }
		},
	    },
            legend: {
		show: false
	    },
	    tooltip: {
		enabled: true,
		y: {
		    formatter: basicFormatter,
		    title: {
			formatter: function (seriesName) {
			    return ''
			}
		    },
		}
            },	    
	    colors: orderedSentimentColors,
	};

	let emotions = topic.emotion;
	let total = 0;
	for (let i = 0; i < emotions.length; i++) total += emotions[i];
	let top_emotions = [ ];
	for (let i = 1; i < emotions.length; i++) {
	    top_emotions.push( { v: emotions[i], label: emotionLabels[i], text: emotionLabels[i] + ' ' + (100 * emotions[i] / total).toFixed(0) + ' %' } );
	}
	top_emotions.sort((a, b) => b.v - a.v);
	top_emotions.splice(3);
 	// const tone = top_emotions.slice(0, 2).map( e => e.label ).join(' / ');

	let top_sentiment = [ ];
	for (let i = 0; i < 3; i++) {
	    top_sentiment.push( { id: i, label: sentimentLabels[i], v: topic.sentiment[i], color: sentimentColors[i] } );
	}
	top_sentiment.sort((a, b) => b.v - a.v);
	if (top_sentiment[0].id == 0 && top_sentiment.length > 1) top_sentiment.shift();
 	const tone_sentiment = top_sentiment[0].label;
	const tone_emotion = top_emotions[0].label;
	const tone_color = top_sentiment[0].color;

	const topic_key = topic.id;
	let topic_name = null;
	if (topics) topic_name = topics[topic_key];
	if (topic_name == null) topic_name = '#' + topic.id;

	let topicMessages = messages && messages[topic.id] || [ ];
	
	return (
	    <div className={classes.root}>
		<CardTitle>
		    <div className={classes.inner1}><Link onClick={() => onClick && onClick(topic_key)} title={topic_name}>{topic_name}</Link>
			<span className={classes.f}>{topic.activity ? topic.activity.toFixed(1) + " % " : null}<IconButton size="small" onClick={ (e) => { onContextMenu(e.currentTarget, topic) } } ><MoreVertIcon /></IconButton></span>
		    </div>
		</CardTitle>

		{ false ?
		  <>
		      <Typography variant="h7" component="h4" color="primary">Typical words</Typography>
		      <Typography variant="subtitle2"><div className={classes.lemmas}>{ topic.lemmas }</div></Typography>
		  </>
		  : null }
		
		<div className={classes.inner2}>
		    <Typography variant="h7" component="h4" color="primary">Sentiment</Typography>
		    <Typography variant="subtitle" className={classes.f} style={{color: tone_color}}>Tone: {tone_sentiment} | {tone_emotion}</Typography>
		</div>
		
		<Chart options={sentimentOptions} series={sentimentSeries} height={50} type="bar" />

		<div>
		    <Typography className={classes.heading2} variant="h7" component="h4" color="primary">Popularity</Typography>
		    <Chart options={timeseriesOptions} series={activityData} type="line" height={125} />
		</div>

		{ false ?
		  <div className={classes.box2}>
		      <Typography className={classes.heading2} variant="h7" component="h4" color="primary">Top emotions</Typography>
		      <Typography variant="subtitle2">
			  { top_emotions.map( e => e.text ).join(' | ') }
		      </Typography>
		  </div>
		  : null }

		<div className={classes.box2}>
		    <Typography className={classes.heading2} variant="h7" component="h4" color="primary">Examples</Typography>
		    { topicMessages.map( message => 
			<div className={classes.messageContainer}><Message positive_regex={project && project.positive_regex} negative_regex={project && project.negative_regex} fixedSize={true} article={message} variant="small" datasets={datasets} /></div>
		    ) }
		</div>
	    </div>
	);
    }
}

export default withStyles(styles) (TopicCard);
