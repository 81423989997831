import React from "react";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

export default class AnnotationDialog extends React.Component {
    constructor(props) {
	super(props);
	this.state = this.getInitialState();
    }

    getInitialState() {
	return { date: new Date(), name: '' , hasWritten: false, nameError: true}
    }

    handleDateChange(event, value) {
        this.setState({ date: value });
    }
    
    render() {
	const handleInputChange = (event) => {
	    const target = event.target;

	    if (target.name === "name") {
                this.setState({ nameError: target.value.length < 1 });
                if (target.value.length > 0) this.setState({ hasWritten: true });
            }
	    
	    this.setState({
		[target.name]: target.value
	    });
	}
	
	const handleCreate = () => {
	    this.props.onCreate(this.state.date, this.state.name);	
	    this.props.onClose();
	    this.setState(this.getInitialState());
	}

	const handleCancel = () => {
	    this.props.onClose();
	    this.setState(this.getInitialState());
	}

	return (
	    <Dialog open={this.props.open} aria-labelledby="form-dialog-title" onClose={handleCancel}>
		<DialogTitle id="form-dialog-title">Add Annotation</DialogTitle>
		<DialogContent>
		    <TextField
			value={this.state.name}
			name="name"
			autoFocus
			onChange={handleInputChange}
			margin="dense"
			label="Name"
			fullWidth
			error={ this.state.nameError && this.state.hasWritten }
                        helperText={ this.state.nameError && this.state.hasWritten ? 'Required field.' : null }
		    />

		    <Box display="flex" justifyContent="center" m={1} p={1}>
			<MuiPickersUtilsProvider utils={MomentUtils}>
			    <KeyboardDatePicker
				disableToolbar
				variant="inline"
				format="YYYY-MM-DD"
				margin="normal"
				id="annotation-date-picker-inline"
				disableFuture="true"
				label="Date"
				value={this.state.date}
				onChange={this.handleDateChange.bind(this)}
				autoOk="true"
				KeyboardButtonProps={{
				    'aria-label': 'change date'
				}}
			    />
			</MuiPickersUtilsProvider>
		    </Box>                    
		</DialogContent>
		<DialogActions>
		    <Button onClick={handleCreate} color="primary" disabled={ this.state.nameError }>
			Add
		    </Button>
		    <Button onClick={handleCancel}>
			Cancel
		    </Button>
		</DialogActions>
	    </Dialog>
	);
    }
}
