import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import InsightToolbar from "../components/InsightToolbar.js"
import Container from '@material-ui/core/Container';
import Chart from "react-apexcharts";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
    container: {
	paddingTop: theme.spacing(4),
	paddingBottom: theme.spacing(4)
    }
});

class AnalysisView extends React.Component {
    constructor(props) {
	super(props);
	
	this.state = {
	    projects: [ ],
	    isLoaded: false,
	    numDays: 30
	};
    }

    componentDidMount() {
	this.getData(this.state.numDays);
    }

    selectPage(id) {
	// this.props.history.push('/editproject/'+id);
    }

    getData(n) {
	const p = {activity: '0', 'static': '1', days: 0};
        fetch("https://api.sometrik.com/project/list?" + new URLSearchParams(p), { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			isLoaded: true,
			projects: result.data
		    });
		},
		(error) => {
		    this.setState({
			isLoaded: true,
			error
		    });
		}
		
	    )
    }

    render() {
	const { classes, user, app } = this.props;
	const { projects } = this.state;

	const onTimeSelect = (n) => {
            this.setState({numDays: n});
	    this.getData(n);
	};
		
	const fixed = (value) => value.toFixed(1);
	
	let options1 = {
	    xaxis: {
		type: 'datetime',
		tooltip: {
		    enabled: false
		}
	    },
	    yaxis: {
		labels: {
		    formatter: fixed,
		},
	    },
	    chart: {
		animations: {
		    enabled: false
		},
		toolbar: {
		    show: false,
		},
		selection: {
		    enabled: false,
		},
		zoom: {
		    enabled: false,
		},
		sparkline: {
		    enabled: true,
		}
	    },
	    stroke: {
                curve: 'straight',
                width: 2
            },
	    tooltip: {
		x: {
		    format: 'dd MMM yyyy'
		}
	    },
	};

	const createData = (project) => {
	    return [ {
		name: 'Activity',
		data: project.activity,
	    } ];
	};

	const createNew = () => {
	    this.props.history.push('/createAnalysis');
	};

	const formatCount = (a) => {
	    if (a < 10000) return a;
	    if (a < 10000000) return Math.floor(a / 1000) + ' k';
	    return Math.floor(a / 1000000) + ' M';
	}

	const projectsArray = Object.values(projects);
	const numProjects = projectsArray.length;
	const maxProjects = 10;
	const isDisabled = !(user && user.is_admin) && numProjects >= maxProjects;

	const openMenu = (anchor, project) => {
	    this.setState({ anchor: anchor, selectedProject: project });
	}

	const handleClose = () => {
            this.setState({ anchor: null });
	}

	return (
	    <div>
		<InsightToolbar app={this.props.app} title="Manage Analyses" subtitle="" />
		
		<Container maxWidth="lg" className={classes.container}>
		    <h1>Select analysis:</h1>
		    <Table>
			<TableBody>
			    { projectsArray.map((project) => (
				<TableRow key={project.id}>
				    <TableCell>
					<Link component={RouterLink} to={ '/project/' + project.id }>{project.name}</Link><br/>
					<i>{formatCount(project.activity)} messages</i>
				    </TableCell>
				    <TableCell align="right">
					<IconButton size="small" onClick={ (e) => { openMenu(e.currentTarget, project) } } ><MoreVertIcon /></IconButton>
				    </TableCell>
				</TableRow>				    
			    )) }
			    <TableRow>				
				<TableCell colSpan="2">
				    <Button
					variant="contained"
					size="small"
					className={classes.button}
					startIcon={<AddIcon />}
					onClick={createNew}
					disabled={isDisabled}
				    >
					Create New
				    </Button>
				    { isDisabled ? <Typography>You have created {numProjects}/{maxProjects} of your analysis, delete previous to create new.</Typography> : null }
				</TableCell>
			    </TableRow>
			</TableBody>
		    </Table>
		</Container>

	    	<Menu
		    anchorEl={this.state.anchor}
		    keepMounted
		    open={this.state.anchor != null}
		    onClose={handleClose}			
		>
		</Menu>
	    </div>
	);
    }
}

export default withRouter(withStyles(styles)(AnalysisView));
