import React from "react";
import { Route, withRouter } from 'react-router-dom';

import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ShareIcon from '@material-ui/icons/Share';
// import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
// import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import MapIcon from '@material-ui/icons/Map';

import ExportCharts from "../components/ExportCharts";
import ExportTopics from "../components/ExportTopics";
import { withStyles } from '@material-ui/core/styles';
import InsightToolbar from "../components/InsightToolbar.js"

function TabPanel(props) {
    const { children, ...other } = props;

    return (
	<Typography component="div" role="tabpanel" {...other} >
	    {children}
	</Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node
};

const styles = theme => ({
    exportStatusContainer: {
	height: '80vh'
    },
    exportStatusInnerContainer: {
	textAlign: 'center',
	width: '100%'
    },
    button: {
	marginLeft: theme.spacing(1),
	marginRight: theme.spacing(1),
    }
});


class ExportView extends React.Component {
    constructor(props) {
	super(props);
	this.state = {
            task: null
	};
    }
    
    handleChange = (event, newValue) => {
	this.props.history.push(newValue);
    }
    
    componentDidMount () {
	this.setState({ task: null });
	this.getData();
    }

    componentWillUnmount() {
	clearInterval(this.timerID);
    }

    getData() {
	const { id } = this.props.match.params;
	const body = new URLSearchParams({ id: id });

	fetch("https://api.sometrik.com/export/show?" + body, { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    let task = result.data;
		    
		    if (task.state < 2) {
			this.timerId = setTimeout(this.getData.bind(this), 10000);
		    } else if (task.state === 2 && task.type === 1) {
			let e = task.emotion;
			for (let i = 0; i < e.fear.length; i++) {
			    e.fear[i][0] = new Date(e.fear[i][0] * 1000);
			}
			for (let i = 0; i < e.hope.length; i++) {
			    e.hope[i][0] = new Date(e.hope[i][0] * 1000);
			}
			for (let i = 0; i < e.anger.length; i++) {
			    e.anger[i][0] = new Date(e.anger[i][0] * 1000);
			}
			for (let i = 0; i < e.joy.length; i++) {
			    e.joy[i][0] = new Date(e.joy[i][0] * 1000);
			}

			let s = task.sentiment;
			for (let i = 0; i < s.neg.length; i++) {
			    s.neg[i][0] = new Date(s.neg[i][0] * 1000);
			}
			for (let i = 0; i < s.pos.length; i++) {
			    s.pos[i][0] = new Date(s.pos[i][0] * 1000);
			}

			let a = task.activity;
			for (let i = 0; i < a.news.length; i++) {
			    a.news[i][0] = new Date(a.news[i][0] * 1000);
			}
			for (let i = 0; i < a.comment.length; i++) {
			    a.comment[i][0] = new Date(a.comment[i][0] * 1000);
			}
			for (let i = 0; i < a.tweet.length; i++) {
			    a.tweet[i][0] = new Date(a.tweet[i][0] * 1000);
			}
			for (let i = 0; i < a.vk.length; i++) {
			    a.vk[i][0] = new Date(a.vk[i][0] * 1000);
			}
			for (let i = 0; i < a.new_users.length; i++) {
			    a.new_users[i][0] = new Date(a.new_users[i][0] * 1000);
			}
			for (let i = 0; i < a.reddit.length; i++) {
			    a.reddit[i][0] = new Date(a.reddit[i][0] * 1000);
			}
			for (let i = 0; i < a.youtube.length; i++) {
			    a.youtube[i][0] = new Date(a.youtube[i][0] * 1000);
			}
			for (let i = 0; i < a.vimeo.length; i++) {
			    a.vimeo[i][0] = new Date(a.vimeo[i][0] * 1000);
			}
			for (let i = 0; i < a.tumblr.length; i++) {
			    a.tumblr[i][0] = new Date(a.tumblr[i][0] * 1000);
			}
			for (let i = 0; i < a.tiktok.length; i++) {
			    a.tiktok[i][0] = new Date(a.tiktok[i][0] * 1000);
			}
		    }

		    this.setState({
			task: task
		    });
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components.
		(error) => {
		    this.timerId = setTimeout(this.getData.bind(this), 10000);		    
		}
	    )
    }

    componentDidUpdate(prevProps) {
	if (prevProps.match.params.id !== this.props.match.params.id) {
	    this.setState({ task: null });
	    this.getData();
	}
    }

    createAnnotation(date, name) {
	const requestOptions = {
	    method: 'POST',
	    headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
	    credentials: 'include',
	    body: new URLSearchParams({
		'id': this.state.task.project.id,
		'name': name,
		'date': date
	    })
	};
	
	fetch("https://api.sometrik.com/project/add_annotation", requestOptions)
		.then(response => response.json())
		.then(data => {
		    
		});

	this.getData();
    }

    render() {	

	let task_state = 0;
	let task_type = 0;
	let title = '';
	let subtitle = '';
	let mime_type = null;
	
	const export_id = this.props.match.params.id;
	const base_url = '/export/' + export_id;	
	
	const { classes } = this.props;
	
	if (this.state.task) {
	    task_type = this.state.task.type - 0;
	    task_state = this.state.task.state - 0;
	    mime_type = this.state.task.mime_type;
	    title = this.state.task.project.name;
	    subtitle = this.state.task.start_date + " - " + this.state.task.end_date;
	}

	const handleDownload = () => {
	    window.open("https://api.sometrik.com/export/raw?id=" + export_id, "_blank")			
	}

	const handleView = () => {
	    if (mime_type == 'application/graphml+xml') {
		this.props.history.push('/export/graph/' + export_id);
	    }
	}

	const handleCancel = () => {
	    const requestOptions = {
		method: 'POST',
		headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
		credentials: 'include',
		body: new URLSearchParams({ 'id': export_id })
	    };

	    fetch("https://api.sometrik.com/export/cancel", requestOptions)
		.then(response => response.json())
		.then(data => {
		    this.getData();
		});
	}
	
	if (task_state === 0 || task_state === 1) {
	    const status_text = task_state === 0 ? 'Pending' : 'Processing';
	    return (
		<div>
		    <InsightToolbar app={this.props.app} title={title} subtitle={subtitle} />
		    
		    <Box className={classes.exportStatusContainer} display="flex" alignItems="center">
			<div className={classes.exportStatusInnerContainer}>
			    <CircularProgress />
			    <div className={classes.exportStatusInnerContainer}>{status_text}</div>
			    <Button variant="contained" onClick={handleCancel}>Cancel</Button>
			</div>
		    </Box>
		</div>
	    );
	} else if (task_state === 3 || task_state === 4) {
	    const status_text = task_state === 3 ? 'Export failed' : 'Export canceled';
	    return (
		<div>
		    <InsightToolbar app={this.props.app} title={title} subtitle={subtitle} />
		    
		    <Box className={classes.exportStatusContainer} display="flex" alignItems="center">
			<div className={classes.exportStatusInnerContainer}>{status_text}</div>
		    </Box>
		</div>
	    );
	} else if (task_type !== 1) {
	    return (
		<div>
		    <InsightToolbar app={this.props.app} title={title} subtitle={subtitle} />
		    
		    <Box className={classes.exportStatusContainer} display="flex" alignItems="center">
			<div className={classes.exportStatusInnerContainer}>
			    <Button className={classes.button} variant="contained" onClick={handleDownload}>Download</Button>
			    { mime_type == 'application/graphml+xml' ? <Button className={classes.button} variant="contained" onClick={handleView}>View</Button> : null }
			</div>
		    </Box>
		</div>
	    );
	} else {
	    return (<>
			<InsightToolbar app={this.props.app} title={title} subtitle={subtitle} />

			<Paper square >
			    <Tabs
				value={this.props.history.location.pathname}
				onChange={this.handleChange}
				variant="fullWidth"
				indicatorColor="secondary"
				textColor="secondary"
				aria-label="icon label tabs example"
			    >
				<Tab value={ base_url } icon={<AssessmentIcon />} label="STATISTICS" />
				<Tab value={ base_url + "/graph" } icon={<ShareIcon />} label="GRAPH" />
				<Tab value={ base_url + "/topics" } icon={<MapIcon />} label="TOPICS" />
			    </Tabs>

			    <Route exact path={ base_url }>
				<TabPanel>
				    <ExportCharts task={this.state.task} onCreateAnnotation={this.createAnnotation.bind(this)} />
				</TabPanel>
			    </Route>

			    <Route exact path={ base_url + "/topics" }>
				<TabPanel>
				    <ExportTopics task={this.state.task} />
				</TabPanel>
			    </Route>

			</Paper>
			
		   </>
		   );
	}	
    }
}

export default withRouter(withStyles(styles)(ExportView));
